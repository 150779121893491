import { MatDialogRef } from "@angular/material";
import { DeviceDetectorService } from "ngx-device-detector";
var ShareWaDialog = /** @class */ (function () {
    function ShareWaDialog(dialogRef, deviceService) {
        this.dialogRef = dialogRef;
        this.deviceService = deviceService;
        this.title = "Abmeldung";
        console.log("Starting 'share wa dialog'");
    }
    ShareWaDialog.prototype.ngOnInit = function () { };
    ShareWaDialog.prototype.ngAfterViewInit = function () { };
    ShareWaDialog.prototype.onNoClick = function () {
        this.dialogRef.close(0);
    };
    ShareWaDialog.prototype.onYesClick = function () {
        this.dialogRef.close(1);
    };
    ShareWaDialog.prototype.isMobile = function () {
        return this.deviceService.isMobile();
    };
    return ShareWaDialog;
}());
export { ShareWaDialog };
