import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ServerCommunication } from "../../shared/server-communication.service";
import { ResponseTypeError, ResponseTypeWarn } from "../../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonFunctions } from "../../shared/common-functions.service";

export interface DefaultDialogData {
  result: number;
}

@Component({
  selector: "join-guest",
  templateUrl: "join-guest.html"
})
export class JoinGuestDialog {
  firstName: string = "";
  lastName: string = "";
  playerStrength: number = 5;

  constructor(
    public dialogRef: MatDialogRef<JoinGuestDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DefaultDialogData,
    private serverCommunication: ServerCommunication,
    private spinnerService: Ng4LoadingSpinnerService,
    private commonFunctions: CommonFunctions
  ) {
    console.log("Start 'join guest' dialog");
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onYesClick(): void {
    this.serverCommunication.joinGuestInGame(this.firstName, this.lastName, this.playerStrength).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
          this.data.result = 2;
          this.dialogRef.close(this.data);
        } else {
          this.data.result = 1;
          this.dialogRef.close(this.data);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }
}
