import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { _localeFactory } from "@angular/core/src/application_module";
import { ResponseTypeError, TeamNames, Team } from "../../shared/definitions.service";

export interface AddGameResultDialogData {
  result: number;
}

@Component({
  selector: "add-game-result",
  templateUrl: "add-game-result.html",
  styleUrls: ["./add-game-result.css"]
})
export class AddGameResultDialog {
  public title: string = "Spielergebnis eintragen";
  public team1: Team = { name: TeamNames.Team1, wins: 0 };
  public team2: Team = { name: TeamNames.Team2, wins: 0 };
  public standoff: number = 0;

  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddGameResultDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AddGameResultDialogData,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("Starting 'add game result' dialog");

    //set up form group
    this.formGroup = new FormGroup({});
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onNoClick(): void {
    this.dialogData.result = 0;
    this.dialogRef.close(this.dialogData);
  }

  public setData() {
    this.spinnerService.show();

    this.serverCommunication.addGameResult(this.team1.wins, this.team2.wins, this.standoff).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.dialogData.result = 1;
          this.dialogRef.close(this.dialogData);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }
}
