import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDatepicker,
} from "@angular/material";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
} from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  ResponseTypeError,
  Game,
  ResponseTypeWarn,
} from "../../shared/definitions.service";
import { _localeFactory } from "@angular/core/src/application_module";

export interface DialogDataAddNewGame {
  result: number;
  gameNumber: number;
  gameId: number;
  maxPlayer: number;
  moneyPoolLink: string;
}

@Component({
  selector: "add-new-game",
  templateUrl: "add-new-game.html",
})
export class AddNewGameDialog {
  gameNumber: number = 0;
  gamePlace: string = "Hansa";
  maxPlayer: number = 12;
  moneyPoolLink: string = "";

  public minDateNow = new Date();
  private _lastGameId: number = -1;
  public startDateTime: Date;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;
  public gameNumberFormControl = new FormControl();
  public startDateFormControl = new FormControl();
  public gamePlaceFormControl = new FormControl();
  public maxPlayerFormControl = new FormControl();
  public moneyPoolLinkFormControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<AddNewGameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddNewGame,
    private _serverCommunication: ServerCommunication,
    private _commonFunctions: CommonFunctions,
    private _spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("Start 'add new game' dialog");

    //datetime auf naechsten Freitag 20 Uhr setzen
    this.startDateTime = new Date();
    this.startDateTime = this.getNextDayOfWeek(this.startDateTime, 5);
    this.startDateTime.setHours(20);
    this.startDateTime.setMinutes(0);
    this.startDateTime.setSeconds(0);

    //set up form group
    this.formGroup = new FormGroup({
      gameNumberFormControl: this.gameNumberFormControl,
      startDateFormControl: this.startDateFormControl,
      gamePlaceFormControl: this.gamePlaceFormControl,
      maxPlayerFormControl: this.maxPlayerFormControl,
      moneyPoolLinkFormControl: this.moneyPoolLinkFormControl,
    });

    //take assigned data from old game
    this._lastGameId = +data.gameId;
    if (+data.gameNumber != -1) {
      this.gameNumberFormControl.setValue(+data.gameNumber);
      this.gameNumber = +data.gameNumber;
    }
    if (+data.maxPlayer != -1) {
      this.maxPlayerFormControl.setValue(+data.maxPlayer);
      this.maxPlayer = +data.maxPlayer;
    }
  }

  ngAfterViewInit() {}

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onYesClick(formDirective: FormGroupDirective): void {
    this.formGroupDirective = formDirective;
    this.data.result = 1;
    this.dialogRef.close(this.data);
  }

  /**
   * Raised when the input picker is clicked
   */
  public onDateTimePickerOpen() {
    var width = window.screen.width;
    var height = window.screen.height;

    setTimeout(() => {
      var element = document.getElementById("cdk-overlay-2");
      var picker = document.getElementById("owl-dt-picker-0");

      if (width < 500) {
        picker.style.width = "18em";
        element.style.left = "20px";
        element.style.bottom = "20px";
      } else {
        picker.style.width = "30em";
        element.style.left = width * 0.2 + "px";
        element.style.bottom = height * 0.1 + "px";
      }
    }, 1500);
  }

  /**
   * Adds a new game
   */
  public addNewGame() {
    this._spinnerService.show();

    var gameNumber = this.gameNumberFormControl.value;
    var gamePlace = this.gamePlaceFormControl.value;
    var startDate = this.startDateTime.getTime() / 1000;
    var maxPlayer = this.maxPlayerFormControl.value;
    var moneyPoolLink = this.moneyPoolLinkFormControl.value;

    this._serverCommunication
      .addNewGame(
        startDate,
        gamePlace,
        gameNumber,
        maxPlayer,
        -1,
        moneyPoolLink
      )
      .subscribe(
        (data) => {
          // check if error
          if (data.result === ResponseTypeError) {
            this._commonFunctions.showErrorMessage(data.message);
          } else if (data.result === ResponseTypeWarn) {
            this._commonFunctions.showWarnMessage(data.message);
            this.data.result = 2;
            this.dialogRef.close(this.data);
          } else {
            this.data.result = 1;
            this.dialogRef.close(this.data);
          }
          this._spinnerService.hide();
        },
        (error) => {
          this._commonFunctions.showErrorMessage(error);
          this._spinnerService.hide();
        }
      );
  }

  /**
   * Gets the next wanted day of week from a given start date
   *
   * @param date
   * @param dayOfWeek
   */
  private getNextDayOfWeek(date: Date, dayOfWeek: number): Date {
    var resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));
    return resultDate;
  }
}
