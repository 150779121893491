export var SiteUrl = "https://www.freitags-soccer.de";
export var RetryMax = 1;
export var ResponseTypeOk = "Ok";
export var ResponseTypeError = "Error";
export var ResponseTypeWarn = "Warn";
export var PayPalProductionKey = "";
export var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes[ErrorCodes["UserBlocked"] = 507] = "UserBlocked";
    ErrorCodes[ErrorCodes["NoOpeGame"] = 603] = "NoOpeGame";
})(ErrorCodes || (ErrorCodes = {}));
export var TeamNames;
(function (TeamNames) {
    TeamNames["Team1"] = "Leibchen";
    TeamNames["Team2"] = "Bunt";
})(TeamNames || (TeamNames = {}));
var UserData = /** @class */ (function () {
    function UserData(id) {
        this.id = id;
    }
    UserData.prototype.getId = function () {
        return this.id;
    };
    UserData.prototype.isBlocked = function () {
        return this.blocked == 1;
    };
    UserData.convertArray = function (data) {
        if (data == null || data == undefined) {
            return null;
        }
        var ret = [];
        data.forEach(function (element) {
            ret.push(UserData.convert(element));
        });
        return ret;
    };
    UserData.convert = function (data) {
        if (data == null || data == undefined) {
            return null;
        }
        var ret = new UserData(+data.id);
        ret.firstName =
            data.first_name != undefined ? data.first_name : data.firstName;
        ret.lastName = data.last_name != undefined ? data.last_name : data.lastName;
        ret.userName = data.user_name != undefined ? data.user_name : data.userName;
        ret.mobilePhoneNumber =
            data.mobile_phone_number != undefined
                ? data.mobile_phone_number
                : data.mobilePhoneNumber;
        ret.notificationId =
            data.notification_id != undefined
                ? data.notification_id
                : data.notificationId;
        ret.playerStrength =
            data.player_strength != undefined
                ? +data.player_strength
                : +data.playerStrength;
        ret.receiveMails =
            data.receive_mails != undefined
                ? +data.receive_mails
                : +data.receiveMails;
        ret.receivePush =
            data.receive_push != undefined ? +data.receive_push : +data.receivePush;
        ret.userGroup =
            data.user_group != undefined ? data.user_group : data.userGroup;
        ret.blocked = data.blocked;
        ret.blockedUntil =
            data.blocked_until != undefined
                ? +data.blocked_until
                : +data.blockedUntil;
        ret.email = data.email;
        return ret;
    };
    return UserData;
}());
export { UserData };
export var ChangeUserDataIntention;
(function (ChangeUserDataIntention) {
    ChangeUserDataIntention[ChangeUserDataIntention["ChangeAll"] = 0] = "ChangeAll";
    ChangeUserDataIntention[ChangeUserDataIntention["UserSettings"] = 1] = "UserSettings";
})(ChangeUserDataIntention || (ChangeUserDataIntention = {}));
var DefinitionsService = /** @class */ (function () {
    function DefinitionsService() {
    }
    return DefinitionsService;
}());
export { DefinitionsService };
