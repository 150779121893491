import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AuthService } from "./shared/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { CommonFunctions } from "./shared/common-functions.service";
import { MatDialog, MatIconRegistry } from "@angular/material";
import { AddUserDialog, DialogData } from "./dialogs/add-user/add-user";
import { HomeComponent } from "./home/home.component";
import { ToasterConfig } from "angular2-toaster";
import { DomSanitizer } from "@angular/platform-browser";
import { ServerCommunication } from "./shared/server-communication.service";
import { UserData } from "./shared/definitions.service";
import { LoginComponent } from "./login/login.component";
import { SetMoneyPoolLinkDialog } from "./dialogs/set-moneypool-link/set-moneypool-link";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  public sidenavWidth = 4;
  public addScript: boolean = false;
  public paypalLoad: boolean = true;
  public currentOpenComponent = null;
  public userName: string = "";
  private initializing: boolean;
  private isSmallScreenVertical: boolean;
  private isSmallScreenHorizontal: boolean;
  private readonly _sizeHeader = 55;
  private readonly _sizeFooter = 105;

  public config: ToasterConfig = new ToasterConfig({
    showCloseButton: false,
    tapToDismiss: true,
    timeout: 7000,
  });

  @ViewChild("sidenav")
  sidenav: MatSidenav;

  constructor(
    private authService: AuthService,
    private router: Router,
    private commonFunctions: CommonFunctions,
    public _dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private serverCommunication: ServerCommunication
  ) {
    //register svg icons
    this.matIconRegistry.addSvgIcon(
      "paypal_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/paypal-icon.svg"
      )
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.screenSizeDependingActions();
  }

  //Is thrown, when a new component is activated in the router outlet
  onActivate(componentRef) {
    this.currentOpenComponent = componentRef;
  }

  public isHomeComponent(): boolean {
    if (this.currentOpenComponent instanceof HomeComponent) {
      return true;
    }
    return false;
  }

  public isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  public isAdmin(): boolean {
    return this.commonFunctions.isAdmin();
  }

  public logout() {
    //clear local data
    this.userName = null;
    console.log("Logging out...");
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  public getUsername() {
    if ((this.isLoggedIn() && this.userName == null) || this.userName == "") {
      //info: do not remove check for local field !!
      this.serverCommunication.getUserData().then((userData: UserData) => {
        this.userName = userData.userName;
      });
    }
    return this.userName;
  }

  /**
   * Opens the dialog to add a new user
   */
  public openAddPlayerDialog() {
    const dialogRef = this._dialog.open(AddUserDialog, {
      width: "450px",
      data: <DialogData>{ result: 0 },
    });

    dialogRef.afterClosed().subscribe((result: DialogData) => {
      if (result != undefined && result.result === 1) {
        this.commonFunctions.showSuccessMessage("User wurde angelegt");
      }
    });
  }

  /**
   * Opens the dialog to add a new user
   */
  public openSetMoneyPoolLinkDialog() {
    const dialogRef = this._dialog.open(SetMoneyPoolLinkDialog, {
      width: "450px",
      data: <DialogData>{ result: 0 },
    });

    dialogRef.afterClosed().subscribe((result: DialogData) => {
      if (result != undefined && result.result === 1) {
        this.commonFunctions.showSuccessMessage("Link gesetzt");
        let comp: HomeComponent = this.currentOpenComponent;
        comp.loadData(); //update game data
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 470) {
      if (this.sidenav != undefined) {
        this.sidenav.close();
      }
    } else {
      if (this.sidenav != undefined) {
        this.sidenav.open();
      }
    }
  }

  public close() {
    this.sidenav.close();
  }

  public increase() {
    this.sidenavWidth = 15;
  }

  public decrease() {
    this.sidenavWidth = 4;
  }

  public joinGuest() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.joinGuest();
  }

  public joinUser() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.openDialogJoinUser();
  }

  public cancelGame() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.cancelGame();
  }

  public changeUserData() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.openDialogChangeUserData();
  }

  public blockUser() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.openDialogBlockUser();
  }

  public openCreateNewGameDialog() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.openCreateNewGameDialog();
  }

  public showPayButton(): boolean {
    let comp: HomeComponent = this.currentOpenComponent;
    return comp.showPayButton();
  }

  public isInList(): boolean {
    let comp: HomeComponent = this.currentOpenComponent;
    return comp.myGamePlayer != null;
  }

  public pay() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.pay();
  }

  public leaveGameThisUser() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.leaveGameThisUser();
  }

  public joinGameThisUser() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.joinGameThisUser();
  }

  public updateList() {
    let comp: HomeComponent = this.currentOpenComponent;
    comp.updateGamePlayerList();
  }

  /**
   * Checks if a game is present
   */
  public gameIsPresent(): boolean {
    let comp = this.currentOpenComponent;
    if (comp != undefined && comp instanceof HomeComponent) {
      return comp.isGamePresent();
    }
    return false;
  }

  /**
   * Checks if a game is outdated
   */
  public gameIsOutdated(): boolean {
    let comp = this.currentOpenComponent;
    if (comp != undefined && comp instanceof HomeComponent) {
      return comp.isGameOutdated();
    }
    return false;
  }

  /**
   * Gets the style class for the mat-sidenav-content depending on the currently loaded component
   */
  public getStyleClassForComponent() {
    let comp = this.currentOpenComponent;
    var ret = "";

    if (comp != undefined && comp != null) {
      if (comp instanceof LoginComponent) {
        ret += " bg-image-login";
      }
    }
    return ret;
  }

  /**
   * Executes some operations depending on the screen size
   */
  private screenSizeDependingActions() {
    this.initializing = true;
    var width = window.screen.width;
    var height = window.screen.height;

    if (width < 470) {
      this.isSmallScreenVertical = true;
    }
    if (height < 400) {
      this.isSmallScreenHorizontal = true;
    }

    if (this.isSmallScreenHorizontal || this.isSmallScreenVertical) {
      setTimeout(() => {
        if (this.sidenav != undefined) {
          this.sidenav.close();
        }
      }, 500);
    }

    // close sidenav on routing
    this.router.events.subscribe((event) => {
      if (
        !this.initializing &&
        (this.isSmallScreenHorizontal || this.isSmallScreenVertical)
      ) {
        setTimeout(() => {
          if (this.sidenav != undefined) {
            this.sidenav.close();
          }
        }, 100);
      }
      if (event instanceof NavigationEnd) {
        this.initializing = false;
      }
    });
  }

  /**
   * Calculates the available size of the content depending on which component is loaded and which screen size is present
   */
  private getHeightContent(): number {
    var contentHeight = document.getElementById("content").offsetHeight;
    if (!this.isHomeComponent()) {
      return contentHeight - this._sizeHeader;
    }
    var res = contentHeight - this._sizeHeader - this._sizeFooter + 25;
    return res;
  }

  public getHeightContentAsPx(): string {
    return this.getHeightContent() + "px";
  }

  public getHeightBottomBarAsPx(): string {
    return this._sizeFooter + "px";
  }
}
