import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { AuthService } from "./auth.service";
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    ErrorInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).catch(function (err) {
            if (_this.router.url !== "/login" && err.status === 401) {
                console.log("Unauthorized access. Forward to login");
                _this.authService.logout();
                _this.router.navigate(["login"]);
            }
            return Observable.throw(err);
        });
    };
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
