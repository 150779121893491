<h1 mat-dialog-title>Gastspieler hinzufügen</h1>
<hr />
<div mat-dialog-content>
  <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
    <input matInput type="text" placeholder="Vorname" [(ngModel)]="firstName" minlength="3" maxlength="50" required cdkFocusInitial />
    <button mat-button *ngIf="firstName" matSuffix mat-icon-button aria-label="Clear" (click)="firstName = ''"><mat-icon>close</mat-icon></button>
  </mat-form-field>

  <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
    <input matInput type="text" placeholder="Nachname" [(ngModel)]="lastName" minlength="3" maxlength="50" required />
    <button mat-button *ngIf="lastName" matSuffix mat-icon-button aria-label="Clear" (click)="lastName = ''"><mat-icon>close</mat-icon></button>
  </mat-form-field>

  <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
    <input matInput type="number" placeholder="Spielerstärke (1-10)" min="1" max="10" [(ngModel)]="playerStrength" />
  </mat-form-field>
</div>

<hr />
<div mat-dialog-actions>
  <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">Abbrechen</button>
  <button
    mat-raised-button
    color="primary"
    style="width: 47%;"
    type="button"
    (click)="onYesClick()"
    [disabled]="firstName.length < 3 || lastName.length < 3 || playerStrength == 0"
    cdkFocusInitial
  >
    Anlegen
  </button>
</div>
