<h1 mat-dialog-title>{{ title }}</h1>
<hr />

<div mat-dialog-content>
  <h6>
    Es sind nur noch <strong>48h</strong> bis zum Spiel.<br />
    Bitte gib in der Fussball-Gruppe bescheid, dass du aussteigst.
  </h6>

  <div *ngIf="isMobile()" style="text-align: center;">
    <a
      href="https://api.whatsapp.com/send?text=Leider kann ich nicht beim Spiel mitmachen. Kann jemand für mich einspringen?"
      class="button"
      target="_blank"
      >Mitteilen</a
    >
  </div>
</div>
<hr />

<div mat-dialog-actions>
  <button
    mat-raised-button
    type="reset"
    style="width: 47%;"
    (click)="onNoClick()"
  >
    Ich spiel mit
  </button>
  <button
    mat-raised-button
    color="primary"
    style="width: 47%;"
    (click)="onYesClick()"
  >
    Abmelden
  </button>
</div>
