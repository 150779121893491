import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

    //Info: https://angularfirebase.com/lessons/sharing-data-between-angular-components-four-methods/
    private currentSwitchPorts = new BehaviorSubject([]);
    currentSwitchPortList = this.currentSwitchPorts.asObservable();

    constructor() { }

    changeCurrentSwitchPortList(switchPortList: any) {
        this.currentSwitchPorts.next(switchPortList)
    }

}