import { OnInit } from "@angular/core";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ResponseTypeWarn, } from "../shared/definitions.service";
var StatisticsGraphicsComponent = /** @class */ (function () {
    function StatisticsGraphicsComponent(serverCommunication, commonFunctions, spinnerService) {
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.showNoDataBestPlayer = false;
        this.showNoDataMostVisited = false;
        this.typePieChart = "pie";
        this.barChartLegend = true;
        this.barChartType = "bar";
        //BestPlayers
        this.labelsBestPlayers = [];
        this.dataBestPlayers = [];
        this.pieChartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItem, data) {
                        try {
                            var index = +tooltipItem[0].index;
                            var dataSet = data.datasets[0].data[index];
                            return ("Siege: " +
                                dataSet.metadata.siege +
                                " / Spieltage: " +
                                dataSet.metadata.game_days);
                        }
                        catch (error) { }
                        return "";
                    },
                },
            },
        };
        //Most visited
        this.labelsMostVisited = [];
        this.dataMostVisited = [{ data: [], label: "Anwesenheit" }];
        this.barChartOptions = {
            scaleShowVerticalLines: false,
            responsive: true,
        };
        console.log("loading statistics component");
    }
    StatisticsGraphicsComponent.prototype.ngOnInit = function () {
        this.getArrayPlayerToGameDays();
        this.getListBestPlayers();
    };
    /**
     * Bester spieler (Spieltage/gewonnene spiele)
     */
    StatisticsGraphicsComponent.prototype.getListBestPlayers = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getListBestPlayers().subscribe(function (data) {
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
            }
            else {
                if (data.message.length > 0) {
                    var fElement = data.message[0];
                    if (!fElement.hasOwnProperty("game_days") ||
                        !fElement.hasOwnProperty("siege") ||
                        !fElement.hasOwnProperty("fn") ||
                        !fElement.hasOwnProperty("last_name") ||
                        !fElement.hasOwnProperty("user_name")) {
                        _this.commonFunctions.showErrorMessage("Daten zur Anzeige des Besten Spielers sind fehlerhaft");
                    }
                    else {
                        var labelsCache = [];
                        var dataCache = [];
                        var threshold = 5;
                        var cnt = 0;
                        data.message.forEach(function (element) {
                            var firstName = element["fn"];
                            var lastName = element["last_name"];
                            var gameDays = +element["game_days"];
                            var wins = +element["siege"];
                            if (wins != 0 && cnt <= threshold) {
                                var ratio = gameDays / wins;
                                labelsCache.push(firstName + " " + lastName);
                                dataCache.push(new Tooltip(ratio.toString(), element));
                                cnt++;
                            }
                        });
                        if (dataCache.length == 0) {
                            _this.showNoDataBestPlayer = true;
                        }
                        _this.labelsBestPlayers = labelsCache;
                        _this.dataBestPlayers = dataCache;
                    }
                }
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Gibt eine Liste mit den Zuordnungen von Spieler zu Anzahl Spieltage zurueck. Geordnet nach meisten Spieltagen
     */
    StatisticsGraphicsComponent.prototype.getArrayPlayerToGameDays = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getArrayPlayerToGameDays().subscribe(function (data) {
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
            }
            else {
                if (data.message.length > 0) {
                    var fElement = data.message[0];
                    if (!fElement.hasOwnProperty("gameDays") ||
                        !fElement.hasOwnProperty("fn") ||
                        !fElement.hasOwnProperty("last_name")) {
                        _this.commonFunctions.showErrorMessage("Daten zur Anzeige des Besten Spielers sind fehlerhaft");
                    }
                    else {
                        var labelsCache = [];
                        var dataCache = [];
                        data.message.forEach(function (element) {
                            var firstName = element["fn"];
                            var lastName = element["last_name"];
                            var gameDays = +element["gameDays"];
                            if (gameDays != 0) {
                                labelsCache.push(firstName + " " + lastName);
                                dataCache.push(gameDays);
                            }
                        });
                        if (dataCache.length == 0) {
                            _this.showNoDataMostVisited = true;
                        }
                        _this.labelsMostVisited = labelsCache;
                        _this.dataMostVisited = [
                            { data: dataCache, label: "Anwesenheit (Spieltage)" },
                        ];
                    }
                }
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    // events
    StatisticsGraphicsComponent.prototype.chartClicked = function (e) {
        console.log(e);
    };
    StatisticsGraphicsComponent.prototype.chartHovered = function (e) {
        console.log(e);
    };
    return StatisticsGraphicsComponent;
}());
export { StatisticsGraphicsComponent };
/**
 * Class that represents a tooltip in a chart
 */
var Tooltip = /** @class */ (function () {
    function Tooltip(value, metadata) {
        var _this = this;
        this.toString = function () {
            return _this.value;
        };
        this.value = value;
        this.metadata = metadata;
    }
    return Tooltip;
}());
