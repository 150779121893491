import { Component, OnInit } from "@angular/core";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { FormGroup, FormGroupDirective, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../shared/auth.service";
import { MatDialog } from "@angular/material";
import { ForgotPassworDialog, DialogData } from "../dialogs/forgot-password/forgot-password";
import { ResponseTypeError } from "../shared/definitions.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public loadingData: boolean = true;
  private returnUrl: string;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;
  usernameFormControl = new FormControl();
  passwordFormControl = new FormControl();

  constructor(
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public _dialog: MatDialog
  ) {
    //set up form group
    this.formGroup = new FormGroup({
      usernameFormControl: this.usernameFormControl,
      passwordFormControl: this.passwordFormControl
    });
  }

  ngOnInit() {
    console.log("Login component loaded");

    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  /**
   * Verifies the credentials
   */
  public verify(formDirective: FormGroupDirective) {
    this.formGroupDirective = formDirective; //save to local var
    this.loadingData = true;
    var selectedUsername = this.usernameFormControl.value;
    var selectedPassword = this.passwordFormControl.value;

    this.authService
      .login(selectedUsername, selectedPassword)
      .then(
        successful => {
          if (successful) {
            this.resetFormGroup();

            // login successful so redirect to return url
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.commonFunctions.showErrorMessage("Angegeben Zugangsdaten sind inkorrekt");
          }
          this.loadingData = false;
        },
        error => {
          this.commonFunctions.showErrorMessage(error);
          this.loadingData = false;
        }
      )
      .catch((error: any) => {
        this.commonFunctions.showErrorMessage(error);
        this.loadingData = false;
      });
  }

  /**
   * Reset Password
   */
  public forgotPassword() {
    const dialogRef = this._dialog.open(ForgotPassworDialog, {
      width: "450px",
      data: <DialogData>{ result: 0 }
    });

    dialogRef.afterClosed().subscribe((result: DialogData) => {
      if (result != undefined && result.result === 1) {
        this.serverCommunication.prepareResetPassword(result.email).subscribe(
          data => {
            // check if error
            if (data.result === ResponseTypeError) {
              this.commonFunctions.showErrorMessage(data.message);
            } else {
              this.commonFunctions.showSuccessMessage("Du bekommst eine E-Mail");
            }
            this.spinnerService.hide();
          },
          error => {
            this.commonFunctions.showErrorMessage(error);
            this.spinnerService.hide();
          }
        );
      }
    });
  }

  /**
   * Resets the whole form data
   */
  private resetFormGroup() {
    this.formGroupDirective.resetForm();
    this.formGroup.reset();
  }
}
