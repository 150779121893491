<div class="flex-container form-content">
  <form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="saveData()">
    <!-- Username -->
    <mat-form-field class="input-field-large">
      <input
        matInput
        type="text"
        [formControl]="usernameFormControl"
        placeholder="Username"
        [(ngModel)]="userName"
        minlength="3"
        maxlength="50"
        required
        cdkFocusInitial
      />
      <mat-error *ngIf="usernameFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
    </mat-form-field>

    <!-- email -->
    <mat-form-field class="input-field-large">
      <input matInput type="email" [formControl]="emailFormControl" placeholder="E-Mail" [(ngModel)]="email" minlength="3" maxlength="50" required />
      <mat-error *ngIf="emailFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
    </mat-form-field>

    <!-- FirstName -->
    <mat-form-field class="input-field-large">
      <input
        matInput
        type="text"
        [formControl]="firstnameFormControl"
        placeholder="Vorname"
        [(ngModel)]="firstName"
        minlength="3"
        maxlength="50"
        required
      />
      <mat-error *ngIf="firstnameFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
    </mat-form-field>

    <!-- LastName -->
    <mat-form-field class="input-field-large">
      <input
        matInput
        type="text"
        [formControl]="lastnameFormControl"
        placeholder="Nachname"
        [(ngModel)]="lastName"
        minlength="3"
        maxlength="50"
        required
      />
      <mat-error *ngIf="lastnameFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
    </mat-form-field>

    <!-- MobilePhoneNumber-->
    <mat-form-field class="input-field-large">
      <span matPrefix>+49 &nbsp;</span>
      <input
        type="tel"
        matInput
        (keypress)="numberOnly($event)"
        placeholder="Handynummer"
        [formControl]="mobilephonenumFormControl"
        [(ngModel)]="mobilePhoneNumber"
        minlength="10"
        maxlength="12"
      />
      <mat-icon matSuffix>phone</mat-icon>
      <mat-error *ngIf="mobilephonenumFormControl.invalid">Nummer hat nicht die richtige Länge</mat-error>
    </mat-form-field>

    <!--Image upload-->
    <!-- <mat-form-field class="pic-select" (click)="imageInput.click()">
      <input type="text" matInput [formControl]="imagenameFormControl" />
    </mat-form-field>
    <mat-icon *ngIf="selectedImage == null" (click)="imageInput.click()" style="cursor: pointer;">change_history</mat-icon>
    <mat-icon *ngIf="selectedImage != null" (click)="selectedImage = null; imagenameFormControl.setValue(imageInputText)" style="cursor: pointer;"
      >clear</mat-icon
    >

    <mat-error *ngIf="!profileImageAccepted">Bitte nur jpg/png und max. 500 KB</mat-error>
    <label class="image-upload-container btn btn-bwm" hidden>
      <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" />
    </label> -->

    <!--checkbox receive mails-->
    <!-- <div style="width: 100%;text-align: left;">
      <mat-checkbox class="input-field-large" [(ngModel)]="checkboxReceiveMails" [formControl]="checkboxReceiveMailsFormControl"
        >E-Mails empfangen</mat-checkbox
      >
    </div> -->

    <!--checkbox receive push-->
    <!-- <div style="width: 100%;text-align: left;">
      <mat-checkbox class="input-field-large" [(ngModel)]="checkboxReceivePush" [formControl]="checkboxReceivePushFormControl"
        >Push-Nachrichten empfangen</mat-checkbox
      >
    </div> -->

    <button
      mat-raised-button
      color="primary"
      style="width: 100%;margin-top: 40px;height: 40px;margin-bottom: 50px;"
      type="submit"
      [disabled]="!formGroup.valid"
    >
      Speichern
    </button>
  </form>
</div>
