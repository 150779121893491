import { MatDialogRef } from "@angular/material";
import { ServerCommunication } from "../../shared/server-communication.service";
import { ResponseTypeError, ResponseTypeWarn, } from "../../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonFunctions } from "../../shared/common-functions.service";
var SetMoneyPoolLinkDialog = /** @class */ (function () {
    function SetMoneyPoolLinkDialog(dialogRef, data, serverCommunication, spinnerService, commonFunctions) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.serverCommunication = serverCommunication;
        this.spinnerService = spinnerService;
        this.commonFunctions = commonFunctions;
        this.moneyPoolLink = "";
        console.log("Start 'set money pool link' dialog");
    }
    SetMoneyPoolLinkDialog.prototype.onNoClick = function () {
        this.data.result = 0;
        this.dialogRef.close(this.data);
    };
    SetMoneyPoolLinkDialog.prototype.onYesClick = function () {
        var _this = this;
        this.serverCommunication.setMoneyPoolLink(this.moneyPoolLink).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
                _this.data.result = 2;
                _this.dialogRef.close(_this.data);
            }
            else {
                _this.data.result = 1;
                _this.dialogRef.close(_this.data);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    return SetMoneyPoolLinkDialog;
}());
export { SetMoneyPoolLinkDialog };
