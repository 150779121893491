<h1 mat-dialog-title>{{ data.operationName }}</h1>
<hr />
<div mat-dialog-content>
  <p>{{ data.contentText }}</p>
</div>
<hr />
<div mat-dialog-actions>
  <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">{{ data.buttonNoName }}</button>
  <button mat-raised-button color="primary" style="width: 47%;" (click)="onYesClick()" cdkFocusInitial>
    {{ data.buttonYesName }}
  </button>
</div>
