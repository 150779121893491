<!--Bester spieler (gewonnene spiele/Spieltage).-->
<mat-card>
  <mat-card-header style="display: block">
    <mat-card-title style="color:black;font-size: 1.2em;">Beste Spieler</mat-card-title>
    <h6 style="font-size: 0.8em;">Gewonnene Spiele/Spieltage</h6>
    <hr />
  </mat-card-header>
  <mat-card-content>
    <div style="display: block" *ngIf="!showNoDataBestPlayer">
      <canvas baseChart [data]="dataBestPlayers" [labels]="labelsBestPlayers" [chartType]="typePieChart" [options]="pieChartOptions"></canvas>
    </div>
    <div style="text-align: center;" *ngIf="showNoDataBestPlayer">
      <h4>Keine statistischen Daten verwertbar</h4>
    </div>
  </mat-card-content>
</mat-card>

<!--Meist besucht: Gibt eine Liste mit den Zuordnungen von Spieler zu Anzahl Spieltage zurueck. Geordnet nach meisten Spieltagen-->
<mat-card>
  <mat-card-header style="display: block">
    <mat-card-title style="color:black;font-size: 1.2em;">Häufigste Anwesenheit</mat-card-title>
    <hr />
  </mat-card-header>
  <mat-card-content>
    <div style="display: block" *ngIf="!showNoDataMostVisited">
      <canvas
        baseChart
        [datasets]="dataMostVisited"
        [labels]="labelsMostVisited"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType"
      ></canvas>
    </div>
    <div style="text-align: center;" *ngIf="showNoDataMostVisited">
      <h4>Keine statistischen Daten verwertbar</h4>
    </div>
  </mat-card-content>
</mat-card>
