import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { CommonFunctions } from "../shared/common-functions.service";
import { ServerCommunication } from "../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ResponseTypeWarn, UserData } from "../shared/definitions.service";
import { MatSort, MatTableDataSource } from "@angular/material";

export interface TableEntry {
  fn: string;
  last_name: string;
  gameDays: number;
  wins: number;
  loss: number;
  standoff: number;
}

@Component({
  selector: "app-statistics-table",
  templateUrl: "./statistics-table.component.html",
  styleUrls: ["./statistics-table.component.css"]
})
export class StatisticsTableComponent implements OnInit {
  constructor(
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("loading statistics table component");
    this._userData = null;
    //get user data
    this.serverCommunication.getUserData().then((userData: UserData) => {
      this._userData = userData;
    });
  }

  @ViewChild(MatSort) sort: MatSort;

  public dataSource;
  public displayedColumns = [];
  private _userData: UserData;

  public columnNames = [
    {
      id: "fn",
      value: "Vorname"
    },
    {
      id: "last_name",
      value: "Nachname"
    },
    {
      id: "gameDays",
      value: "Spieltage"
    },
    {
      id: "wins",
      value: "Siege"
    },
    {
      id: "loss",
      value: "Niederlagen"
    },
    {
      id: "standoff",
      value: "Unentschieden"
    }
  ];

  ngOnInit() {
    this.displayedColumns = this.columnNames.map(x => x.id);
    this.getDataForTable();
  }

  /**
   * Get list with data for a table view of statistics.
   * Liste mit: Vorname Name (Username), Spieltage, Siege, Niederlagen, Unentschieden
   */
  private getDataForTable() {
    this.spinnerService.show();

    this.serverCommunication.getDataForTable().subscribe(
      data => {
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
        } else {
          let tableArr: TableEntry[] = data.message;
          this.dataSource = new MatTableDataSource(tableArr);
          this.dataSource.sort = this.sort;
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  public highlightRow(row: any) {
    if (row == null || row == undefined) {
      return false;
    }
    var firstName = row.fn;
    var lastName = row.last_name;
    if (this._userData.firstName == firstName && this._userData.lastName == lastName) {
      return true;
    }
    return false;
  }
}
