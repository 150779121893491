import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormGroupDirective, FormControl } from "@angular/forms";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError } from "../shared/definitions.service";
import { setTimeout } from "core-js";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    //set up form group
    this.formGroup = new FormGroup({
      password1FormControl: this.password1FormControl,
      password2FormControl: this.password2FormControl
    });
  }

  public hideNotification: boolean = false;
  public password1: string;
  public password2: string;
  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;
  password1FormControl = new FormControl();
  password2FormControl = new FormControl();
  private _userId: number = -1;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      var userId = +params["id"] || -1;

      if (userId !== -1) {
        this._userId = userId;
        this.hideNotification = true;
      }
    });
  }

  public setNewPassword(formDirective: FormGroupDirective) {
    this.formGroupDirective = formDirective; //save to local var
    var selectedPassword = this.password2FormControl.value;

    this.serverCommunication.setNewPassword(this._userId, selectedPassword).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.commonFunctions.showSuccessMessage("Passwort erfolgreich neu gesetzt");
          this.resetFormGroup();

          //weiterleiten
          setTimeout(() => {
            this.router.navigate(["login"]);
          }, 3000);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Resets the whole form data
   */
  private resetFormGroup() {
    this.formGroupDirective.resetForm();
    this.formGroup.reset();
  }
}
