import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeOk } from "./definitions.service";
import { ServerCommunication } from "./server-communication.service";
import { CommonFunctions } from "./common-functions.service";
var AuthService = /** @class */ (function () {
    function AuthService(spinnerService, serverCommunication, commonFunctions) {
        this.spinnerService = spinnerService;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
    }
    AuthService.prototype.logout = function () {
        //jwt aus local cache clearen
        localStorage.clear();
        this.commonFunctions.clearToken();
    };
    /**
     * Checks if the current token is still valid
     */
    AuthService.prototype.isAuthenticated = function () {
        var token = localStorage.getItem("jwt");
        if (token == null || token == undefined || token == "") {
            return false;
        }
        return true;
    };
    /**
     * Executes a login operation
     *
     * @param username
     * @param password
     */
    AuthService.prototype.login = function (username, password) {
        var _this = this;
        this.commonFunctions.clearToken();
        this.spinnerService.show();
        var prom = new Promise(function (resolve, reject) {
            console.log("Verifying login...");
            _this.serverCommunication.verifyLoginData(username, password).subscribe(function (data) {
                var result = data.result;
                var message = data.message;
                _this.spinnerService.hide();
                if (result === ResponseTypeOk) {
                    console.log("Login successfull");
                    //save jwt to local storage
                    localStorage.setItem("jwt", message);
                    resolve(true);
                }
                else {
                    console.log("Login failed");
                    localStorage.setItem("jwt", "");
                    resolve(false);
                }
            }, function (error) {
                _this.spinnerService.hide();
                reject(error);
            });
        });
        return prom;
    };
    return AuthService;
}());
export { AuthService };
