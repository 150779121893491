import { Component, OnInit } from "@angular/core";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  ResponseTypeError,
  ResponseTypeWarn,
} from "../shared/definitions.service";

@Component({
  selector: "app-statistics-graphics",
  templateUrl: "./statistics-graphics.component.html",
  styleUrls: ["./statistics-graphics.component.css"],
})
export class StatisticsGraphicsComponent implements OnInit {
  constructor(
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("loading statistics component");
  }

  public showNoDataBestPlayer: boolean = false;
  public showNoDataMostVisited: boolean = false;
  public typePieChart: string = "pie";
  public barChartLegend: boolean = true;
  public barChartType: string = "bar";

  //BestPlayers
  public labelsBestPlayers: string[] = [];
  public dataBestPlayers: number[] = [];
  public pieChartOptions: any = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      enabled: true,
      callbacks: {
        title: function (tooltipItem: any[], data) {
          try {
            var index = +tooltipItem[0].index;
            var dataSet: Tooltip = data.datasets[0].data[index];
            return (
              "Siege: " +
              dataSet.metadata.siege +
              " / Spieltage: " +
              dataSet.metadata.game_days
            );
          } catch (error) {}
          return "";
        },
      },
    },
  };

  //Most visited
  public labelsMostVisited: string[] = [];
  public dataMostVisited: any[] = [{ data: [], label: "Anwesenheit" }];
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  ngOnInit() {
    this.getArrayPlayerToGameDays();
    this.getListBestPlayers();
  }

  /**
   * Bester spieler (Spieltage/gewonnene spiele)
   */
  private getListBestPlayers() {
    this.spinnerService.show();

    this.serverCommunication.getListBestPlayers().subscribe(
      (data) => {
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
        } else {
          if (data.message.length > 0) {
            var fElement = data.message[0];
            if (
              !fElement.hasOwnProperty("game_days") ||
              !fElement.hasOwnProperty("siege") ||
              !fElement.hasOwnProperty("fn") ||
              !fElement.hasOwnProperty("last_name") ||
              !fElement.hasOwnProperty("user_name")
            ) {
              this.commonFunctions.showErrorMessage(
                "Daten zur Anzeige des Besten Spielers sind fehlerhaft"
              );
            } else {
              var labelsCache = [];
              var dataCache = [];
              var threshold = 5;
              var cnt = 0;
              data.message.forEach(function (element) {
                var firstName = element["fn"];
                var lastName = element["last_name"];
                var gameDays = +element["game_days"];
                var wins = +element["siege"];
                if (wins != 0 && cnt <= threshold) {
                  var ratio = gameDays / wins;
                  labelsCache.push(firstName + " " + lastName);
                  dataCache.push(new Tooltip(ratio.toString(), element));
                  cnt++;
                }
              });
              if (dataCache.length == 0) {
                this.showNoDataBestPlayer = true;
              }

              this.labelsBestPlayers = labelsCache;
              this.dataBestPlayers = dataCache;
            }
          }
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Gibt eine Liste mit den Zuordnungen von Spieler zu Anzahl Spieltage zurueck. Geordnet nach meisten Spieltagen
   */
  private getArrayPlayerToGameDays() {
    this.spinnerService.show();

    this.serverCommunication.getArrayPlayerToGameDays().subscribe(
      (data) => {
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
        } else {
          if (data.message.length > 0) {
            var fElement = data.message[0];
            if (
              !fElement.hasOwnProperty("gameDays") ||
              !fElement.hasOwnProperty("fn") ||
              !fElement.hasOwnProperty("last_name")
            ) {
              this.commonFunctions.showErrorMessage(
                "Daten zur Anzeige des Besten Spielers sind fehlerhaft"
              );
            } else {
              var labelsCache = [];
              var dataCache = [];
              data.message.forEach(function (element) {
                var firstName = element["fn"];
                var lastName = element["last_name"];
                var gameDays = +element["gameDays"];
                if (gameDays != 0) {
                  labelsCache.push(firstName + " " + lastName);
                  dataCache.push(gameDays);
                }
              });
              if (dataCache.length == 0) {
                this.showNoDataMostVisited = true;
              }
              this.labelsMostVisited = labelsCache;
              this.dataMostVisited = [
                { data: dataCache, label: "Anwesenheit (Spieltage)" },
              ];
            }
          }
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
}

/**
 * Class that represents a tooltip in a chart
 */
class Tooltip {
  value: string;
  metadata: any;
  constructor(value: string, metadata: any) {
    this.value = value;
    this.metadata = metadata;
  }

  public toString = (): string => {
    return this.value;
  };
}
