import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ServerCommunication } from "../../shared/server-communication.service";
import { ResponseTypeError } from "../../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonFunctions } from "../../shared/common-functions.service";

export interface DialogData {
  result: number;
}

@Component({
  selector: "add-user",
  templateUrl: "add-user.html"
})
export class AddUserDialog {
  email: string = "";
  playerStrength: number = 5;

  constructor(
    public dialogRef: MatDialogRef<AddUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private serverCommunication: ServerCommunication,
    private spinnerService: Ng4LoadingSpinnerService,
    private commonFunctions: CommonFunctions
  ) {
    console.log("Start 'add user' dialog");
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onYesClick(): void {
    this.serverCommunication.addNewUser(this.email, this.playerStrength).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.data.result = 1;
          this.dialogRef.close(this.data);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }
}
