/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./statistics-table.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/sort/typings/index.ngfactory";
import * as i3 from "@angular/material/table";
import * as i4 from "@angular/cdk/table";
import * as i5 from "@angular/material/sort";
import * as i6 from "../../../node_modules/@angular/material/table/typings/index.ngfactory";
import * as i7 from "@angular/common";
import * as i8 from "./statistics-table.component";
import * as i9 from "../shared/server-communication.service";
import * as i10 from "../shared/common-functions.service";
import * as i11 from "ng4-loading-spinner";
var styles_StatisticsTableComponent = [i0.styles];
var RenderType_StatisticsTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatisticsTableComponent, data: {} });
export { RenderType_StatisticsTableComponent as RenderType_StatisticsTableComponent };
function View_StatisticsTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-header-cell", [["class", "mat-header-cell"], ["mat-sort-header", ""], ["role", "columnheader"], ["style", "word-break: break-all"]], [[2, "mat-sort-header-disabled", null]], [[null, "click"], [null, "mouseenter"], [null, "longpress"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._setIndicatorHintVisible(true) !== false);
        ad = (pd_1 && ad);
    } if (("longpress" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._setIndicatorHintVisible(true) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._setIndicatorHintVisible(false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatSortHeader_0, i2.RenderType_MatSortHeader)), i1.ɵdid(1, 16384, null, 0, i3.MatHeaderCell, [i4.CdkColumnDef, i1.ElementRef], null, null), i1.ɵdid(2, 245760, null, 0, i5.MatSortHeader, [i5.MatSortHeaderIntl, i1.ChangeDetectorRef, [2, i5.MatSort], [2, i4.CdkColumnDef]], { id: [0, "id"] }, null), (_l()(), i1.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._isDisabled(); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.value; _ck(_v, 3, 0, currVal_2); }); }
function View_StatisticsTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-cell", [["class", "mat-cell"], ["role", "gridcell"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i3.MatCell, [i4.CdkColumnDef, i1.ElementRef], null, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit[_v.parent.context.$implicit.id]; _ck(_v, 2, 0, currVal_0); }); }
function View_StatisticsTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 2, i3.MatColumnDef, [], { name: [0, "name"] }, null), i1.ɵqud(335544320, 5, { cell: 0 }), i1.ɵqud(335544320, 6, { headerCell: 0 }), i1.ɵprd(2048, [[2, 4]], i4.CdkColumnDef, null, [i3.MatColumnDef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(0, null, null, 2, null, View_StatisticsTableComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.MatHeaderCellDef, [i1.TemplateRef], null, null), i1.ɵprd(2048, [[6, 4]], i4.CdkHeaderCellDef, null, [i3.MatHeaderCellDef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(0, null, null, 2, null, View_StatisticsTableComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.MatCellDef, [i1.TemplateRef], null, null), i1.ɵprd(2048, [[5, 4]], i4.CdkCellDef, null, [i3.MatCellDef]), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StatisticsTableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-header-row", [["class", "mat-header-row"], ["role", "row"], ["style", "text-align: center;"]], null, null, null, i6.View_MatHeaderRow_0, i6.RenderType_MatHeaderRow)), i1.ɵdid(1, 49152, null, 0, i3.MatHeaderRow, [], null, null)], null, null); }
function View_StatisticsTableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-row", [["class", "mat-row"], ["role", "row"], ["style", "text-align: center;"]], null, null, null, i6.View_MatRow_0, i6.RenderType_MatRow)), i1.ɵdid(1, 278528, null, 0, i7.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { highlight: 0 }), i1.ɵdid(3, 49152, null, 0, i3.MatRow, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.highlightRow(_v.context.$implicit)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_StatisticsTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { sort: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "mat-table", [["class", "mat-table"], ["matSort", ""], ["style", "margin-bottom: 100px;"]], null, null, null, i6.View_MatTable_0, i6.RenderType_MatTable)), i1.ɵdid(2, 2342912, [["table", 4]], 3, i3.MatTable, [i1.IterableDiffers, i1.ChangeDetectorRef, i1.ElementRef, [8, null]], { dataSource: [0, "dataSource"] }, null), i1.ɵqud(603979776, 2, { _contentColumnDefs: 1 }), i1.ɵqud(603979776, 3, { _contentRowDefs: 1 }), i1.ɵqud(335544320, 4, { _headerRowDef: 0 }), i1.ɵdid(6, 671744, [[1, 4]], 0, i5.MatSort, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatisticsTableComponent_1)), i1.ɵdid(9, 802816, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(0, null, null, 2, null, View_StatisticsTableComponent_4)), i1.ɵdid(12, 540672, null, 0, i3.MatHeaderRowDef, [i1.TemplateRef, i1.IterableDiffers], { columns: [0, "columns"] }, null), i1.ɵprd(2048, [[4, 4]], i4.CdkHeaderRowDef, null, [i3.MatHeaderRowDef]), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(0, null, null, 2, null, View_StatisticsTableComponent_5)), i1.ɵdid(16, 540672, null, 0, i3.MatRowDef, [i1.TemplateRef, i1.IterableDiffers], { columns: [0, "columns"] }, null), i1.ɵprd(2048, [[3, 4]], i4.CdkRowDef, null, [i3.MatRowDef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.columnNames; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.displayedColumns; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.displayedColumns; _ck(_v, 16, 0, currVal_3); }, null); }
export function View_StatisticsTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-statistics-table", [], null, null, null, View_StatisticsTableComponent_0, RenderType_StatisticsTableComponent)), i1.ɵdid(1, 114688, null, 0, i8.StatisticsTableComponent, [i9.ServerCommunication, i10.CommonFunctions, i11.Ng4LoadingSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatisticsTableComponentNgFactory = i1.ɵccf("app-statistics-table", i8.StatisticsTableComponent, View_StatisticsTableComponent_Host_0, {}, {}, []);
export { StatisticsTableComponentNgFactory as StatisticsTableComponentNgFactory };
