import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatSelect } from "@angular/material";
import { FormControl, FormGroup, FormGroupDirective } from "../../../../node_modules/@angular/forms";
import { _localeFactory } from "@angular/core/src/application_module";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "share-wa",
  templateUrl: "share-wa.html",
  styleUrls: ["./share-wa.css"]
})
export class ShareWaDialog {
  public title: string = "Abmeldung";

  constructor(public dialogRef: MatDialogRef<ShareWaDialog>, private deviceService: DeviceDetectorService) {
    console.log("Starting 'share wa dialog'");
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onNoClick(): void {
    this.dialogRef.close(0);
  }

  onYesClick(): void {
    this.dialogRef.close(1);
  }

  public isMobile() {
    return this.deviceService.isMobile();
  }
}
