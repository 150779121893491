import { OnInit } from "@angular/core";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ChangeUserDataIntention, ErrorCodes, TeamNames, ResponseTypeWarn, PayPalProductionKey, } from "../shared/definitions.service";
import { MatDialog } from "@angular/material";
import { JoinGuestDialog, } from "../dialogs/join-guest/join-guest";
import { PayDialog } from "../dialogs/pay-dialog/pay-dialog";
import { SetUserDataDialog, } from "../dialogs/set-user-data/set-user-data";
import { BlockUserDialog, } from "../dialogs/block-user/block-user";
import { UniversalDialog, } from "../dialogs/universal-dialog/universal-dialog";
import { JoinUserToGameDialog, } from "../dialogs/join-user-to-game/join-user-to-game";
import { AddNewGameDialog, } from "../dialogs/add-new-game/add-new-game";
import { AddGameResultDialog, } from "../dialogs/add-game-result/add-game-result";
import { ShowTeamsDialog, } from "../dialogs/show-teams/show-teams";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable } from "rxjs";
import { ShareWaDialog } from "../dialogs/share-wa/share-wa";
var HomeComponent = /** @class */ (function () {
    function HomeComponent(serverCommunication, commonFunctions, spinnerService, dialog) {
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.dialog = dialog;
        this.gamePlayerData = [];
        this.myGamePlayer = null; //represents the user in the list
        this.selection = new SelectionModel(false, []);
        this.fabOpen = false;
        this.fabDirection = "up";
        //states
        this.loadingData = true;
        this.dataLoaded = false;
        this.noConnection = false;
        this.noOpenGame = false;
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    /**
     * Loads the current game data and the game player list
     */
    HomeComponent.prototype.loadData = function () {
        var _this = this;
        var first = this.serverCommunication.getCurrentGame();
        var second = this.serverCommunication.getCurrentGamePlayer();
        this.spinnerService.show();
        this.loadingData = true;
        this.dataLoaded = false;
        this.noOpenGame = false;
        Observable.zip(first, second, function (currentGame, gamePlayer) { return ({
            currentGame: currentGame,
            gamePlayer: gamePlayer,
        }); }).subscribe(function (pair) {
            var gameData = pair.currentGame;
            var gamePlayerData = pair.gamePlayer;
            if (gameData == null || gamePlayerData == null) {
                _this.dataLoaded = true;
                _this.spinnerService.hide();
                _this.loadingData = false;
                _this.commonFunctions.showErrorMessage("Interner Fehler beim Laden");
                return;
            }
            //Game
            if (gameData.result === ResponseTypeError) {
                if (gameData.errorCode.code == ErrorCodes.NoOpeGame) {
                    _this.noOpenGame = true;
                }
                else {
                    _this.commonFunctions.showErrorMessage(gameData.message);
                }
            }
            else {
                _this.gameData = gameData.message;
            }
            //GamePlayer
            if (gamePlayerData.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(gamePlayerData.message);
                _this.gamePlayerData = [];
                _this.dataLoaded = true;
            }
            else {
                _this.gamePlayerData = gamePlayerData.message;
                if (_this.gamePlayerData.length == 0) {
                    _this.commonFunctions.showWarnMessage("Noch kein Spieler angemeldet");
                }
                _this.determineMyGamePlayer();
                _this.dataLoaded = true;
            }
            _this.spinnerService.hide();
            _this.loadingData = false;
        }, function (error) {
            console.log("No network connection");
            _this.spinnerService.hide();
            _this.noConnection = true;
            _this.dataLoaded = true;
            _this.loadingData = false;
        });
    };
    /**
     * Leave the current game
     *
     * @param gamePlayerId
     */
    HomeComponent.prototype.leaveGame = function (gamePlayerId) {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.leaveGame(gamePlayerId).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
                _this.updateGamePlayerList();
            }
            else {
                _this.commonFunctions.showSuccessMessage("Ausgetreten");
                _this.updateGamePlayerList();
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Leaves the game as the current user
     */
    HomeComponent.prototype.leaveGameThisUser = function () {
        var _this = this;
        //checken ob zeit unter 48h und dann dialog mit hinweis auf Whatsapp gruppe
        var currentTime = +Math.round(new Date().getTime() / 1000);
        var gameTime = +this.gameData.date_time;
        var delta = gameTime - currentTime;
        if (delta < 172800) {
            //48h
            var dialogRef = this.dialog.open(ShareWaDialog, {
                width: "450px",
                data: { result: 0 },
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result != undefined && result === 1) {
                    _this.leveThisUserHelper();
                }
            });
        }
        else {
            this.leveThisUserHelper();
        }
    };
    HomeComponent.prototype.leveThisUserHelper = function () {
        if (this.myGamePlayer != null) {
            var gamePlayerId = this.myGamePlayer.id;
            this.leaveGame(gamePlayerId);
        }
        else {
            this.commonFunctions.showErrorMessage("Interner Fehler: Home-Component konnte nicht aufgelöst werden");
        }
    };
    /**
     * Join the current game
     */
    HomeComponent.prototype.joinGame = function (userId) {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.joinGame(userId).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                //check for 'user blocked'
                if (data.errorCode.code == ErrorCodes.UserBlocked) {
                    _this.commonFunctions.showErrorMessage("Anmeldung nicht erlaubt");
                }
                else {
                    _this.commonFunctions.showErrorMessage(data.message);
                }
            }
            else {
                _this.commonFunctions.showSuccessMessage("Beigetreten");
                _this.updateGamePlayerList();
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Joins the game as the current user
     */
    HomeComponent.prototype.joinGameThisUser = function () {
        this.joinGame(this.commonFunctions.getUserId());
    };
    /**
     * Shows a dialog to add a guest player
     */
    HomeComponent.prototype.joinGuest = function () {
        var _this = this;
        var dialogRef = this.dialog.open(JoinGuestDialog, {
            width: "450px",
            data: { result: 0 },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined) {
                if (result.result === 1) {
                    _this.commonFunctions.showSuccessMessage("Gastspieler erfolgreich hinzugefügt");
                    _this.updateGamePlayerList();
                }
            }
        });
    };
    HomeComponent.prototype.isAdmin = function () {
        return this.commonFunctions.isAdmin();
    };
    /**
     * Shows the button to pay for a Game
     */
    HomeComponent.prototype.showPayButton = function () {
        //zuerst checken ob GamePlayer der auf den aktuellen User matcht in der Liste ist
        if (this.myGamePlayer != null &&
            +this.myGamePlayer.list_place <= +this.gameData.max_player &&
            +this.myGamePlayer.paid == 0) {
            //check ob ueberhaupt eine Zahlungsmethode hinterlegt ist
            if ((PayPalProductionKey !== null && PayPalProductionKey !== "") ||
                (this.gameData.money_pool_link !== null &&
                    this.gameData.money_pool_link !== "")) {
                return true;
            }
        }
        return false;
    };
    /**
     * Checks if a player is in game
     *
     * @param gamePlayer
     */
    HomeComponent.prototype.playerIsInGame = function (gamePlayer) {
        if (gamePlayer == null || gamePlayer == undefined) {
            return false;
        }
        if (gamePlayer.list_place <= +this.gameData.max_player) {
            return true;
        }
        return false;
    };
    /**
     * Updates the game player list
     */
    HomeComponent.prototype.updateGamePlayerList = function () {
        var _this = this;
        this.spinnerService.show();
        this.dataLoaded = false;
        this.loadingData = true;
        this.serverCommunication.getCurrentGamePlayer().subscribe(function (data) {
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
                _this.gamePlayerData = [];
                _this.dataLoaded = true;
            }
            else {
                _this.gamePlayerData = data.message;
                _this.determineMyGamePlayer();
                _this.dataLoaded = true;
            }
            _this.spinnerService.hide();
            _this.loadingData = false;
        }, function (error) {
            console.log("No network connection");
            _this.spinnerService.hide();
            _this.noConnection = true;
            _this.dataLoaded = true;
            _this.loadingData = false;
        });
    };
    /**
     * Deteremines which GamePlayer is the current user that is logged in
     */
    HomeComponent.prototype.determineMyGamePlayer = function () {
        var _this = this;
        var userId = this.commonFunctions.getUserId();
        var found = false;
        this.gamePlayerData.forEach(function (element) {
            if (element.user_id == userId) {
                _this.myGamePlayer = element;
                found = true;
            }
        });
        if (!found) {
            this.myGamePlayer = null;
        }
    };
    /**
     * Checks if the assigned GamePlayer User-id is the same as the one from the current user
     *
     * @param playerUserId
     */
    HomeComponent.prototype.playerIsThisUser = function (playerUserId) {
        var userId = this.commonFunctions.getUserId();
        if (userId == playerUserId) {
            return true;
        }
        return false;
    };
    /**
     * Opens the dialog for the payment
     */
    HomeComponent.prototype.pay = function () {
        var _this = this;
        var dialogRef = this.dialog.open(PayDialog, {
            width: "450px",
            data: {
                result: 0,
                gamePlayerId: this.myGamePlayer.id,
                moneyPoolLink: this.gameData.money_pool_link,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.commonFunctions.showSuccessMessage("Bezahlung erfolgreich");
                _this.updateGamePlayerList();
            }
        });
    };
    /**
     * Shows a dialog to select a user to join a game
     */
    HomeComponent.prototype.openDialogJoinUser = function () {
        var _this = this;
        var dialogRef = this.dialog.open(JoinUserToGameDialog, {
            width: "450px",
            data: { result: 0 },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined) {
                if (result.result === 1) {
                    _this.commonFunctions.showSuccessMessage("Spieler erfolgreich hinzugefügt");
                    _this.updateGamePlayerList();
                }
            }
        });
    };
    /**
     * Cancels the current game
     */
    HomeComponent.prototype.cancelGame = function () {
        var _this = this;
        var dialogRef = this.dialog.open(UniversalDialog, {
            width: "450px",
            data: {
                result: 0,
                operationName: "Absagen",
                contentText: "Willst du das Spiel wirklich absagen?",
                buttonNoName: "Nein",
                buttonYesName: "Ja",
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.spinnerService.show();
                _this.serverCommunication.cancelCurrentGame(_this.gameData.id).subscribe(function (data) {
                    // check if error
                    if (data.result === ResponseTypeError) {
                        _this.commonFunctions.showErrorMessage(data.message);
                    }
                    else {
                        _this.commonFunctions.showSuccessMessage("Das spiel wurde abgesagt");
                        _this.loadData();
                    }
                    _this.spinnerService.hide();
                }, function (error) {
                    _this.commonFunctions.showErrorMessage(error);
                    _this.spinnerService.hide();
                });
            }
        });
    };
    /**
     * Opens a dialog to change a users data
     *
     * @param gamePlayerData
     * @param userData
     */
    HomeComponent.prototype.openDialogChangeUserData = function (gamePlayerData, userData) {
        var _this = this;
        if (gamePlayerData === void 0) { gamePlayerData = null; }
        if (userData === void 0) { userData = null; }
        var intention = this.isAdmin()
            ? ChangeUserDataIntention.ChangeAll
            : ChangeUserDataIntention.UserSettings;
        var dialogRef = this.dialog.open(SetUserDataDialog, {
            width: "450px",
            data: {
                result: 0,
                intention: intention,
                gamePlayerData: gamePlayerData,
                userData: userData,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.commonFunctions.showSuccessMessage("Userdaten erfolgreich gesetzt");
            }
        });
    };
    /**
     * Opens a dialog to block a user
     * @param userId
     */
    HomeComponent.prototype.openDialogBlockUser = function (userId) {
        var _this = this;
        if (userId === void 0) { userId = null; }
        var dialogRef = this.dialog.open(BlockUserDialog, {
            width: "450px",
            data: { result: 0, userId: userId },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.commonFunctions.showSuccessMessage("Operation erfolgreich");
            }
        });
    };
    /**
     * Asks the user if to remove a player from the game
     *
     * @param gamePlayerId
     */
    HomeComponent.prototype.askToRemovePlayerFromList = function (gamePlayerId) {
        var _this = this;
        var dialogRef = this.dialog.open(UniversalDialog, {
            width: "450px",
            data: {
                result: 0,
                operationName: "Spieler abmelden",
                contentText: "Willst du diesen Spieler wirklich von der Liste nehmen?",
                buttonNoName: "Nein",
                buttonYesName: "Ja",
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined) {
                if (result.result === 1 || result.result === 2) {
                    _this.leaveGame(gamePlayerId);
                }
            }
        });
    };
    /**
     * Open Dialog to create new Game
     */
    HomeComponent.prototype.openCreateNewGameDialog = function () {
        var _this = this;
        console.log("Get last game data");
        this.spinnerService.show();
        this.serverCommunication.getLastFinishedGame().subscribe(function (data) {
            var dialogData = {
                result: 0,
                gameNumber: -1,
                gameId: -1,
                maxPlayer: -1,
                moneyPoolLink: "",
            };
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                var lastGame = data.message;
                dialogData.gameNumber = +lastGame.game_number + 1;
                dialogData.gameId = +lastGame.id;
                dialogData.maxPlayer = +lastGame.max_player;
            }
            _this.spinnerService.hide();
            //open dialog
            var dialogRef = _this.dialog.open(AddNewGameDialog, {
                width: "450px",
                data: dialogData,
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result != undefined) {
                    if (result.result === 1) {
                        _this.commonFunctions.showSuccessMessage("Spiel wurde angelegt");
                    }
                    _this.loadData();
                }
            });
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Open dialog to set the game results
     */
    HomeComponent.prototype.openDialogAddGameResult = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AddGameResultDialog, {
            width: "450px",
            data: {
                result: 0,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.commonFunctions.showSuccessMessage("Spielergebnis eingetragen");
            }
        });
    };
    /**
     * Close the current game
     */
    HomeComponent.prototype.closeGame = function () {
        var _this = this;
        var dialogRef = this.dialog.open(UniversalDialog, {
            width: "450px",
            data: {
                result: 0,
                operationName: "Spielergebnis eingetragen",
                contentText: "Hast du schon das Spielergebnis eingetragen?",
                buttonNoName: "Nein",
                buttonYesName: "Ja",
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                var dialogRef_1 = _this.dialog.open(UniversalDialog, {
                    width: "450px",
                    data: {
                        result: 0,
                        operationName: "Spiel abschließen",
                        contentText: "Willst du das Spiel abschließen?",
                        buttonNoName: "Nein",
                        buttonYesName: "Ja",
                    },
                });
                dialogRef_1.afterClosed().subscribe(function (result) {
                    if (result != undefined && result.result === 1) {
                        _this.spinnerService.show();
                        _this.serverCommunication.closeCurrentGame().subscribe(function (data) {
                            // check if error
                            if (data.result === ResponseTypeError) {
                                _this.commonFunctions.showErrorMessage(data.message);
                            }
                            else {
                                _this.loadData(); //update all data
                            }
                            _this.spinnerService.hide();
                        }, function (error) {
                            _this.commonFunctions.showErrorMessage(error);
                            _this.spinnerService.hide();
                        });
                    }
                });
            }
        });
    };
    /**
     * Starts the process of automatic team calculation
     */
    HomeComponent.prototype.automaticTeamGeneration = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.calculateTeams().subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
                _this.updateGamePlayerList();
            }
            else {
                _this.updateGamePlayerList();
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Gets the team strength
     *
     * @param team
     */
    HomeComponent.prototype.getTeamStrength = function (team) {
        var cnt = 0;
        this.gamePlayerData.forEach(function (element) {
            var teamName = +team == 1 ? TeamNames.Team1 : TeamNames.Team2;
            if (element.team == teamName) {
                cnt += +element.player_strength;
            }
        });
        return cnt;
    };
    /**
     * Shows a dialog to select a user to join a game
     */
    HomeComponent.prototype.openDialogShowTeams = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ShowTeamsDialog, {
            width: "550px",
            data: { result: 0, gamePlayer: this.gamePlayerData },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined) {
                if (result.result === 1) {
                    _this.commonFunctions.showSuccessMessage("Neue Teamverteilung gespeichert");
                    _this.updateGamePlayerList();
                }
            }
        });
    };
    //handles a click on a list element
    HomeComponent.prototype.onListClick = function (listElement) {
        this.selection.toggle(listElement);
    };
    HomeComponent.prototype.isSelected = function (player) {
        if (player === undefined ||
            this.selection === undefined ||
            this.selection.selected === undefined) {
            return false;
        }
        if (this.selection.selected.indexOf(player) >= 0) {
            return true;
        }
        return false;
    };
    HomeComponent.prototype.getSelected = function () {
        return this.selection.selected[0];
    };
    HomeComponent.prototype.isGamePresent = function () {
        return (this.gameData != null &&
            this.gameData != undefined &&
            this.gamePlayerData != null &&
            this.gamePlayerData != undefined);
    };
    //checks if the game is finished
    HomeComponent.prototype.isGameFinished = function () {
        if (this.gameData != null &&
            this.gameData != undefined &&
            this.gameData.finished == 1) {
            return true;
        }
        return false;
    };
    /**
     * Checks if the current game is passed
     */
    HomeComponent.prototype.isGameOutdated = function () {
        if (this.gameData != null && this.gameData != undefined) {
            var currentTime = +Math.round(new Date().getTime() / 1000);
            var gameTime = +this.gameData.date_time;
            if (currentTime < gameTime + 18000) {
                //inkl. 5h delay
                return false;
            }
        }
        return true;
    };
    HomeComponent.prototype.setPaid = function (gamePlayerId) {
        var _this = this;
        this.serverCommunication.setAsPaid(gamePlayerId).subscribe(function (data) {
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.updateGamePlayerList();
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage("Setzen des Zahlungsstatus fehlgeschlagen");
            _this.spinnerService.hide();
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
