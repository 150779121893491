import { MatDialogRef } from "@angular/material";
import { FormGroup } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, TeamNames } from "../../shared/definitions.service";
var AddGameResultDialog = /** @class */ (function () {
    function AddGameResultDialog(dialogRef, dialogData, serverCommunication, commonFunctions, spinnerService) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.title = "Spielergebnis eintragen";
        this.team1 = { name: TeamNames.Team1, wins: 0 };
        this.team2 = { name: TeamNames.Team2, wins: 0 };
        this.standoff = 0;
        console.log("Starting 'add game result' dialog");
        //set up form group
        this.formGroup = new FormGroup({});
    }
    AddGameResultDialog.prototype.ngOnInit = function () { };
    AddGameResultDialog.prototype.ngAfterViewInit = function () { };
    AddGameResultDialog.prototype.onNoClick = function () {
        this.dialogData.result = 0;
        this.dialogRef.close(this.dialogData);
    };
    AddGameResultDialog.prototype.setData = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.addGameResult(this.team1.wins, this.team2.wins, this.standoff).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.dialogData.result = 1;
                _this.dialogRef.close(_this.dialogData);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    return AddGameResultDialog;
}());
export { AddGameResultDialog };
