<h1 mat-dialog-title>{{ title }}</h1>
<hr />

<form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="joinUser()">
  <div mat-dialog-content>
    <!--Suchfeld-->
    <mat-form-field class="set-user-data-element">
      <mat-select [formControl]="userSelectionCtrl" placeholder="User auswählen" (change)="onChangeUser(userSelectionCtrl.value)" #singleSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let user of (filteredUsers | async)" [value]="user">
          {{ user.userName }} ({{ user.firstName }} {{ user.lastName }})
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <hr />

  <div mat-dialog-actions>
    <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">Abbrechen</button>
    <button mat-raised-button color="primary" style="width: 47%;" type="submit">
      Hinzufügen
    </button>
  </div>
</form>
