<h1 mat-dialog-title>Bezahlen</h1>
<hr />
<div mat-dialog-content>
  <div *ngIf="moneyPoolLink !== null && moneyPoolLink !== ''">
    <button
      mat-raised-button
      color="primary"
      isRound="true"
      style="
        width: 100%;
        margin-right: 10px;
        margin-bottom: 40px;
        margin-top: 20px;
      "
      (click)="onMoneyPoolClick()"
    >
      MoneyPool
    </button>
  </div>

  <div
    *ngIf="moneyPoolLink !== null && moneyPoolLink !== '' && payPalKey !== null && payPalKey !== ''"
  >
    <h2 style="margin-bottom: 60px;"><span>Oder</span></h2>
  </div>

  <div *ngIf="payPalKey !== null && payPalKey !== ''">
    <h4 *ngIf="paypalLoad">Paypal button is loading</h4>
    <div
      id="paypal-checkout-btn"
      style="margin-left: 10px; margin-right: 10px;"
    ></div>
  </div>
  <hr />
  <button
    mat-raised-button
    tabindex="-1"
    color="warn"
    style="width: 90%; margin-left: 5%; margin-top: 10px;"
    (click)="onNoClick()"
  >
    Abbrechen
  </button>
</div>
