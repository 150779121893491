<!-- https://www.npmjs.com/package/ng4-loading-spinner -->
<!-- <ng4-loading-spinner [template]="spinnerTemplate"> </ng4-loading-spinner> -->
<!-- With custom spinner. Variable is defined in according .ts -->
<!-- <ng4-loading-spinner> </ng4-loading-spinner> -->
<!-- <ng4-loading-spinner [threshold]="2000" [timeout]="4000" [template]="template" [loadingText]="'Please wait...'" [zIndex]="9999"></ng4-loading-spinner> -->
<ng4-loading-spinner [timeout]="30000" [zIndex]="9999"></ng4-loading-spinner>
<toaster-container [toasterconfig]="config"></toaster-container>

<div>
  <mat-toolbar color="primary" class="fixed-header z-depth-3">
    <button
      *ngIf="isLoggedIn()"
      style="margin-right: 20px;"
      mat-icon-button
      (click)="sidenav?.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="example-app-name" *ngIf="isLoggedIn()">Freitags-Soccer</h1>

    <!-- This fills the remaining space of the current row -->
    <span class="example-fill-remaining-space"></span>

    <!-- Username -->
    <!-- <span style="font-size: small; margin-right: 15px;" *ngIf="isLoggedIn()">{{
      getUsername()
    }}</span> -->

    <!-- Account circle -->
    <mat-icon md-list-icon *ngIf="isLoggedIn()">account_circle</mat-icon>

    <!-- Vertical menu button -->
    <button mat-icon-button [mat-menu-trigger-for]="menu" *ngIf="isLoggedIn()">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-toolbar>

  <!-- Vertical menu -->
  <mat-menu x-position="after" #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="isAdmin() && isHomeComponent()"
      (click)="openCreateNewGameDialog()"
    >
      <span>Neues Spiel</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        isAdmin() && isHomeComponent() && gameIsPresent() && !gameIsOutdated()
      "
      (click)="cancelGame()"
    >
      <span>Spiel absagen</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        isAdmin() && isHomeComponent() && gameIsPresent() && !gameIsOutdated()
      "
      (click)="joinGuest()"
    >
      <span>Gast hinzufügen</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        isAdmin() && isHomeComponent() && gameIsPresent() && !gameIsOutdated()
      "
      (click)="joinUser()"
    >
      <span>Spieler hinzufügen</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isAdmin() && isHomeComponent()"
      (click)="changeUserData()"
    >
      <span>Userdaten ändern</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isAdmin() && isHomeComponent()"
      (click)="blockUser()"
    >
      <span>User blockieren</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isAdmin() && isHomeComponent()"
      (click)="openAddPlayerDialog()"
    >
      <span>Neuen User registrieren</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isAdmin() && isHomeComponent()"
      (click)="openSetMoneyPoolLinkDialog()"
    >
      <span>MoneyPool link setzen</span>
    </button>

    <button mat-menu-item *ngIf="isLoggedIn()" (click)="logout()">
      <mat-icon md-list-icon>exit_to_app</mat-icon> Log Out
    </button>
    <button mat-menu-item *ngIf="!isLoggedIn()" routerLink="login">
      <mat-icon md-list-icon>exit_to_app</mat-icon> Log In
    </button>
  </mat-menu>
</div>

<mat-sidenav-container id="content" (backdropClick)="close()" fullscreen>
  <mat-sidenav
    *ngIf="isLoggedIn()"
    #sidenav
    class="example-sidenav z-depth-2"
    mode="side"
    opened="true"
    [fixedInViewport]="true"
    [fixedTopGap]="63"
    [fixedBottomGap]="0"
    (keydown.escape)="close()"
    [ngStyle]="{ 'width.em': 4 }"
    (mouseenter)="increase()"
    (mouseleave)="decrease()"
    disableClose
  >
    <!--Old:-->
    <!-- [ngStyle]="{ 'width.em': sidenavWidth }" -->

    <mat-nav-list>
      <mat-list-item routerLink="home" style="margin-left: 5px;">
        <!-- routerLinkActive="active" markiert dann den aktuellen Eintrag in der Liste -->
        <mat-icon md-list-icon>home</mat-icon>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <span>Home</span>
        </div>
      </mat-list-item>

      <!-- <mat-list-item routerLink="statistics-table" style="margin-left: 5px;">
        <mat-icon md-list-icon>insert_chart</mat-icon>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <span>Statistik - Tabelle</span>
        </div>
      </mat-list-item>

      <mat-list-item routerLink="statistics-graphics" style="margin-left: 5px;">
        <mat-icon md-list-icon>pie_chart</mat-icon>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <span>Statistik - Grafik</span>
        </div>
      </mat-list-item> -->

      <mat-list-item routerLink="settings" style="margin-left: 5px;">
        <mat-icon md-list-icon>settings</mat-icon>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <span>Einstellungen</span>
        </div>
      </mat-list-item>

      <mat-list-item
        *ngIf="!isLoggedIn()"
        routerLink="login"
        style="margin-left: 5px;"
      >
        <mat-icon md-list-icon>exit_to_app</mat-icon>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <span>Login</span>
        </div>
      </mat-list-item>

      <mat-list-item
        *ngIf="isLoggedIn()"
        (click)="logout()"
        style="margin-left: 5px;"
      >
        <mat-icon md-list-icon>power_settings_new</mat-icon>
        <div *ngIf="sidenavWidth > 6" class="sidenav-item">
          <span>Logout</span>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content
    style="margin-top: 55px;"
    [ngClass]="getStyleClassForComponent()"
    [ngStyle]="{ height: getHeightContentAsPx() }"
  >
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>

  <!--Fixed bottom bar-->
  <mat-sidenav-content
    *ngIf="gameIsPresent()"
    [ngStyle]="{ height: getHeightBottomBarAsPx() }"
    style="background-color: #3f51b5;"
    class="bottom-nav"
  >
    <div>
      <button
        mat-icon-button
        *ngIf="isInList() && !gameIsOutdated()"
        (click)="leaveGameThisUser()"
        class="game-enter-leave-btn"
      >
        <mat-icon style="margin-bottom: -10px;">clear</mat-icon>
        <p>Austreten</p>
      </button>
      <button
        mat-icon-button
        *ngIf="!isInList() && !gameIsOutdated()"
        (click)="joinGameThisUser()"
        class="game-enter-leave-btn"
      >
        <mat-icon style="margin-bottom: -10px;">exit_to_app</mat-icon>
        <p>Eintreten</p>
      </button>
      <button mat-icon-button (click)="updateList()" class="list-update-btn">
        <mat-icon aria-label="Update" style="margin-bottom: -10px;"
          >refresh</mat-icon
        >
        <p>Update</p>
      </button>
      <button
        mat-icon-button
        *ngIf="showPayButton() && !gameIsOutdated()"
        (click)="pay()"
        class="pay-btn"
      >
        <mat-icon aria-label="Bezahlen" style="margin-bottom: -10px;"
          >euro_symbol</mat-icon
        >
        <p>Bezahlen</p>
      </button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
