import { BehaviorSubject } from 'rxjs';
var DataService = /** @class */ (function () {
    function DataService() {
        //Info: https://angularfirebase.com/lessons/sharing-data-between-angular-components-four-methods/
        this.currentSwitchPorts = new BehaviorSubject([]);
        this.currentSwitchPortList = this.currentSwitchPorts.asObservable();
    }
    DataService.prototype.changeCurrentSwitchPortList = function (switchPortList) {
        this.currentSwitchPorts.next(switchPortList);
    };
    return DataService;
}());
export { DataService };
