import "../polyfills";
import { registerLocaleData } from "@angular/common";
import lcoaleDeAt from "@angular/common/locales/de";
/** IMPORTANT : IE10 and IE11 requires the following to support `@angular/animation` (which is used by this module).
Run `npm install --save web-animations-js`.
*/
import "web-animations-js";
export function tokenGetter() {
    return localStorage.getItem("access_token");
}
registerLocaleData(lcoaleDeAt);
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
