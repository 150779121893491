import { OnInit } from "@angular/core";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../shared/auth.service";
import { MatDialog } from "@angular/material";
import { ForgotPassworDialog } from "../dialogs/forgot-password/forgot-password";
import { ResponseTypeError } from "../shared/definitions.service";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(serverCommunication, commonFunctions, spinnerService, route, router, authService, _dialog) {
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.route = route;
        this.router = router;
        this.authService = authService;
        this._dialog = _dialog;
        this.loadingData = true;
        this.usernameFormControl = new FormControl();
        this.passwordFormControl = new FormControl();
        //set up form group
        this.formGroup = new FormGroup({
            usernameFormControl: this.usernameFormControl,
            passwordFormControl: this.passwordFormControl
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        console.log("Login component loaded");
        // reset login status
        this.authService.logout();
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    };
    /**
     * Verifies the credentials
     */
    LoginComponent.prototype.verify = function (formDirective) {
        var _this = this;
        this.formGroupDirective = formDirective; //save to local var
        this.loadingData = true;
        var selectedUsername = this.usernameFormControl.value;
        var selectedPassword = this.passwordFormControl.value;
        this.authService
            .login(selectedUsername, selectedPassword)
            .then(function (successful) {
            if (successful) {
                _this.resetFormGroup();
                // login successful so redirect to return url
                _this.router.navigateByUrl(_this.returnUrl);
            }
            else {
                _this.commonFunctions.showErrorMessage("Angegeben Zugangsdaten sind inkorrekt");
            }
            _this.loadingData = false;
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.loadingData = false;
        })
            .catch(function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.loadingData = false;
        });
    };
    /**
     * Reset Password
     */
    LoginComponent.prototype.forgotPassword = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ForgotPassworDialog, {
            width: "450px",
            data: { result: 0 }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.serverCommunication.prepareResetPassword(result.email).subscribe(function (data) {
                    // check if error
                    if (data.result === ResponseTypeError) {
                        _this.commonFunctions.showErrorMessage(data.message);
                    }
                    else {
                        _this.commonFunctions.showSuccessMessage("Du bekommst eine E-Mail");
                    }
                    _this.spinnerService.hide();
                }, function (error) {
                    _this.commonFunctions.showErrorMessage(error);
                    _this.spinnerService.hide();
                });
            }
        });
    };
    /**
     * Resets the whole form data
     */
    LoginComponent.prototype.resetFormGroup = function () {
        this.formGroupDirective.resetForm();
        this.formGroup.reset();
    };
    return LoginComponent;
}());
export { LoginComponent };
