import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatSelect } from "@angular/material";
import { FormControl, FormGroup, FormGroupDirective } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { _localeFactory } from "@angular/core/src/application_module";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { UserData, ResponseTypeError, ResponseTypeWarn } from "../../shared/definitions.service";

export interface JoinUserDialogData {
  result: number;
}

@Component({
  selector: "join-user-to-game",
  templateUrl: "join-user-to-game.html",
  styleUrls: ["./join-user-to-game.css"]
})
export class JoinUserToGameDialog {
  public title: string = "Spieler hinzufügen";
  public selectedUser: UserData;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;
  public userSelectionCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();

  //-------------------------------
  /** list of users */
  protected usersList: UserData[] = [];

  /** list of users filtered by search keyword */
  public filteredUsers: ReplaySubject<UserData[]> = new ReplaySubject<UserData[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @ViewChild("singleSelect") singleSelect: MatSelect;

  //---------------------------------

  constructor(
    public dialogRef: MatDialogRef<JoinUserToGameDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: JoinUserDialogData,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("Starting 'join user to game dialog'");

    //set up form group
    this.formGroup = new FormGroup({
      userSelectionCtrl: this.userSelectionCtrl,
      userFilterCtrl: this.userFilterCtrl
    });

    this.loadAllUsers();
  }

  ngOnInit() {
    // set initial selection
    //this.userSelectionCtrl.setValue(this.usersList[1]);

    // load the initial bank list
    this.filteredUsers.next(this.usersList.slice());

    // listen for search field value changes
    this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterUser();
    });
  }

  ngAfterViewInit() {}

  onNoClick(): void {
    this.dialogData.result = 0;
    this.dialogRef.close(this.dialogData);
  }

  //Raised when selection of user is changed
  onChangeUser(user: UserData) {
    this.selectedUser = user;
  }

  public joinUser() {
    this.spinnerService.show();

    this.serverCommunication.joinGame(this.selectedUser.getId()).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
          this.dialogData.result = 2;
          this.dialogRef.close(this.dialogData);
        } else {
          this.dialogData.result = 1;
          this.dialogRef.close(this.dialogData);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  private loadAllUsers() {
    this.spinnerService.show();
    this.serverCommunication.getAllUsers().subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.usersList = UserData.convertArray(data.message);
          this.filteredUsers.next(this.usersList);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
        this.dialogRef.close(this.dialogData);
      }
    );
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredUsers
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  /**
   * Filters the user selection
   */
  protected filterUser() {
    if (!this.usersList) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.usersList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredUsers.next(
      this.usersList.filter(
        user =>
          user.firstName.toLowerCase().indexOf(search) > -1 ||
          user.lastName.toLowerCase().indexOf(search) > -1 ||
          user.userName.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
