import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormGroup, FormGroupDirective, FormControl } from "@angular/forms";
import { CommonFunctions } from "../shared/common-functions.service";
import { UserData, ResponseTypeError } from "../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ServerCommunication } from "../shared/server-communication.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"]
})
export class SettingsComponent implements OnInit, AfterViewInit {
  private _userData: UserData;
  public profileImageAccepted: boolean = true;
  public selectedImage: string = null;
  public imageInputText: string = "Profilbild ändern";

  public email: string = "";
  public userName: string;
  public firstName: string;
  public lastName: string;
  public mobilePhoneNumber: string;
  public imageName: string;
  public password1: string;
  public password2: string;
  public checkboxReceiveMails: boolean = false;
  public checkboxReceivePush: boolean = false;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;
  public usernameFormControl = new FormControl();
  public emailFormControl = new FormControl();
  public firstnameFormControl = new FormControl();
  public lastnameFormControl = new FormControl();
  public mobilephonenumFormControl = new FormControl();
  // public imagenameFormControl = new FormControl({ value: this.imageInputText, disabled: true });
  public checkboxReceiveMailsFormControl = new FormControl();
  public checkboxReceivePushFormControl = new FormControl();

  constructor(
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService,
    private serverCommunication: ServerCommunication
  ) {
    //set up form group
    this.formGroup = new FormGroup({
      usernameFormControl: this.usernameFormControl,
      emailFormControl: this.emailFormControl,
      firstnameFormControl: this.firstnameFormControl,
      lastnameFormControl: this.lastnameFormControl,
      mobilephonenumFormControl: this.mobilephonenumFormControl,
      // imagenameFormControl: this.imagenameFormControl,
      checkboxReceiveMailsFormControl: this.checkboxReceiveMailsFormControl,
      checkboxReceivePushFormControl: this.checkboxReceivePushFormControl
    });
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.loadUserData();
  }

  /**
   * Loads the users data and sets it to the fields
   *
   * @param reFetchData
   */
  private loadUserData(reFetchData: boolean = false) {
    this.serverCommunication
      .getUserData(reFetchData)
      .then((userData: UserData) => {
        if (userData != null) {
          this.setDataToControls(userData);
          this._userData = userData;
        } else {
          this.commonFunctions.showErrorMessage("Userdaten fehlerhaft");
        }
      })
      .catch(reason => {
        this.commonFunctions.showErrorMessage("Userdaten konnten nicht geladen werden");
      });
  }

  /**
   * Sets the data to the controls
   * @param userData
   */
  private setDataToControls(userData: UserData) {
    this.usernameFormControl.setValue(userData.userName);
    this.firstnameFormControl.setValue(userData.firstName);
    this.lastnameFormControl.setValue(userData.lastName);
    this.emailFormControl.setValue(userData.email);
    this.mobilephonenumFormControl.setValue(userData.mobilePhoneNumber);
    this.checkboxReceiveMailsFormControl.setValue(+userData.receiveMails);
    this.checkboxReceivePushFormControl.setValue(+userData.receivePush);
  }

  // /**
  //  * Processes the uploaded profile picture
  //  *
  //  * @param imageInput
  //  */
  // public processFile(imageInput: any) {
  //   const file: File = imageInput.files[0];
  //   const reader = new FileReader();

  //   reader.addEventListener("load", (event: any) => {
  //     this.selectedImage = event.target.result;
  //     this.imagenameFormControl.setValue(file.name);
  //   });

  //   if (this.acceptImage(file)) {
  //     reader.readAsDataURL(file);
  //   }
  // }

  /**
   * Ensures that only numbers are entered
   *
   * @param event
   */
  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //check if first char is '0' and dont accept it
    if ((this.mobilephonenumFormControl.value == undefined || this.mobilephonenumFormControl.value == "") && charCode == 48) {
      return false;
    }
    return true;
  }

  // /**
  //  * Checks if the image is accepted
  //  */
  // private acceptImage(file: any): boolean {
  //   if (file != undefined) {
  //     var type = file.type;
  //     var size = file.size;
  //     var png = "image/png";
  //     var jpg = "image/jpg";
  //     var jpeg = "image/jpeg";

  //     if ((type == png || type == jpeg || type == jpg) && size < 512000) {
  //       this.profileImageAccepted = true;
  //       return true;
  //     } else {
  //       this.profileImageAccepted = false;
  //     }
  //   }
  //   return false;
  // }

  public saveData() {
    this.spinnerService.show();

    let userName: string = this.usernameFormControl.value;
    let firstName: string = this.firstnameFormControl.value;
    let lastName: string = this.lastnameFormControl.value;
    let email: string = this.emailFormControl.value;
    let mobilePhone: string = this.mobilephonenumFormControl.value;
    // let receiveMails: number = this.checkboxReceiveMailsFormControl.value ? 1 : 0;
    // let receivePush: number = this.checkboxReceivePushFormControl.value ? 1 : 0;

    this.serverCommunication
      .setUserData(this._userData.getId(), userName, firstName, lastName, null, mobilePhone, email, null, null, null, null, null)
      .subscribe(
        data => {
          // check if error
          if (data.result === ResponseTypeError) {
            this.commonFunctions.showErrorMessage(data.message);
          } else {
            this.loadUserData(true);
            this.commonFunctions.showSuccessMessage("Daten gespeichert");
          }
          this.spinnerService.hide();
        },
        error => {
          this.commonFunctions.showErrorMessage(error);
          this.spinnerService.hide();
        }
      );
  }
}
