import { MatDialogRef, MatSelect } from "@angular/material";
import { FormControl, FormGroup } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { UserData, ResponseTypeError } from "../../shared/definitions.service";
var BlockUserDialog = /** @class */ (function () {
    //---------------------------------
    function BlockUserDialog(dialogRef, dialogData, serverCommunication, commonFunctions, spinnerService) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.title = "User blockieren";
        this.minDateNow = new Date();
        this.showUserSearch = false;
        this.userSelectionCtrl = new FormControl();
        this.userFilterCtrl = new FormControl();
        this.blockUntilFormControl = new FormControl();
        this.blockUserFormControl = new FormControl();
        //-------------------------------
        /** list of users */
        this.usersList = [];
        /** list of users filtered by search keyword */
        this.filteredUsers = new ReplaySubject(1);
        /** Subject that emits when the component has been destroyed. */
        this._onDestroy = new Subject();
        console.log("Starting 'block user dialog'");
        //set up form group
        this.formGroup = new FormGroup({
            userSelectionCtrl: this.userSelectionCtrl,
            userFilterCtrl: this.userFilterCtrl,
            blockUntilFormControl: this.blockUntilFormControl,
            blockUserFormControl: this.blockUserFormControl
        });
        if (dialogData.userId != null) {
            this.loadUser(dialogData.userId);
            this.blockDateTime = new Date();
            this.blockDateTime.setMonth(this.blockDateTime.getMonth() + 1);
        }
        else {
            this.showUserSearch = true;
            this.loadAllUsers();
        }
    }
    BlockUserDialog.prototype.ngOnInit = function () {
        var _this = this;
        if (this.showUserSearch) {
            // set initial selection
            //this.userSelectionCtrl.setValue(this.usersList[1]);
            // load the initial bank list
            this.filteredUsers.next(this.usersList.slice());
            // listen for search field value changes
            this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(function () {
                _this.filterUser();
            });
        }
    };
    BlockUserDialog.prototype.ngAfterViewInit = function () {
        if (this.showUserSearch) {
            this.setInitialValue();
        }
    };
    BlockUserDialog.prototype.onNoClick = function () {
        this.dialogData.result = 0;
        this.dialogRef.close(this.dialogData);
    };
    BlockUserDialog.prototype.onYesClick = function (formDirective) {
        this.formGroupDirective = formDirective;
        this.dialogData.result = 1;
        this.dialogRef.close(this.dialogData);
    };
    //Raised when selection of user is changed
    BlockUserDialog.prototype.onChangeUser = function (user) {
        this.selectedUser = user;
        this.blockUserFormControl.setValue(user.isBlocked());
        if (!user.isBlocked()) {
            this.blockUntilFormControl.disable();
        }
        else {
            this.blockUntilFormControl.enable();
        }
        if (user.blockedUntil != 0 && user.blockedUntil != null && user.blockedUntil != NaN) {
            this.blockDateTime = new Date(user.blockedUntil * 1000);
        }
        else {
            this.blockDateTime = new Date();
            this.blockDateTime.setMonth(this.blockDateTime.getMonth() + 1);
        }
    };
    /**
     * Raised when checkbox to block user is clicked
     * @param checked
     */
    BlockUserDialog.prototype.checkedChangeBlockUser = function (checked) {
        if (!checked) {
            this.blockUntilFormControl.disable();
        }
        else {
            this.blockUntilFormControl.enable();
        }
    };
    BlockUserDialog.prototype.setData = function () {
        var _this = this;
        this.spinnerService.show();
        var block = this.blockUserFormControl.value ? 1 : 0;
        var blockUntil = this.blockDateTime.getTime() / 1000;
        if (block == 0) {
            blockUntil = 0;
        }
        this.serverCommunication.setUserData(this.selectedUser.getId(), null, null, null, null, null, null, block, blockUntil).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.dialogData.result = 1;
                _this.dialogRef.close(_this.dialogData);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    BlockUserDialog.prototype.loadUser = function (id) {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getUser(id).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                var userData = UserData.convert(data.message);
                //set local field
                _this.selectedUser = userData;
                //Checkbox
                _this.blockUserFormControl.setValue(userData.isBlocked());
                //block until
                var blockedUntil = userData.blockedUntil;
                if (blockedUntil != undefined && blockedUntil != null) {
                    _this.blockDateTime = new Date(blockedUntil * 1000);
                }
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
            _this.dialogRef.close(_this.dialogData);
        });
    };
    BlockUserDialog.prototype.loadAllUsers = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getAllUsers().subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.usersList = UserData.convertArray(data.message);
                _this.filteredUsers.next(_this.usersList);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
            _this.dialogRef.close(_this.dialogData);
        });
    };
    /**
     * Sets the initial value after the filteredBanks are loaded initially
     */
    BlockUserDialog.prototype.setInitialValue = function () {
        var _this = this;
        this.filteredUsers
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            _this.singleSelect.compareWith = function (a, b) { return a && b && a.id === b.id; };
        });
    };
    /**
     * Filters the user selection
     */
    BlockUserDialog.prototype.filterUser = function () {
        if (!this.usersList) {
            return;
        }
        // get the search keyword
        var search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredUsers.next(this.usersList.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the users
        this.filteredUsers.next(this.usersList.filter(function (user) {
            return user.firstName.toLowerCase().indexOf(search) > -1 ||
                user.lastName.toLowerCase().indexOf(search) > -1 ||
                user.userName.toLowerCase().indexOf(search) > -1;
        }));
    };
    return BlockUserDialog;
}());
export { BlockUserDialog };
