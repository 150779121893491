<h1 mat-dialog-title>Neuen Spieler anlegen</h1>
<hr />
<div mat-dialog-content>
  <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
    <input matInput type="email" placeholder="Email Adresse angeben" [(ngModel)]="email" minlength="3" maxlength="50" required cdkFocusInitial />
    <button mat-button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="email = ''"><mat-icon>close</mat-icon></button>
  </mat-form-field>

  <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
    <input matInput type="number" placeholder="Spielerstärke (1-10)" min="1" max="10" [(ngModel)]="playerStrength" />
  </mat-form-field>
</div>
<hr />

<div mat-dialog-actions>
  <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">Abbrechen</button>
  <button
    mat-raised-button
    color="primary"
    style="width: 47%;"
    type="button"
    (click)="onYesClick()"
    [disabled]="email.length < 3 || playerStrength == 0"
    cdkFocusInitial
  >
    Anlegen
  </button>
</div>
