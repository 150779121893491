import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ToasterService } from "angular2-toaster";
import { UniversalDialog } from "../dialogs/universal-dialog/universal-dialog";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NotificationDialog, NotificationDialogData } from "../dialogs/notification/notification";

@Injectable()
export class CommonFunctions {
  private toasterService: ToasterService;
  private jwtHelper: JwtHelperService;
  private _decodedToken;

  constructor(toasterService: ToasterService, public dialog: MatDialog) {
    this.toasterService = toasterService;
    this.jwtHelper = new JwtHelperService();
  }

  public showErrorMessage(message: string) {
    var data: NotificationDialogData = { duration: 4000, text: message };
    this.dialog.open(NotificationDialog, {
      width: "100%",
      maxWidth: "100%",
      panelClass: "my-panel-error",
      data: data,
      position: { bottom: "0px" }
    });
    // this._notify.show(message, { position: "bottom", duration: "4000", type: "error" });
  }

  public showSuccessMessage(message: string) {
    var data: NotificationDialogData = { duration: 2500, text: message };
    this.dialog.open(NotificationDialog, {
      width: "100%",
      maxWidth: "100%",
      panelClass: "my-panel-success",
      data: data,
      position: { bottom: "0px" }
    });
    // this._notify.show(message, { position: "bottom", duration: "3000", type: "success" });
  }

  public showWarnMessage(message: string) {
    var data: NotificationDialogData = { duration: 4000, text: message };
    this.dialog.open(NotificationDialog, {
      width: "100%",
      maxWidth: "100%",
      panelClass: "my-panel-warn",
      data: data,
      position: { bottom: "0px" }
    });
    // this._notify.show(message, { position: "bottom", duration: "3000", type: "warn" });
  }

  public showErrorToast(message: string, title: string = "Error") {
    this.toasterService.popAsync("error", title, message);
  }

  public showSuccessToast(message: string, title: string = "Success") {
    this.toasterService.popAsync("success", title, message);
  }

  public showWarnToast(message: string, title: string = "Warning") {
    this.toasterService.popAsync("warn", title, message);
  }

  public clearToken() {
    this._decodedToken = null;
  }

  /**
   * Gets the decoded token data
   */
  private getDecodedJWT() {
    //load token
    if (this._decodedToken == null || this._decodedToken == undefined) {
      var token = localStorage.getItem("jwt");
      if (token == null || token == undefined || token == "" || this.jwtHelper.isTokenExpired(token)) {
        //console.log("Token null or expired");
        return -1;
      }
      this._decodedToken = this.jwtHelper.decodeToken(token);
    }
    if (this._decodedToken == null) {
      console.log("JWT could not be decoded");
      return null;
    }
    var data = this._decodedToken.data;
    if (data != null) {
      return data;
    } else {
      console.log("Could not extract data from token");
    }
    return null;
  }

  /**
   * Checks if the user is admin
   */
  public isAdmin(): boolean {
    var data = this.getDecodedJWT();
    if (data != null) {
      return data.userGroup == "admin";
    }
    return false;
  }

  /**
   * Gets the user id
   */
  public getUserId(): number {
    var token = this.getDecodedJWT();
    if (token != null) {
      if (token.id == undefined || token.id == null) {
        return -1;
      }
      return token.id;
    }
    return -1;
  }

  public openDialog(
    operationName: string,
    contentText: string,
    buttonNoName: string = "No",
    buttonYesName: string = "Yes"
  ): MatDialogRef<UniversalDialog, any> {
    const dialogRef = this.dialog.open(UniversalDialog, {
      width: "250px",
      data: { operationName: operationName, contentText: contentText, buttonNoName: buttonNoName, buttonYesName: buttonYesName, result: 0 }
    });
    return dialogRef;
  }
}

export class KeyValuePair {
  public key: string;
  public value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}
