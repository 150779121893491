import { OnInit, AfterViewInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { CommonFunctions } from "../shared/common-functions.service";
import { ResponseTypeError } from "../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ServerCommunication } from "../shared/server-communication.service";
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(commonFunctions, spinnerService, serverCommunication) {
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.serverCommunication = serverCommunication;
        this.profileImageAccepted = true;
        this.selectedImage = null;
        this.imageInputText = "Profilbild ändern";
        this.email = "";
        this.checkboxReceiveMails = false;
        this.checkboxReceivePush = false;
        this.usernameFormControl = new FormControl();
        this.emailFormControl = new FormControl();
        this.firstnameFormControl = new FormControl();
        this.lastnameFormControl = new FormControl();
        this.mobilephonenumFormControl = new FormControl();
        // public imagenameFormControl = new FormControl({ value: this.imageInputText, disabled: true });
        this.checkboxReceiveMailsFormControl = new FormControl();
        this.checkboxReceivePushFormControl = new FormControl();
        //set up form group
        this.formGroup = new FormGroup({
            usernameFormControl: this.usernameFormControl,
            emailFormControl: this.emailFormControl,
            firstnameFormControl: this.firstnameFormControl,
            lastnameFormControl: this.lastnameFormControl,
            mobilephonenumFormControl: this.mobilephonenumFormControl,
            // imagenameFormControl: this.imagenameFormControl,
            checkboxReceiveMailsFormControl: this.checkboxReceiveMailsFormControl,
            checkboxReceivePushFormControl: this.checkboxReceivePushFormControl
        });
    }
    SettingsComponent.prototype.ngOnInit = function () { };
    SettingsComponent.prototype.ngAfterViewInit = function () {
        this.loadUserData();
    };
    /**
     * Loads the users data and sets it to the fields
     *
     * @param reFetchData
     */
    SettingsComponent.prototype.loadUserData = function (reFetchData) {
        var _this = this;
        if (reFetchData === void 0) { reFetchData = false; }
        this.serverCommunication
            .getUserData(reFetchData)
            .then(function (userData) {
            if (userData != null) {
                _this.setDataToControls(userData);
                _this._userData = userData;
            }
            else {
                _this.commonFunctions.showErrorMessage("Userdaten fehlerhaft");
            }
        })
            .catch(function (reason) {
            _this.commonFunctions.showErrorMessage("Userdaten konnten nicht geladen werden");
        });
    };
    /**
     * Sets the data to the controls
     * @param userData
     */
    SettingsComponent.prototype.setDataToControls = function (userData) {
        this.usernameFormControl.setValue(userData.userName);
        this.firstnameFormControl.setValue(userData.firstName);
        this.lastnameFormControl.setValue(userData.lastName);
        this.emailFormControl.setValue(userData.email);
        this.mobilephonenumFormControl.setValue(userData.mobilePhoneNumber);
        this.checkboxReceiveMailsFormControl.setValue(+userData.receiveMails);
        this.checkboxReceivePushFormControl.setValue(+userData.receivePush);
    };
    // /**
    //  * Processes the uploaded profile picture
    //  *
    //  * @param imageInput
    //  */
    // public processFile(imageInput: any) {
    //   const file: File = imageInput.files[0];
    //   const reader = new FileReader();
    //   reader.addEventListener("load", (event: any) => {
    //     this.selectedImage = event.target.result;
    //     this.imagenameFormControl.setValue(file.name);
    //   });
    //   if (this.acceptImage(file)) {
    //     reader.readAsDataURL(file);
    //   }
    // }
    /**
     * Ensures that only numbers are entered
     *
     * @param event
     */
    SettingsComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        //check if first char is '0' and dont accept it
        if ((this.mobilephonenumFormControl.value == undefined || this.mobilephonenumFormControl.value == "") && charCode == 48) {
            return false;
        }
        return true;
    };
    // /**
    //  * Checks if the image is accepted
    //  */
    // private acceptImage(file: any): boolean {
    //   if (file != undefined) {
    //     var type = file.type;
    //     var size = file.size;
    //     var png = "image/png";
    //     var jpg = "image/jpg";
    //     var jpeg = "image/jpeg";
    //     if ((type == png || type == jpeg || type == jpg) && size < 512000) {
    //       this.profileImageAccepted = true;
    //       return true;
    //     } else {
    //       this.profileImageAccepted = false;
    //     }
    //   }
    //   return false;
    // }
    SettingsComponent.prototype.saveData = function () {
        var _this = this;
        this.spinnerService.show();
        var userName = this.usernameFormControl.value;
        var firstName = this.firstnameFormControl.value;
        var lastName = this.lastnameFormControl.value;
        var email = this.emailFormControl.value;
        var mobilePhone = this.mobilephonenumFormControl.value;
        // let receiveMails: number = this.checkboxReceiveMailsFormControl.value ? 1 : 0;
        // let receivePush: number = this.checkboxReceivePushFormControl.value ? 1 : 0;
        this.serverCommunication
            .setUserData(this._userData.getId(), userName, firstName, lastName, null, mobilePhone, email, null, null, null, null, null)
            .subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.loadUserData(true);
                _this.commonFunctions.showSuccessMessage("Daten gespeichert");
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    return SettingsComponent;
}());
export { SettingsComponent };
