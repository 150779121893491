<mat-table #table [dataSource]="dataSource" matSort style="margin-bottom: 100px;">
  <ng-container matColumnDef="{{ column.id }}" *ngFor="let column of columnNames">
    <mat-header-cell *matHeaderCellDef mat-sort-header style="word-break: break-all"> {{ column.value }} </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element[column.id] }} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" style="text-align: center;"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns; let entry"
    [ngClass]="{ highlight: highlightRow(entry) }"
    style="text-align: center;"
  ></mat-row>
</mat-table>
