<div class="title-text-heading">
  <h1 class="title-text">Freitags-Soccer</h1>
</div>

<div class="flex-container form-content">
  <form
    [formGroup]="formGroup"
    #formDirective="ngForm"
    (ngSubmit)="verify(formDirective)"
  >
    <!-- Username -->
    <mat-form-field class="input-field">
      <input
        matInput
        type="text"
        [formControl]="usernameFormControl"
        placeholder="Username oder E-Mail"
        [(ngModel)]="username"
        minlength="3"
        maxlength="50"
        required
        cdkFocusInitial
      />
      <mat-error *ngIf="usernameFormControl.invalid" style="color: white;"
        >Bitte mehr als 3 Zeichen angeben</mat-error
      >
    </mat-form-field>

    <!-- Password -->
    <mat-form-field class="input-field">
      <input
        matInput
        type="password"
        [formControl]="passwordFormControl"
        placeholder="Passwort"
        [(ngModel)]="password"
        minlength="3"
        maxlength="50"
        style="color: white;"
        required
      />
      <mat-error
        *ngIf="passwordFormControl.invalid"
        style="color: white; margin-bottom: 20px;"
        >Bitte mehr als 3 Zeichen angeben</mat-error
      >
    </mat-form-field>

    <div *ngIf="formGroup.valid">
      <button mat-raised-button color="primary" class="login-btn" type="submit">
        Login
      </button>
      <br />
    </div>

    <a
      (click)="forgotPassword()"
      style="
        color: orange;
        font-size: 1.1em;
        letter-spacing: 1px;
        margin-top: 30px;
      "
      >Passwort vergessen?</a
    >
  </form>
</div>
