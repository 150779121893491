import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../shared/authGuard";
import { HomeComponent } from "../home/home.component";
import { LoginComponent } from "../login/login.component";
import { SettingsComponent } from "../settings/settings.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { RegisterComponent } from "../register/register.component";
import { StatisticsTableComponent } from "../statistics-table/statistics-table.component";
import { StatisticsGraphicsComponent } from "../statistics-graphics/statistics-graphics.component";

// Info: https://jaxenter.de/angular-2-tutorial-component-router-47946
// Dynamic Routing: https://kirjai.com/dynamic-guard-redirects-angular/
const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "statistics-table",
    component: StatisticsTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "statistics-graphics",
    component: StatisticsGraphicsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "register",
    component: RegisterComponent,
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], // Info:{ enableTracing: true } tracing ist nur für debug zwecke
  exports: [RouterModule],
})
export class AppRoutingModule {}
