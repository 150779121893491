import { MatDialogRef } from "@angular/material";
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["Error"] = 0] = "Error";
    NotificationType[NotificationType["Success"] = 1] = "Success";
    NotificationType[NotificationType["Warn"] = 2] = "Warn";
})(NotificationType || (NotificationType = {}));
var NotificationDialog = /** @class */ (function () {
    function NotificationDialog(dialogRef, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.text = "";
        var duration = data.duration;
        this.text = data.text;
        if (duration == undefined || duration == null) {
            duration = 4000; //default
        }
        setTimeout(function () {
            _this.dialogRef.close();
        }, duration);
    }
    return NotificationDialog;
}());
export { NotificationDialog };
