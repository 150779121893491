/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./notification";
import * as i2 from "@angular/material/dialog";
var styles_NotificationDialog = [];
var RenderType_NotificationDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationDialog, data: {} });
export { RenderType_NotificationDialog as RenderType_NotificationDialog };
export function View_NotificationDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["style", "font-size: 1.3em; color: white;"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 3, 0, currVal_0); }); }
export function View_NotificationDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification", [], null, null, null, View_NotificationDialog_0, RenderType_NotificationDialog)), i0.ɵdid(1, 49152, null, 0, i1.NotificationDialog, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var NotificationDialogNgFactory = i0.ɵccf("notification", i1.NotificationDialog, View_NotificationDialog_Host_0, {}, {}, []);
export { NotificationDialogNgFactory as NotificationDialogNgFactory };
