import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, } from "@angular/common/http";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, retry } from "rxjs/operators";
import { RetryMax, SiteUrl, TeamNames, UserData, ResponseTypeError, } from "./definitions.service";
import { CommonFunctions } from "./common-functions.service";
import { reject } from "q";
import { Router } from "@angular/router";
var ServerCommunication = /** @class */ (function () {
    function ServerCommunication(http, commonFunctions, router) {
        this.http = http;
        this.commonFunctions = commonFunctions;
        this.router = router;
        this._userData = null;
    }
    ServerCommunication.prototype.buildHttpOptions = function () {
        var ret = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
        });
        ret.set("authorization", "Bearer " + this.getJwt());
        var httpOptions = {
            headers: ret,
        };
        return httpOptions;
    };
    ServerCommunication.prototype.getJwt = function () {
        return localStorage.getItem("jwt");
    };
    /**
     * Verify Login
     *
     * @param username
     * @param password
     * @param headers
     */
    ServerCommunication.prototype.verifyLoginData = function (username, password) {
        //clear token
        this.commonFunctions.clearToken();
        this._userData = null;
        var body = new HttpParams()
            .set("username", username)
            .set("password", password);
        return this.http
            .post(SiteUrl + "/server/public/verifyLogin.php", body.toString(), this.buildHttpOptions())
            .pipe(retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.prepareResetPassword = function (email) {
        var body = new HttpParams().set("email", email);
        return this.http
            .post(SiteUrl + "/server/public/prepareResetPassword.php", body.toString(), this.buildHttpOptions())
            .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.setNewPassword = function (userId, password) {
        var body = new HttpParams()
            .set("password", password)
            .set("userId", userId.toString());
        return this.http
            .post(SiteUrl + "/server/public/setNewPassword.php", body.toString(), this.buildHttpOptions())
            .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.register = function (username, firstname, lastname, password, email, mobilephonenum) {
        if (mobilephonenum === void 0) { mobilephonenum = null; }
        var body = new HttpParams()
            .set("username", username)
            .set("firstname", firstname)
            .set("lastname", lastname)
            .set("password", password)
            .set("email", email);
        if (mobilephonenum != null) {
            body = body.set("mobilephonenum", mobilephonenum);
        }
        return this.http
            .post(SiteUrl + "/server/public/register.php", body.toString(), this.buildHttpOptions())
            .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.addNewUser = function (email, playerStrength) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("email", email)
            .set("playerStrength", playerStrength.toString());
        return this.http
            .post(SiteUrl + "/server/public/addNewUser.php", body.toString(), this.buildHttpOptions())
            .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getLastFinishedGame = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/getLastFinishedGame.php", body.toString(), this.buildHttpOptions())
            .pipe(retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getCurrentGame = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/getCurrentGame.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getCurrentGamePlayer = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/getCurrentGamePlayer.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.addNewGame = function (dateTime, gamePlace, gameNumber, maxPlayer, gameId, moneyPoolLink) {
        if (gameId === void 0) { gameId = -1; }
        if (moneyPoolLink === void 0) { moneyPoolLink = ""; }
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("dateTime", dateTime.toString())
            .set("gamePlace", gamePlace)
            .set("gameNumber", gameNumber)
            .set("maxPlayer", maxPlayer.toString())
            .set("team1Name", TeamNames.Team1)
            .set("team2Name", TeamNames.Team2)
            .set("moneyPoolLink", moneyPoolLink);
        if (gameId != -1) {
            body = body.set("gameId", gameId.toString());
        }
        return this.http
            .post(SiteUrl + "/server/public/addNewGame.php", body.toString(), this.buildHttpOptions())
            .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.joinGame = function (userId) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("userId", userId.toString())
            .set("team1Name", TeamNames.Team1)
            .set("team2Name", TeamNames.Team2);
        return this.http
            .post(SiteUrl + "/server/public/joinGame.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.leaveGame = function (gamePlayerId) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("gamePlayerId", gamePlayerId.toString())
            .set("team1Name", TeamNames.Team1)
            .set("team2Name", TeamNames.Team2);
        return this.http
            .post(SiteUrl + "/server/public/leaveGame.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.joinGuestInGame = function (firstName, lastName, playerStrength) {
        if (playerStrength === void 0) { playerStrength = 5; }
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("firstName", firstName)
            .set("lastName", lastName)
            .set("playerStrength", playerStrength.toString())
            .set("team1Name", TeamNames.Team1)
            .set("team2Name", TeamNames.Team2);
        return this.http
            .post(SiteUrl + "/server/public/joinGuestInGame.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.setMoneyPoolLink = function (link) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("link", link);
        return this.http
            .post(SiteUrl + "/server/public/setMoneyPoolLink.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.setAsPaid = function (gamePlayerId) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("gamePlayerId", gamePlayerId.toString());
        return this.http
            .post(SiteUrl + "/server/public/setAsPaid.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.cancelCurrentGame = function (gameId) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("gameId", gameId.toString());
        return this.http
            .post(SiteUrl + "/server/public/cancelCurrentGame.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getAllUsers = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/getAllUsers.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getUser = function (id) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("userId", id.toString());
        return this.http
            .post(SiteUrl + "/server/public/getUser.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.setUserData = function (userId, userName, firstName, lastName, playerStrength, mobilePhoneNumber, email, blocked, blockedUntil, userGroup, receiveMails, receivePush) {
        if (userName === void 0) { userName = null; }
        if (firstName === void 0) { firstName = null; }
        if (lastName === void 0) { lastName = null; }
        if (playerStrength === void 0) { playerStrength = null; }
        if (mobilePhoneNumber === void 0) { mobilePhoneNumber = null; }
        if (email === void 0) { email = null; }
        if (blocked === void 0) { blocked = null; }
        if (blockedUntil === void 0) { blockedUntil = null; }
        if (userGroup === void 0) { userGroup = null; }
        if (receiveMails === void 0) { receiveMails = null; }
        if (receivePush === void 0) { receivePush = null; }
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("userId", userId.toString());
        if (userName != null) {
            body = body.set("userName", userName);
        }
        if (firstName != null) {
            body = body.set("firstName", firstName);
        }
        if (lastName != null) {
            body = body.set("lastName", lastName);
        }
        if (playerStrength != null) {
            body = body.set("playerStrength", playerStrength.toString());
        }
        if (mobilePhoneNumber != null) {
            body = body.set("mobilePhoneNumber", mobilePhoneNumber);
        }
        if (email != null) {
            body = body.set("email", email);
        }
        if (blocked != null) {
            body = body.set("blocked", blocked.toString());
        }
        if (blockedUntil != null) {
            body = body.set("blockedUntil", blockedUntil.toString());
        }
        if (userGroup != null) {
            body = body.set("userGroup", userGroup);
        }
        if (receiveMails != null) {
            body = body.set("receiveMails", receiveMails.toString());
        }
        if (receivePush != null) {
            body = body.set("receivePush", receivePush.toString());
        }
        return this.http
            .post(SiteUrl + "/server/public/setUserData.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.closeCurrentGame = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/closeCurrentGame.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.addGameResult = function (winsTeam1, winsTeam2, standoff) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("winsTeam1", winsTeam1.toString())
            .set("winsTeam2", winsTeam2.toString())
            .set("standoff", standoff.toString());
        return this.http
            .post(SiteUrl + "/server/public/addGameResult.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.calculateTeams = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("team1Name", TeamNames.Team1)
            .set("team2Name", TeamNames.Team2);
        return this.http
            .post(SiteUrl + "/server/public/calculateTeams.php", body.toString(), this.buildHttpOptions())
            .pipe(retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.manualSetTeams = function (gamePlayer) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt())
            .set("gamePlayer", JSON.stringify(gamePlayer));
        return this.http
            .post(SiteUrl + "/server/public/manualSetTeams.php", body.toString(), this.buildHttpOptions())
            .pipe(retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getCountGamesPerPlayer = function (userId) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("userId", userId.toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/statistics/getCountGamesPerPlayer.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getCountGameDaysPerPlayer = function (userId) {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("userId", userId.toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/statistics/getCountGameDaysPerPlayer.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getListBestPlayers = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/statistics/getListBestPlayers.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getArrayPlayerToGameDays = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/statistics/getArrayPlayerToGameDays.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    ServerCommunication.prototype.getDataForTable = function () {
        var body = new HttpParams()
            .set("id", this.getUserId().toString())
            .set("token", this.getJwt());
        return this.http
            .post(SiteUrl + "/server/public/statistics/getDataForTable.php", body.toString(), this.buildHttpOptions())
            .pipe(catchError(this.handleError) // then handle the error
        );
    };
    /**
     * Gets the user id from the token
     */
    ServerCommunication.prototype.getUserId = function () {
        return this.commonFunctions.getUserId();
    };
    /**
     * Gets the user data from the jwt
     */
    ServerCommunication.prototype.getUserData = function (reFetch) {
        var _this = this;
        if (reFetch === void 0) { reFetch = false; }
        return new Promise(function (resolve) {
            if (_this._userData == null || reFetch) {
                var userId = _this.commonFunctions.getUserId();
                if (userId != -1) {
                    _this.getUser(userId).subscribe(function (data) {
                        if (data == null) {
                            console.log("Konnte Userdaten nicht laden");
                            reject("Konnte Userdaten nicht laden");
                            return;
                        }
                        // check if error
                        if (data.result === ResponseTypeError) {
                            reject(data.message);
                        }
                        else {
                            _this._userData = UserData.convert(data.message);
                            resolve(_this._userData);
                        }
                    }, function (error) {
                        reject(error);
                    });
                }
            }
            else {
                resolve(_this._userData);
            }
        });
    };
    /**
     * Handles an error of a server request
     *
     * @param error
     */
    ServerCommunication.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        }
        else {
            if (error.status == 0) {
                return new ErrorObservable("Server call fehlgeschlagen. Keine Verbindung ?");
            }
            else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error("Backend returned code " + error.status + ", " +
                    ("body was: " + error.message));
                console.error(error.error.text);
                //console.error(`Stack trace: ${error.error.stack}`);
                if (error.message != undefined)
                    return new ErrorObservable(error.message);
            }
        }
        // return an ErrorObservable with a user-facing error message
        return new ErrorObservable("Error occured; please try again later.");
    };
    return ServerCommunication;
}());
export { ServerCommunication };
