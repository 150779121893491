import { CdkTableModule } from "@angular/cdk/table";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import {
  MatDialogModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatSliderModule,
  MatTabsModule,
  MatIconModule,
  MatTableModule,
  MatSortModule,
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EcoFabSpeedDialModule } from "@ecodev/fab-speed-dial";
import { ToasterModule } from "angular2-toaster";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import "../polyfills";
import { AppComponent } from "./app.component";
import { MaterialModule } from "./modules/MaterialModule";
import { StringFilterPipe } from "./pipe/string-filter";
import { SwitchStatusFilter } from "./pipe/switch-status-filter";
import { AppRoutingModule } from "./routing/app-routing.module";
import { AuthService } from "./shared/auth.service";
import { AuthGuard } from "./shared/authGuard";
import { CommonFunctions } from "./shared/common-functions.service";
import { DataService } from "./shared/data.service";
import { DefinitionsService } from "./shared/definitions.service";
import { ServerCommunication } from "./shared/server-communication.service";
import { HomeComponent } from "./home/home.component";
import { UniversalDialog } from "./dialogs/universal-dialog/universal-dialog";
import { LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import lcoaleDeAt from "@angular/common/locales/de";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { PathFilterPipe } from "./pipe/path-filter";
import { JwtModule } from "@auth0/angular-jwt";
import { LoginComponent } from "./login/login.component";
import { ForgotPassworDialog } from "./dialogs/forgot-password/forgot-password";
import { SettingsComponent } from "./settings/settings.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RegisterComponent } from "./register/register.component";
import { AddUserDialog } from "./dialogs/add-user/add-user";
import { AddNewGameDialog } from "./dialogs/add-new-game/add-new-game";
import { JoinGuestDialog } from "./dialogs/join-guest/join-guest";
import { PayDialog } from "./dialogs/pay-dialog/pay-dialog";
import { SetUserDataDialog } from "./dialogs/set-user-data/set-user-data";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { BlockUserDialog } from "./dialogs/block-user/block-user";
import { JoinUserToGameDialog } from "./dialogs/join-user-to-game/join-user-to-game";
import { AddGameResultDialog } from "./dialogs/add-game-result/add-game-result";
import { ShowTeamsDialog } from "./dialogs/show-teams/show-teams";
import { DeviceDetectorModule } from "ngx-device-detector";
import { ChartsModule } from "ng4-charts/ng4-charts";

/** IMPORTANT : IE10 and IE11 requires the following to support `@angular/animation` (which is used by this module).
Run `npm install --save web-animations-js`.
*/
import "web-animations-js";
import { ShareWaDialog } from "./dialogs/share-wa/share-wa";
import { StatisticsGraphicsComponent } from "./statistics-graphics/statistics-graphics.component";
import { StatisticsTableComponent } from "./statistics-table/statistics-table.component";
import { NotificationDialog } from "./dialogs/notification/notification";
import { ErrorInterceptor } from "./shared/http-interceptor";
import { SetMoneyPoolLinkDialog } from "./dialogs/set-moneypool-link/set-moneypool-link";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}
registerLocaleData(lcoaleDeAt);

@NgModule({
  declarations: [
    AppComponent,
    SwitchStatusFilter,
    StringFilterPipe,
    PathFilterPipe,
    HomeComponent,
    UniversalDialog,
    AddNewGameDialog,
    AddUserDialog,
    SetUserDataDialog,
    BlockUserDialog,
    ShowTeamsDialog,
    PayDialog,
    NotificationDialog,
    JoinGuestDialog,
    JoinUserToGameDialog,
    AddGameResultDialog,
    ShareWaDialog,
    LoginComponent,
    ForgotPassworDialog,
    SettingsComponent,
    StatisticsTableComponent,
    ResetPasswordComponent,
    RegisterComponent,
    StatisticsGraphicsComponent,
    SetMoneyPoolLinkDialog,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MaterialModule,
    FormsModule,
    MatTableModule,
    HttpModule,
    MatSortModule,
    CdkTableModule,
    HttpClientModule,
    MatInputModule,
    MatTabsModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatOptionModule,
    EcoFabSpeedDialModule,
    MatSliderModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    ChartsModule,
    ToasterModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["example.com"],
        blacklistedRoutes: ["example.com/examplebadroute/"],
      },
    }),
    // tslint:disable-next-line:max-line-length
    AppRoutingModule, // Attention: Root Routing module muss immer am ende kommen (siehe: https://angular.io/guide/router#milestone-3-heroes-feature -> Module import order matters)
  ],
  entryComponents: [
    UniversalDialog,
    AddNewGameDialog,
    ForgotPassworDialog,
    AddUserDialog,
    JoinGuestDialog,
    PayDialog,
    ShowTeamsDialog,
    SetUserDataDialog,
    BlockUserDialog,
    JoinUserToGameDialog,
    AddGameResultDialog,
    ShareWaDialog,
    NotificationDialog,
    SetMoneyPoolLinkDialog,
  ],
  providers: [
    AuthGuard,
    AuthService,
    DefinitionsService,
    ServerCommunication,
    CommonFunctions,
    DataService,
    { provide: LOCALE_ID, useValue: "de" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
