<style>
  .owl-dt-inline-container .owl-dt-calendar,
  .owl-dt-popup-container .owl-dt-calendar {
    height: 10.25em;
  }

  .cdk-overlay-pane owl-dt-popup {
    left: 0;
  }

  .owl-dt-container-control-button .owl-dt-control-button-content {
    height: 20px;
    background-color: darkblue;
    color: white;
    font-size: 1.5em;
  }

  .owl-dt-calendar-table .owl-dt-calendar-cell-content {
    font-size: 1.5em;
  }

  .owl-dt-control-button {
    font-size: 1.5em;
  }
</style>

<h1 mat-dialog-title>Neues Spiel anlegen</h1>
<hr />
<form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="addNewGame()">
  <div mat-dialog-content>
    <!--Spielnummer-->
    <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
      <input
        matInput
        type="number"
        placeholder="Spielnummer"
        [formControl]="gameNumberFormControl"
        [(ngModel)]="gameNumber"
        required
        cdkFocusInitial
      />
    </mat-form-field>

    <!--Datum-->
    <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
      <input
        matInput
        [min]="minDateNow"
        type="datetime"
        placeholder="Start-time"
        [formControl]="startDateFormControl"
        [(ngModel)]="startDateTime"
        [owlDateTimeTrigger]="dtStart"
        [owlDateTime]="dtStart"
        (click)="onDateTimePickerOpen()"
        required
      />
      <owl-date-time #dtStart></owl-date-time>
    </mat-form-field>

    <!--Spielfeld-->
    <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
      <input
        matInput
        type="text"
        placeholder="Spielfeld"
        [formControl]="gamePlaceFormControl"
        [(ngModel)]="gamePlace"
        minlength="3"
        maxlength="20"
        required
      />
      <!-- <button mat-button *ngIf="gamePlace" matSuffix mat-icon-button aria-label="Clear" (click)="gamePlace = ''"><mat-icon>close</mat-icon></button> -->
    </mat-form-field>

    <!--Maximale Spielerzahl-->
    <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
      <input
        matInput
        type="number"
        placeholder="Spieleranzahl"
        [formControl]="maxPlayerFormControl"
        [(ngModel)]="maxPlayer"
      />
    </mat-form-field>

    <!--MoneyPool-->
    <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
      <input
        matInput
        type="text"
        placeholder="MoneyPool Link"
        [formControl]="moneyPoolLinkFormControl"
        [(ngModel)]="moneyPoolLink"
        minlength="3"
        maxlength="150"
      />
    </mat-form-field>
  </div>
  <hr />

  <div mat-dialog-actions>
    <button
      mat-raised-button
      type="reset"
      style="width: 47%;"
      (click)="onNoClick()"
    >
      Abbrechen
    </button>
    <button
      mat-raised-button
      color="primary"
      style="width: 47%;"
      type="submit"
      [disabled]="!formGroup.valid"
    >
      Spiel erstellen
    </button>
  </div>
</form>
