import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        //check if logged in
        if (this.authService.isAuthenticated()) {
            return true;
        }
        // not logged in so redirect to login page with the return url and return false
        this.router.navigate(["login"], { queryParams: { returnUrl: state.url } });
        return false;
    };
    return AuthGuard;
}());
export { AuthGuard };
