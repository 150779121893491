import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError } from "../shared/definitions.service";
import { setTimeout } from "core-js";
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(route, router, serverCommunication, commonFunctions, spinnerService) {
        this.route = route;
        this.router = router;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.hideNotification = false;
        this.password1FormControl = new FormControl();
        this.password2FormControl = new FormControl();
        this._userId = -1;
        //set up form group
        this.formGroup = new FormGroup({
            password1FormControl: this.password1FormControl,
            password2FormControl: this.password2FormControl
        });
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            // Defaults to 0 if no query param provided.
            var userId = +params["id"] || -1;
            if (userId !== -1) {
                _this._userId = userId;
                _this.hideNotification = true;
            }
        });
    };
    ResetPasswordComponent.prototype.setNewPassword = function (formDirective) {
        var _this = this;
        this.formGroupDirective = formDirective; //save to local var
        var selectedPassword = this.password2FormControl.value;
        this.serverCommunication.setNewPassword(this._userId, selectedPassword).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.commonFunctions.showSuccessMessage("Passwort erfolgreich neu gesetzt");
                _this.resetFormGroup();
                //weiterleiten
                setTimeout(function () {
                    _this.router.navigate(["login"]);
                }, 3000);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Resets the whole form data
     */
    ResetPasswordComponent.prototype.resetFormGroup = function () {
        this.formGroupDirective.resetForm();
        this.formGroup.reset();
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
