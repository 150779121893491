import { OnInit, AfterViewInit, } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AuthService } from "./shared/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { CommonFunctions } from "./shared/common-functions.service";
import { MatDialog, MatIconRegistry } from "@angular/material";
import { AddUserDialog } from "./dialogs/add-user/add-user";
import { HomeComponent } from "./home/home.component";
import { ToasterConfig } from "angular2-toaster";
import { DomSanitizer } from "@angular/platform-browser";
import { ServerCommunication } from "./shared/server-communication.service";
import { LoginComponent } from "./login/login.component";
import { SetMoneyPoolLinkDialog } from "./dialogs/set-moneypool-link/set-moneypool-link";
var AppComponent = /** @class */ (function () {
    function AppComponent(authService, router, commonFunctions, _dialog, matIconRegistry, domSanitizer, serverCommunication) {
        this.authService = authService;
        this.router = router;
        this.commonFunctions = commonFunctions;
        this._dialog = _dialog;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.serverCommunication = serverCommunication;
        this.sidenavWidth = 4;
        this.addScript = false;
        this.paypalLoad = true;
        this.currentOpenComponent = null;
        this.userName = "";
        this._sizeHeader = 55;
        this._sizeFooter = 105;
        this.config = new ToasterConfig({
            showCloseButton: false,
            tapToDismiss: true,
            timeout: 7000,
        });
        //register svg icons
        this.matIconRegistry.addSvgIcon("paypal_icon", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/paypal-icon.svg"));
    }
    AppComponent.prototype.ngOnInit = function () { };
    AppComponent.prototype.ngAfterViewInit = function () {
        this.screenSizeDependingActions();
    };
    //Is thrown, when a new component is activated in the router outlet
    AppComponent.prototype.onActivate = function (componentRef) {
        this.currentOpenComponent = componentRef;
    };
    AppComponent.prototype.isHomeComponent = function () {
        if (this.currentOpenComponent instanceof HomeComponent) {
            return true;
        }
        return false;
    };
    AppComponent.prototype.isLoggedIn = function () {
        return this.authService.isAuthenticated();
    };
    AppComponent.prototype.isAdmin = function () {
        return this.commonFunctions.isAdmin();
    };
    AppComponent.prototype.logout = function () {
        //clear local data
        this.userName = null;
        console.log("Logging out...");
        this.authService.logout();
        this.router.navigate(["login"]);
    };
    AppComponent.prototype.getUsername = function () {
        var _this = this;
        if ((this.isLoggedIn() && this.userName == null) || this.userName == "") {
            //info: do not remove check for local field !!
            this.serverCommunication.getUserData().then(function (userData) {
                _this.userName = userData.userName;
            });
        }
        return this.userName;
    };
    /**
     * Opens the dialog to add a new user
     */
    AppComponent.prototype.openAddPlayerDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(AddUserDialog, {
            width: "450px",
            data: { result: 0 },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.commonFunctions.showSuccessMessage("User wurde angelegt");
            }
        });
    };
    /**
     * Opens the dialog to add a new user
     */
    AppComponent.prototype.openSetMoneyPoolLinkDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(SetMoneyPoolLinkDialog, {
            width: "450px",
            data: { result: 0 },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != undefined && result.result === 1) {
                _this.commonFunctions.showSuccessMessage("Link gesetzt");
                var comp = _this.currentOpenComponent;
                comp.loadData(); //update game data
            }
        });
    };
    AppComponent.prototype.onResize = function (event) {
        if (event.target.innerWidth < 470) {
            if (this.sidenav != undefined) {
                this.sidenav.close();
            }
        }
        else {
            if (this.sidenav != undefined) {
                this.sidenav.open();
            }
        }
    };
    AppComponent.prototype.close = function () {
        this.sidenav.close();
    };
    AppComponent.prototype.increase = function () {
        this.sidenavWidth = 15;
    };
    AppComponent.prototype.decrease = function () {
        this.sidenavWidth = 4;
    };
    AppComponent.prototype.joinGuest = function () {
        var comp = this.currentOpenComponent;
        comp.joinGuest();
    };
    AppComponent.prototype.joinUser = function () {
        var comp = this.currentOpenComponent;
        comp.openDialogJoinUser();
    };
    AppComponent.prototype.cancelGame = function () {
        var comp = this.currentOpenComponent;
        comp.cancelGame();
    };
    AppComponent.prototype.changeUserData = function () {
        var comp = this.currentOpenComponent;
        comp.openDialogChangeUserData();
    };
    AppComponent.prototype.blockUser = function () {
        var comp = this.currentOpenComponent;
        comp.openDialogBlockUser();
    };
    AppComponent.prototype.openCreateNewGameDialog = function () {
        var comp = this.currentOpenComponent;
        comp.openCreateNewGameDialog();
    };
    AppComponent.prototype.showPayButton = function () {
        var comp = this.currentOpenComponent;
        return comp.showPayButton();
    };
    AppComponent.prototype.isInList = function () {
        var comp = this.currentOpenComponent;
        return comp.myGamePlayer != null;
    };
    AppComponent.prototype.pay = function () {
        var comp = this.currentOpenComponent;
        comp.pay();
    };
    AppComponent.prototype.leaveGameThisUser = function () {
        var comp = this.currentOpenComponent;
        comp.leaveGameThisUser();
    };
    AppComponent.prototype.joinGameThisUser = function () {
        var comp = this.currentOpenComponent;
        comp.joinGameThisUser();
    };
    AppComponent.prototype.updateList = function () {
        var comp = this.currentOpenComponent;
        comp.updateGamePlayerList();
    };
    /**
     * Checks if a game is present
     */
    AppComponent.prototype.gameIsPresent = function () {
        var comp = this.currentOpenComponent;
        if (comp != undefined && comp instanceof HomeComponent) {
            return comp.isGamePresent();
        }
        return false;
    };
    /**
     * Checks if a game is outdated
     */
    AppComponent.prototype.gameIsOutdated = function () {
        var comp = this.currentOpenComponent;
        if (comp != undefined && comp instanceof HomeComponent) {
            return comp.isGameOutdated();
        }
        return false;
    };
    /**
     * Gets the style class for the mat-sidenav-content depending on the currently loaded component
     */
    AppComponent.prototype.getStyleClassForComponent = function () {
        var comp = this.currentOpenComponent;
        var ret = "";
        if (comp != undefined && comp != null) {
            if (comp instanceof LoginComponent) {
                ret += " bg-image-login";
            }
        }
        return ret;
    };
    /**
     * Executes some operations depending on the screen size
     */
    AppComponent.prototype.screenSizeDependingActions = function () {
        var _this = this;
        this.initializing = true;
        var width = window.screen.width;
        var height = window.screen.height;
        if (width < 470) {
            this.isSmallScreenVertical = true;
        }
        if (height < 400) {
            this.isSmallScreenHorizontal = true;
        }
        if (this.isSmallScreenHorizontal || this.isSmallScreenVertical) {
            setTimeout(function () {
                if (_this.sidenav != undefined) {
                    _this.sidenav.close();
                }
            }, 500);
        }
        // close sidenav on routing
        this.router.events.subscribe(function (event) {
            if (!_this.initializing &&
                (_this.isSmallScreenHorizontal || _this.isSmallScreenVertical)) {
                setTimeout(function () {
                    if (_this.sidenav != undefined) {
                        _this.sidenav.close();
                    }
                }, 100);
            }
            if (event instanceof NavigationEnd) {
                _this.initializing = false;
            }
        });
    };
    /**
     * Calculates the available size of the content depending on which component is loaded and which screen size is present
     */
    AppComponent.prototype.getHeightContent = function () {
        var contentHeight = document.getElementById("content").offsetHeight;
        if (!this.isHomeComponent()) {
            return contentHeight - this._sizeHeader;
        }
        var res = contentHeight - this._sizeHeader - this._sizeFooter + 25;
        return res;
    };
    AppComponent.prototype.getHeightContentAsPx = function () {
        return this.getHeightContent() + "px";
    };
    AppComponent.prototype.getHeightBottomBarAsPx = function () {
        return this._sizeFooter + "px";
    };
    return AppComponent;
}());
export { AppComponent };
