import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { FormGroup, FormControl } from "@angular/forms";
import { ResponseTypeError } from "../shared/definitions.service";
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(route, router, serverCommunication, commonFunctions, spinnerService) {
        this.route = route;
        this.router = router;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this._emailAddress = "";
        this.hideNotification = false;
        this.profileImageAccepted = true;
        this.selectedImage = null;
        this.usernameFormControl = new FormControl();
        this.firstnameFormControl = new FormControl();
        this.lastnameFormControl = new FormControl();
        this.mobilephonenumFormControl = new FormControl();
        // imagenameFormControl = new FormControl({ value: this.imageInputText, disabled: true });
        this.password1FormControl = new FormControl();
        this.password2FormControl = new FormControl();
    }
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            // Defaults to 0 if no query param provided.
            var emailAddress = params["email"] || null;
            if (emailAddress !== null && emailAddress != "") {
                _this._emailAddress = emailAddress;
                _this.hideNotification = true;
            }
        });
        //set up form group
        this.formGroup = new FormGroup({
            usernameFormControl: this.usernameFormControl,
            firstnameFormControl: this.firstnameFormControl,
            lastnameFormControl: this.lastnameFormControl,
            mobilephonenumFormControl: this.mobilephonenumFormControl,
            // imagenameFormControl: this.imagenameFormControl,
            password1FormControl: this.password1FormControl,
            password2FormControl: this.password2FormControl
        });
    };
    /**
     * Executes a registration
     *
     * @param formDirective
     */
    RegisterComponent.prototype.register = function (formDirective) {
        var _this = this;
        this.formGroupDirective = formDirective; //save to local var
        var username = this.usernameFormControl.value;
        var firstname = this.firstnameFormControl.value;
        var lastname = this.lastnameFormControl.value;
        var mobilePhoneNum = this.mobilephonenumFormControl.value == undefined || this.mobilephonenumFormControl.value == "" ? null : this.mobilephonenumFormControl.value;
        var selectedPassword = this.password2FormControl.value;
        this.serverCommunication.register(username, firstname, lastname, selectedPassword, this._emailAddress, mobilePhoneNum).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.commonFunctions.showSuccessMessage("Erfolgreich registriert");
                _this.resetFormGroup();
                //weiterleiten
                setTimeout(function () {
                    _this.router.navigate(["login"]);
                }, 3000);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    // /**
    //  * Processes the uploaded profile picture
    //  *
    //  * @param imageInput
    //  */
    // public processFile(imageInput: any) {
    //   const file: File = imageInput.files[0];
    //   const reader = new FileReader();
    //   reader.addEventListener("load", (event: any) => {
    //     this.selectedImage = event.target.result;
    //     this.imagenameFormControl.setValue(file.name);
    //   });
    //   if (this.acceptImage(file)) {
    //     reader.readAsDataURL(file);
    //   }
    // }
    /**
     * Ensures that only numbers are entered
     *
     * @param event
     */
    RegisterComponent.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        //check if first char is '0' and dont accept it
        if ((this.mobilephonenumFormControl.value == undefined || this.mobilephonenumFormControl.value == "") && charCode == 48) {
            return false;
        }
        return true;
    };
    // /**
    //  * Checks if the image is accepted
    //  */
    // private acceptImage(file: any): boolean {
    //   if (file != undefined) {
    //     var type = file.type;
    //     var size = file.size;
    //     var png = "image/png";
    //     var jpg = "image/jpg";
    //     var jpeg = "image/jpeg";
    //     if ((type == png || type == jpeg || type == jpg) && size < 512000) {
    //       this.profileImageAccepted = true;
    //       return true;
    //     } else {
    //       this.profileImageAccepted = false;
    //     }
    //   }
    //   return false;
    // }
    /**
     * Resets the whole form data
     */
    RegisterComponent.prototype.resetFormGroup = function () {
        this.formGroupDirective.resetForm();
        this.formGroup.reset();
    };
    return RegisterComponent;
}());
export { RegisterComponent };
