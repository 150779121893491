/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pay-dialog.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/common";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./pay-dialog";
import * as i9 from "../../shared/server-communication.service";
import * as i10 from "ng4-loading-spinner";
import * as i11 from "../../shared/common-functions.service";
var styles_PayDialog = [i0.styles];
var RenderType_PayDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_PayDialog, data: {} });
export { RenderType_PayDialog as RenderType_PayDialog };
function View_PayDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["color", "primary"], ["isRound", "true"], ["mat-raised-button", ""], ["style", "\n        width: 100%;\n        margin-right: 10px;\n        margin-bottom: 40px;\n        margin-top: 20px;\n      "]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMoneyPoolClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      MoneyPool\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_1 = "primary"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); _ck(_v, 2, 0, currVal_0); }); }
function View_PayDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [["style", "margin-bottom: 60px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oder"])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, null); }
function View_PayDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Paypal button is loading"]))], null, null); }
function View_PayDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayDialog_4)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["id", "paypal-checkout-btn"], ["style", "margin-left: 10px; margin-right: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paypalLoad; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PayDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Bezahlen"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 17, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayDialog_1)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayDialog_2)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayDialog_3)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["color", "warn"], ["mat-raised-button", ""], ["style", "width: 90%; margin-left: 5%; margin-top: 10px;"], ["tabindex", "-1"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    Abbrechen\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = ((_co.moneyPoolLink !== null) && (_co.moneyPoolLink !== "")); _ck(_v, 10, 0, currVal_1); var currVal_2 = ((((_co.moneyPoolLink !== null) && (_co.moneyPoolLink !== "")) && (_co.payPalKey !== null)) && (_co.payPalKey !== "")); _ck(_v, 13, 0, currVal_2); var currVal_3 = ((_co.payPalKey !== null) && (_co.payPalKey !== "")); _ck(_v, 16, 0, currVal_3); var currVal_5 = "warn"; _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_4 = (i1.ɵnov(_v, 21).disabled || null); _ck(_v, 20, 0, currVal_4); }); }
export function View_PayDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pay-dialog", [], null, null, null, View_PayDialog_0, RenderType_PayDialog)), i1.ɵdid(1, 114688, null, 0, i8.PayDialog, [i7.MatDialogRef, i7.MAT_DIALOG_DATA, i9.ServerCommunication, i10.Ng4LoadingSpinnerService, i11.CommonFunctions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PayDialogNgFactory = i1.ɵccf("pay-dialog", i8.PayDialog, View_PayDialog_Host_0, {}, {}, []);
export { PayDialogNgFactory as PayDialogNgFactory };
