import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { FormGroup, FormGroupDirective, FormControl } from "@angular/forms";
import { ResponseTypeError } from "../shared/definitions.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit {
  private _emailAddress: string = "";
  public hideNotification: boolean = false;
  public profileImageAccepted: boolean = true;
  public selectedImage: string = null;
  // public imageInputText: string = "Profilbild wählen";

  public userName: string;
  public firstName: string;
  public lastName: string;
  public mobilePhoneNumber: string;
  public imageName: string;
  public password1: string;
  public password2: string;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;
  usernameFormControl = new FormControl();
  firstnameFormControl = new FormControl();
  lastnameFormControl = new FormControl();
  mobilephonenumFormControl = new FormControl();
  // imagenameFormControl = new FormControl({ value: this.imageInputText, disabled: true });
  password1FormControl = new FormControl();
  password2FormControl = new FormControl();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      var emailAddress = params["email"] || null;

      if (emailAddress !== null && emailAddress != "") {
        this._emailAddress = emailAddress;
        this.hideNotification = true;
      }
    });

    //set up form group
    this.formGroup = new FormGroup({
      usernameFormControl: this.usernameFormControl,
      firstnameFormControl: this.firstnameFormControl,
      lastnameFormControl: this.lastnameFormControl,
      mobilephonenumFormControl: this.mobilephonenumFormControl,
      // imagenameFormControl: this.imagenameFormControl,
      password1FormControl: this.password1FormControl,
      password2FormControl: this.password2FormControl
    });
  }

  /**
   * Executes a registration
   *
   * @param formDirective
   */
  public register(formDirective: FormGroupDirective) {
    this.formGroupDirective = formDirective; //save to local var
    var username = this.usernameFormControl.value;
    var firstname = this.firstnameFormControl.value;
    var lastname = this.lastnameFormControl.value;
    var mobilePhoneNum =
      this.mobilephonenumFormControl.value == undefined || this.mobilephonenumFormControl.value == "" ? null : this.mobilephonenumFormControl.value;
    var selectedPassword = this.password2FormControl.value;

    this.serverCommunication.register(username, firstname, lastname, selectedPassword, this._emailAddress, mobilePhoneNum).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.commonFunctions.showSuccessMessage("Erfolgreich registriert");
          this.resetFormGroup();

          //weiterleiten
          setTimeout(() => {
            this.router.navigate(["login"]);
          }, 3000);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  // /**
  //  * Processes the uploaded profile picture
  //  *
  //  * @param imageInput
  //  */
  // public processFile(imageInput: any) {
  //   const file: File = imageInput.files[0];
  //   const reader = new FileReader();

  //   reader.addEventListener("load", (event: any) => {
  //     this.selectedImage = event.target.result;
  //     this.imagenameFormControl.setValue(file.name);
  //   });

  //   if (this.acceptImage(file)) {
  //     reader.readAsDataURL(file);
  //   }
  // }

  /**
   * Ensures that only numbers are entered
   *
   * @param event
   */
  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //check if first char is '0' and dont accept it
    if ((this.mobilephonenumFormControl.value == undefined || this.mobilephonenumFormControl.value == "") && charCode == 48) {
      return false;
    }
    return true;
  }

  // /**
  //  * Checks if the image is accepted
  //  */
  // private acceptImage(file: any): boolean {
  //   if (file != undefined) {
  //     var type = file.type;
  //     var size = file.size;
  //     var png = "image/png";
  //     var jpg = "image/jpg";
  //     var jpeg = "image/jpeg";

  //     if ((type == png || type == jpeg || type == jpg) && size < 512000) {
  //       this.profileImageAccepted = true;
  //       return true;
  //     } else {
  //       this.profileImageAccepted = false;
  //     }
  //   }
  //   return false;
  // }

  /**
   * Resets the whole form data
   */
  private resetFormGroup() {
    this.formGroupDirective.resetForm();
    this.formGroup.reset();
  }
}
