import { MatDialogRef } from "@angular/material";
var ForgotPassworDialog = /** @class */ (function () {
    function ForgotPassworDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.email = "";
        console.log("Start 'forgot password' dialog");
    }
    ForgotPassworDialog.prototype.onNoClick = function () {
        this.data.result = 0;
        this.dialogRef.close(this.data);
    };
    ForgotPassworDialog.prototype.onYesClick = function () {
        this.data.result = 1;
        this.data.email = this.email;
        this.dialogRef.close(this.data);
    };
    return ForgotPassworDialog;
}());
export { ForgotPassworDialog };
