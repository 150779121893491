/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "./universal-dialog";
var styles_UniversalDialog = [];
var RenderType_UniversalDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_UniversalDialog, data: {} });
export { RenderType_UniversalDialog as RenderType_UniversalDialog };
export function View_UniversalDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(15, 0, null, null, 10, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(16, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(18, 0, null, null, 2, "button", [["mat-raised-button", ""], ["style", "width: 47%"], ["type", "reset"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor], null, null), (_l()(), i0.ɵted(20, 0, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(22, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-raised-button", ""], ["style", "width: 47%;"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(23, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor], { color: [0, "color"] }, null), (_l()(), i0.ɵted(24, 0, ["\n    ", "\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_6 = "primary"; _ck(_v, 23, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.operationName; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.contentText; _ck(_v, 10, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 19).disabled || null); _ck(_v, 18, 0, currVal_3); var currVal_4 = _co.data.buttonNoName; _ck(_v, 20, 0, currVal_4); var currVal_5 = (i0.ɵnov(_v, 23).disabled || null); _ck(_v, 22, 0, currVal_5); var currVal_7 = _co.data.buttonYesName; _ck(_v, 24, 0, currVal_7); }); }
export function View_UniversalDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "universal-dialog", [], null, null, null, View_UniversalDialog_0, RenderType_UniversalDialog)), i0.ɵdid(1, 49152, null, 0, i6.UniversalDialog, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var UniversalDialogNgFactory = i0.ɵccf("universal-dialog", i6.UniversalDialog, View_UniversalDialog_Host_0, {}, {}, []);
export { UniversalDialogNgFactory as UniversalDialogNgFactory };
