import { MatDialog } from "@angular/material";
import { ToasterService } from "angular2-toaster";
import { UniversalDialog } from "../dialogs/universal-dialog/universal-dialog";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NotificationDialog } from "../dialogs/notification/notification";
var CommonFunctions = /** @class */ (function () {
    function CommonFunctions(toasterService, dialog) {
        this.dialog = dialog;
        this.toasterService = toasterService;
        this.jwtHelper = new JwtHelperService();
    }
    CommonFunctions.prototype.showErrorMessage = function (message) {
        var data = { duration: 4000, text: message };
        this.dialog.open(NotificationDialog, {
            width: "100%",
            maxWidth: "100%",
            panelClass: "my-panel-error",
            data: data,
            position: { bottom: "0px" }
        });
        // this._notify.show(message, { position: "bottom", duration: "4000", type: "error" });
    };
    CommonFunctions.prototype.showSuccessMessage = function (message) {
        var data = { duration: 2500, text: message };
        this.dialog.open(NotificationDialog, {
            width: "100%",
            maxWidth: "100%",
            panelClass: "my-panel-success",
            data: data,
            position: { bottom: "0px" }
        });
        // this._notify.show(message, { position: "bottom", duration: "3000", type: "success" });
    };
    CommonFunctions.prototype.showWarnMessage = function (message) {
        var data = { duration: 4000, text: message };
        this.dialog.open(NotificationDialog, {
            width: "100%",
            maxWidth: "100%",
            panelClass: "my-panel-warn",
            data: data,
            position: { bottom: "0px" }
        });
        // this._notify.show(message, { position: "bottom", duration: "3000", type: "warn" });
    };
    CommonFunctions.prototype.showErrorToast = function (message, title) {
        if (title === void 0) { title = "Error"; }
        this.toasterService.popAsync("error", title, message);
    };
    CommonFunctions.prototype.showSuccessToast = function (message, title) {
        if (title === void 0) { title = "Success"; }
        this.toasterService.popAsync("success", title, message);
    };
    CommonFunctions.prototype.showWarnToast = function (message, title) {
        if (title === void 0) { title = "Warning"; }
        this.toasterService.popAsync("warn", title, message);
    };
    CommonFunctions.prototype.clearToken = function () {
        this._decodedToken = null;
    };
    /**
     * Gets the decoded token data
     */
    CommonFunctions.prototype.getDecodedJWT = function () {
        //load token
        if (this._decodedToken == null || this._decodedToken == undefined) {
            var token = localStorage.getItem("jwt");
            if (token == null || token == undefined || token == "" || this.jwtHelper.isTokenExpired(token)) {
                //console.log("Token null or expired");
                return -1;
            }
            this._decodedToken = this.jwtHelper.decodeToken(token);
        }
        if (this._decodedToken == null) {
            console.log("JWT could not be decoded");
            return null;
        }
        var data = this._decodedToken.data;
        if (data != null) {
            return data;
        }
        else {
            console.log("Could not extract data from token");
        }
        return null;
    };
    /**
     * Checks if the user is admin
     */
    CommonFunctions.prototype.isAdmin = function () {
        var data = this.getDecodedJWT();
        if (data != null) {
            return data.userGroup == "admin";
        }
        return false;
    };
    /**
     * Gets the user id
     */
    CommonFunctions.prototype.getUserId = function () {
        var token = this.getDecodedJWT();
        if (token != null) {
            if (token.id == undefined || token.id == null) {
                return -1;
            }
            return token.id;
        }
        return -1;
    };
    CommonFunctions.prototype.openDialog = function (operationName, contentText, buttonNoName, buttonYesName) {
        if (buttonNoName === void 0) { buttonNoName = "No"; }
        if (buttonYesName === void 0) { buttonYesName = "Yes"; }
        var dialogRef = this.dialog.open(UniversalDialog, {
            width: "250px",
            data: { operationName: operationName, contentText: contentText, buttonNoName: buttonNoName, buttonYesName: buttonYesName, result: 0 }
        });
        return dialogRef;
    };
    return CommonFunctions;
}());
export { CommonFunctions };
var KeyValuePair = /** @class */ (function () {
    function KeyValuePair(key, value) {
        this.key = key;
        this.value = value;
    }
    return KeyValuePair;
}());
export { KeyValuePair };
