<h1 mat-dialog-title>{{ title }}</h1>
<hr />

<div mat-dialog-content>
  <table style="width: -webkit-fill-available;background-color: white;">
    <thead>
      <tr style="text-align: center;border: 1px solid #dddddd">
        <th>{{ team1.name }}</th>
        <th>{{ team2.name }}</th>
      </tr>
    </thead>

    <tr>
      <td class="my-td">
        <mat-form-field class="number-input">
          <input matInput type="number" min="0" [(ngModel)]="team1.wins" />
        </mat-form-field>
      </td>

      <td class="my-td">
        <mat-form-field class="number-input">
          <input matInput type="number" min="0" [(ngModel)]="team2.wins" />
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td class="my-td" colspan="2">
        <div>
          <p><strong>Unentschieden</strong></p>
        </div>
        <mat-form-field class="number-input">
          <input matInput type="number" min="0" [(ngModel)]="standoff" />
        </mat-form-field>
      </td>
    </tr>
  </table>
</div>
<hr />

<div mat-dialog-actions>
  <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">Abbrechen</button>
  <button mat-raised-button color="primary" style="width: 47%;" (click)="setData()">
    Eintragen
  </button>
</div>
