import { Injectable } from "@angular/core";

export const SiteUrl = "https://www.freitags-soccer.de";
export const RetryMax = 1;
export const ResponseTypeOk = "Ok";
export const ResponseTypeError = "Error";
export const ResponseTypeWarn = "Warn";
export const PayPalProductionKey = "";

export enum ErrorCodes {
  UserBlocked = 507,
  NoOpeGame = 603,
}

export enum TeamNames {
  Team1 = "Leibchen",
  Team2 = "Bunt",
}

export interface Team {
  name: TeamNames;
  wins: number;
}

export interface Game {
  id: number;
  date_time: number;
  game_place: string;
  game_number: number;
  max_player: number;
  finished: number;
  wins_team_1: number;
  wins_team_2: number;
  standoff_games: number;
  money_pool_link: string;
}

export interface GamePlayer {
  id: number;
  user_id: number;
  paid: number;
  player_strength: number;
  first_name: string;
  last_name: string;
  list_place: number;
  team: string;
}

@Injectable()
export class UserData {
  private id: number;
  public firstName: string;
  public lastName: string;
  public userName: string;
  public mobilePhoneNumber: string;
  public email: string;
  public notificationId: string;
  private blocked: number;
  public blockedUntil: number;
  public userGroup: string;
  public playerStrength: number;
  public receiveMails: number;
  public receivePush: number;

  constructor(id: number) {
    this.id = id;
  }

  public getId(): number {
    return this.id;
  }

  public isBlocked(): boolean {
    return this.blocked == 1;
  }
  public static convertArray(data: any[]): UserData[] {
    if (data == null || data == undefined) {
      return null;
    }
    let ret: UserData[] = [];
    data.forEach((element) => {
      ret.push(UserData.convert(element));
    });
    return ret;
  }

  public static convert(data: any): UserData {
    if (data == null || data == undefined) {
      return null;
    }
    let ret = new UserData(+data.id);
    ret.firstName =
      data.first_name != undefined ? data.first_name : data.firstName;
    ret.lastName = data.last_name != undefined ? data.last_name : data.lastName;
    ret.userName = data.user_name != undefined ? data.user_name : data.userName;
    ret.mobilePhoneNumber =
      data.mobile_phone_number != undefined
        ? data.mobile_phone_number
        : data.mobilePhoneNumber;
    ret.notificationId =
      data.notification_id != undefined
        ? data.notification_id
        : data.notificationId;
    ret.playerStrength =
      data.player_strength != undefined
        ? +data.player_strength
        : +data.playerStrength;
    ret.receiveMails =
      data.receive_mails != undefined
        ? +data.receive_mails
        : +data.receiveMails;
    ret.receivePush =
      data.receive_push != undefined ? +data.receive_push : +data.receivePush;
    ret.userGroup =
      data.user_group != undefined ? data.user_group : data.userGroup;
    ret.blocked = data.blocked;
    ret.blockedUntil =
      data.blocked_until != undefined
        ? +data.blocked_until
        : +data.blockedUntil;
    ret.email = data.email;
    return ret;
  }
}

export interface Response {
  result: string;
  message: any;
  errorCode: ErrorCode;
}

export interface ErrorCode {
  code: number;
  errorText: string;
  extendedErrorText: string;
  sendAutomatically: boolean;
  stackTrace: string;
}

export enum ChangeUserDataIntention {
  ChangeAll,
  UserSettings,
}

@Injectable()
export class DefinitionsService {
  constructor() {}
}
