<div class="bg-image">
  <div *ngIf="!hideNotification" style="text-align: center;margin-top: 200px;">
    <h3>Keine Daten angegeben</h3>
  </div>

  <div *ngIf="hideNotification" class="flex-container form-content">
    <form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="register(formDirective)">
      <!-- Username -->
      <mat-form-field class="input-field-large">
        <input
          matInput
          type="text"
          [formControl]="usernameFormControl"
          placeholder="Username"
          [(ngModel)]="userName"
          minlength="3"
          maxlength="50"
          required
          cdkFocusInitial
        />
        <mat-error *ngIf="usernameFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
      </mat-form-field>

      <!-- FirstName -->
      <mat-form-field class="input-field-large">
        <input
          matInput
          type="text"
          [formControl]="firstnameFormControl"
          placeholder="Vorname"
          [(ngModel)]="firstName"
          minlength="3"
          maxlength="50"
          required
        />
        <mat-error *ngIf="firstnameFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
      </mat-form-field>

      <!-- LastName -->
      <mat-form-field class="input-field-large">
        <input
          matInput
          type="text"
          [formControl]="lastnameFormControl"
          placeholder="Nachname"
          [(ngModel)]="lastName"
          minlength="3"
          maxlength="50"
          required
        />
        <mat-error *ngIf="lastnameFormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
      </mat-form-field>

      <!-- MobilePhoneNumber-->
      <mat-form-field class="input-field-large">
        <span matPrefix>+49 &nbsp;</span>
        <input
          type="tel"
          matInput
          (keypress)="numberOnly($event)"
          placeholder="Handynummer"
          [formControl]="mobilephonenumFormControl"
          [(ngModel)]="mobilePhoneNumber"
          minlength="10"
          maxlength="12"
        />
        <mat-icon matSuffix>phone</mat-icon>
        <mat-error *ngIf="mobilephonenumFormControl.invalid">Nummer hat nicht die richtige Länge</mat-error>
      </mat-form-field>

      <!--Image upload-->
      <!-- <mat-form-field class="input-field-large" (click)="imageInput.click()">
      <input type="text" matInput [formControl]="imagenameFormControl" />
    </mat-form-field>
    <mat-icon *ngIf="selectedImage == null" (click)="imageInput.click()" style="cursor: pointer;">change_history</mat-icon>
    <mat-icon *ngIf="selectedImage != null" (click)="selectedImage = null; imagenameFormControl.setValue(imageInputText)" style="cursor: pointer;"
    >clear</mat-icon
    >
    
    <mat-error *ngIf="!profileImageAccepted">Bitte nur jpg/png und max. 500 KB</mat-error>
    <label class="image-upload-container btn btn-bwm" hidden>
      <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" />
    </label> -->

      <!-- Password1 -->
      <mat-form-field class="input-field-large">
        <input
          matInput
          type="password"
          [formControl]="password1FormControl"
          placeholder="Passwort eingeben"
          [(ngModel)]="password1"
          minlength="5"
          maxlength="16"
          required
        />
        <mat-error *ngIf="password1FormControl.invalid">Bitte min. 5 Zeichen angeben</mat-error>
      </mat-form-field>

      <!-- Password2 -->
      <mat-form-field class="input-field-large">
        <input
          matInput
          type="password"
          [formControl]="password2FormControl"
          placeholder="Passwort wiederholen"
          [(ngModel)]="password2"
          minlength="5"
          maxlength="16"
          required
        />
        <mat-error *ngIf="password2FormControl.invalid">Bitte min. 5 Zeichen angeben</mat-error>
      </mat-form-field>
      <mat-error *ngIf="formGroup.valid && password1FormControl.value != password2FormControl.value">Passwörter müssen übereinstimmen</mat-error>

      <button
        mat-raised-button
        color="primary"
        style="width: 70%;margin-top: 20px;height: 40px;"
        type="submit"
        [disabled]="!formGroup.valid || password1FormControl.value != password2FormControl.value"
      >
        Registrieren
      </button>
    </form>
  </div>
</div>
