import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

export interface DialogData {
  result: number;
  email: string;
}

@Component({
  selector: "forgot-password",
  templateUrl: "forgot-password.html"
})
export class ForgotPassworDialog {
  email: string = "";

  constructor(public dialogRef: MatDialogRef<ForgotPassworDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log("Start 'forgot password' dialog");
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onYesClick(): void {
    this.data.result = 1;
    this.data.email = this.email;
    this.dialogRef.close(this.data);
  }
}
