import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { ServerCommunication } from "../shared/server-communication.service";
import { CommonFunctions } from "../shared/common-functions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  ResponseTypeError,
  Game,
  Response,
  GamePlayer,
  ChangeUserDataIntention,
  UserData,
  ErrorCodes,
  TeamNames,
  ResponseTypeWarn,
  PayPalProductionKey,
} from "../shared/definitions.service";
import { MatDialog, MatSidenav } from "@angular/material";
import {
  JoinGuestDialog,
  DefaultDialogData,
} from "../dialogs/join-guest/join-guest";
import { PayDialog, PaymentDialogData } from "../dialogs/pay-dialog/pay-dialog";
import {
  SetUserDataDialog,
  ChangeUserDataDialogData,
} from "../dialogs/set-user-data/set-user-data";
import {
  BlockUserDialog,
  BlockUserDialogData,
} from "../dialogs/block-user/block-user";
import {
  UniversalDialog,
  UniversalDialogDialogData,
} from "../dialogs/universal-dialog/universal-dialog";
import {
  JoinUserToGameDialog,
  JoinUserDialogData,
} from "../dialogs/join-user-to-game/join-user-to-game";
import {
  DialogDataAddNewGame,
  AddNewGameDialog,
} from "../dialogs/add-new-game/add-new-game";
import {
  AddGameResultDialog,
  AddGameResultDialogData,
} from "../dialogs/add-game-result/add-game-result";
import {
  ShowTeamsDialog,
  ShowTeamsDialogData,
} from "../dialogs/show-teams/show-teams";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable } from "rxjs";
import { ShareWaDialog } from "../dialogs/share-wa/share-wa";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  constructor(
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog
  ) {}

  public gameData: Game;
  public gamePlayerData: GamePlayer[] = [];
  public myGamePlayer: GamePlayer = null; //represents the user in the list
  public selection = new SelectionModel<GamePlayer>(false, []);
  public fabOpen: boolean = false;
  public fabDirection: string = "up";

  //states
  public loadingData: boolean = true;
  public dataLoaded: boolean = false;
  public noConnection: boolean = false;
  public noOpenGame: boolean = false;

  ngOnInit() {
    this.loadData();
  }

  /**
   * Loads the current game data and the game player list
   */
  public loadData() {
    let first = this.serverCommunication.getCurrentGame();
    let second = this.serverCommunication.getCurrentGamePlayer();
    this.spinnerService.show();
    this.loadingData = true;
    this.dataLoaded = false;
    this.noOpenGame = false;

    Observable.zip(
      first,
      second,
      (currentGame: Response, gamePlayer: Response) => ({
        currentGame,
        gamePlayer,
      })
    ).subscribe(
      (pair) => {
        let gameData: Response = pair.currentGame;
        let gamePlayerData: Response = pair.gamePlayer;

        if (gameData == null || gamePlayerData == null) {
          this.dataLoaded = true;
          this.spinnerService.hide();
          this.loadingData = false;
          this.commonFunctions.showErrorMessage("Interner Fehler beim Laden");
          return;
        }

        //Game
        if (gameData.result === ResponseTypeError) {
          if (gameData.errorCode.code == ErrorCodes.NoOpeGame) {
            this.noOpenGame = true;
          } else {
            this.commonFunctions.showErrorMessage(gameData.message);
          }
        } else {
          this.gameData = gameData.message;
        }
        //GamePlayer
        if (gamePlayerData.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(gamePlayerData.message);
          this.gamePlayerData = [];
          this.dataLoaded = true;
        } else {
          this.gamePlayerData = gamePlayerData.message;
          if (this.gamePlayerData.length == 0) {
            this.commonFunctions.showWarnMessage(
              "Noch kein Spieler angemeldet"
            );
          }
          this.determineMyGamePlayer();
          this.dataLoaded = true;
        }
        this.spinnerService.hide();
        this.loadingData = false;
      },
      (error: any) => {
        console.log("No network connection");
        this.spinnerService.hide();
        this.noConnection = true;
        this.dataLoaded = true;
        this.loadingData = false;
      }
    );
  }

  /**
   * Leave the current game
   *
   * @param gamePlayerId
   */
  public leaveGame(gamePlayerId: number) {
    this.spinnerService.show();

    this.serverCommunication.leaveGame(gamePlayerId).subscribe(
      (data) => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
          this.updateGamePlayerList();
        } else {
          this.commonFunctions.showSuccessMessage("Ausgetreten");
          this.updateGamePlayerList();
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Leaves the game as the current user
   */
  public leaveGameThisUser() {
    //checken ob zeit unter 48h und dann dialog mit hinweis auf Whatsapp gruppe
    var currentTime = +Math.round(new Date().getTime() / 1000);
    var gameTime = +this.gameData.date_time;
    var delta = gameTime - currentTime;
    if (delta < 172800) {
      //48h
      var dialogRef = this.dialog.open(ShareWaDialog, {
        width: "450px",
        data: { result: 0 },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined && result === 1) {
          this.leveThisUserHelper();
        }
      });
    } else {
      this.leveThisUserHelper();
    }
  }

  private leveThisUserHelper() {
    if (this.myGamePlayer != null) {
      var gamePlayerId = this.myGamePlayer.id;
      this.leaveGame(gamePlayerId);
    } else {
      this.commonFunctions.showErrorMessage(
        "Interner Fehler: Home-Component konnte nicht aufgelöst werden"
      );
    }
  }

  /**
   * Join the current game
   */
  public joinGame(userId: number) {
    this.spinnerService.show();

    this.serverCommunication.joinGame(userId).subscribe(
      (data) => {
        // check if error
        if (data.result === ResponseTypeError) {
          //check for 'user blocked'
          if (data.errorCode.code == ErrorCodes.UserBlocked) {
            this.commonFunctions.showErrorMessage("Anmeldung nicht erlaubt");
          } else {
            this.commonFunctions.showErrorMessage(data.message);
          }
        } else {
          this.commonFunctions.showSuccessMessage("Beigetreten");
          this.updateGamePlayerList();
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Joins the game as the current user
   */
  public joinGameThisUser() {
    this.joinGame(this.commonFunctions.getUserId());
  }

  /**
   * Shows a dialog to add a guest player
   */
  public joinGuest() {
    const dialogRef = this.dialog.open(JoinGuestDialog, {
      width: "450px",
      data: <DefaultDialogData>{ result: 0 },
    });

    dialogRef.afterClosed().subscribe((result: DefaultDialogData) => {
      if (result != undefined) {
        if (result.result === 1) {
          this.commonFunctions.showSuccessMessage(
            "Gastspieler erfolgreich hinzugefügt"
          );
          this.updateGamePlayerList();
        }
      }
    });
  }

  public isAdmin(): boolean {
    return this.commonFunctions.isAdmin();
  }

  /**
   * Shows the button to pay for a Game
   */
  public showPayButton(): boolean {
    //zuerst checken ob GamePlayer der auf den aktuellen User matcht in der Liste ist
    if (
      this.myGamePlayer != null &&
      +this.myGamePlayer.list_place <= +this.gameData.max_player &&
      +this.myGamePlayer.paid == 0
    ) {
      //check ob ueberhaupt eine Zahlungsmethode hinterlegt ist
      if (
        (PayPalProductionKey !== null && PayPalProductionKey !== "") ||
        (this.gameData.money_pool_link !== null &&
          this.gameData.money_pool_link !== "")
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks if a player is in game
   *
   * @param gamePlayer
   */
  public playerIsInGame(gamePlayer: GamePlayer): boolean {
    if (gamePlayer == null || gamePlayer == undefined) {
      return false;
    }
    if (gamePlayer.list_place <= +this.gameData.max_player) {
      return true;
    }
    return false;
  }

  /**
   * Updates the game player list
   */
  public updateGamePlayerList() {
    this.spinnerService.show();
    this.dataLoaded = false;
    this.loadingData = true;

    this.serverCommunication.getCurrentGamePlayer().subscribe(
      (data) => {
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
          this.gamePlayerData = [];
          this.dataLoaded = true;
        } else {
          this.gamePlayerData = data.message;
          this.determineMyGamePlayer();
          this.dataLoaded = true;
        }
        this.spinnerService.hide();
        this.loadingData = false;
      },
      (error: any) => {
        console.log("No network connection");
        this.spinnerService.hide();
        this.noConnection = true;
        this.dataLoaded = true;
        this.loadingData = false;
      }
    );
  }

  /**
   * Deteremines which GamePlayer is the current user that is logged in
   */
  private determineMyGamePlayer() {
    var userId = this.commonFunctions.getUserId();
    var found: boolean = false;
    this.gamePlayerData.forEach((element) => {
      if (element.user_id == userId) {
        this.myGamePlayer = element;
        found = true;
      }
    });
    if (!found) {
      this.myGamePlayer = null;
    }
  }

  /**
   * Checks if the assigned GamePlayer User-id is the same as the one from the current user
   *
   * @param playerUserId
   */
  public playerIsThisUser(playerUserId: number): boolean {
    var userId = this.commonFunctions.getUserId();
    if (userId == playerUserId) {
      return true;
    }
    return false;
  }

  /**
   * Opens the dialog for the payment
   */
  public pay() {
    const dialogRef = this.dialog.open(PayDialog, {
      width: "450px",
      data: <PaymentDialogData>{
        result: 0,
        gamePlayerId: this.myGamePlayer.id,
        moneyPoolLink: this.gameData.money_pool_link,
      },
    });

    dialogRef.afterClosed().subscribe((result: PaymentDialogData) => {
      if (result != undefined && result.result === 1) {
        this.commonFunctions.showSuccessMessage("Bezahlung erfolgreich");
        this.updateGamePlayerList();
      }
    });
  }

  /**
   * Shows a dialog to select a user to join a game
   */
  public openDialogJoinUser() {
    const dialogRef = this.dialog.open(JoinUserToGameDialog, {
      width: "450px",
      data: <JoinUserDialogData>{ result: 0 },
    });

    dialogRef.afterClosed().subscribe((result: BlockUserDialogData) => {
      if (result != undefined) {
        if (result.result === 1) {
          this.commonFunctions.showSuccessMessage(
            "Spieler erfolgreich hinzugefügt"
          );
          this.updateGamePlayerList();
        }
      }
    });
  }

  /**
   * Cancels the current game
   */
  public cancelGame() {
    const dialogRef = this.dialog.open(UniversalDialog, {
      width: "450px",
      data: <UniversalDialogDialogData>{
        result: 0,
        operationName: "Absagen",
        contentText: "Willst du das Spiel wirklich absagen?",
        buttonNoName: "Nein",
        buttonYesName: "Ja",
      },
    });

    dialogRef.afterClosed().subscribe((result: BlockUserDialogData) => {
      if (result != undefined && result.result === 1) {
        this.spinnerService.show();
        this.serverCommunication.cancelCurrentGame(this.gameData.id).subscribe(
          (data) => {
            // check if error
            if (data.result === ResponseTypeError) {
              this.commonFunctions.showErrorMessage(data.message);
            } else {
              this.commonFunctions.showSuccessMessage(
                "Das spiel wurde abgesagt"
              );
              this.loadData();
            }
            this.spinnerService.hide();
          },
          (error) => {
            this.commonFunctions.showErrorMessage(error);
            this.spinnerService.hide();
          }
        );
      }
    });
  }

  /**
   * Opens a dialog to change a users data
   *
   * @param gamePlayerData
   * @param userData
   */
  public openDialogChangeUserData(
    gamePlayerData: GamePlayer = null,
    userData: UserData = null
  ) {
    let intention: ChangeUserDataIntention = this.isAdmin()
      ? ChangeUserDataIntention.ChangeAll
      : ChangeUserDataIntention.UserSettings;

    const dialogRef = this.dialog.open(SetUserDataDialog, {
      width: "450px",
      data: <ChangeUserDataDialogData>{
        result: 0,
        intention: intention,
        gamePlayerData: gamePlayerData,
        userData: userData,
      },
    });

    dialogRef.afterClosed().subscribe((result: ChangeUserDataDialogData) => {
      if (result != undefined && result.result === 1) {
        this.commonFunctions.showSuccessMessage(
          "Userdaten erfolgreich gesetzt"
        );
      }
    });
  }

  /**
   * Opens a dialog to block a user
   * @param userId
   */
  public openDialogBlockUser(userId: number = null) {
    const dialogRef = this.dialog.open(BlockUserDialog, {
      width: "450px",
      data: <BlockUserDialogData>{ result: 0, userId: userId },
    });

    dialogRef.afterClosed().subscribe((result: BlockUserDialogData) => {
      if (result != undefined && result.result === 1) {
        this.commonFunctions.showSuccessMessage("Operation erfolgreich");
      }
    });
  }

  /**
   * Asks the user if to remove a player from the game
   *
   * @param gamePlayerId
   */
  public askToRemovePlayerFromList(gamePlayerId: number) {
    const dialogRef = this.dialog.open(UniversalDialog, {
      width: "450px",
      data: <UniversalDialogDialogData>{
        result: 0,
        operationName: "Spieler abmelden",
        contentText: "Willst du diesen Spieler wirklich von der Liste nehmen?",
        buttonNoName: "Nein",
        buttonYesName: "Ja",
      },
    });

    dialogRef.afterClosed().subscribe((result: BlockUserDialogData) => {
      if (result != undefined) {
        if (result.result === 1 || result.result === 2) {
          this.leaveGame(gamePlayerId);
        }
      }
    });
  }

  /**
   * Open Dialog to create new Game
   */
  public openCreateNewGameDialog() {
    console.log("Get last game data");
    this.spinnerService.show();

    this.serverCommunication.getLastFinishedGame().subscribe(
      (data) => {
        var dialogData: DialogDataAddNewGame = {
          result: 0,
          gameNumber: -1,
          gameId: -1,
          maxPlayer: -1,
          moneyPoolLink: "",
        };

        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          var lastGame: Game = data.message;
          dialogData.gameNumber = +lastGame.game_number + 1;
          dialogData.gameId = +lastGame.id;
          dialogData.maxPlayer = +lastGame.max_player;
        }
        this.spinnerService.hide();

        //open dialog
        const dialogRef = this.dialog.open(AddNewGameDialog, {
          width: "450px",
          data: dialogData,
        });

        dialogRef.afterClosed().subscribe((result: DialogDataAddNewGame) => {
          if (result != undefined) {
            if (result.result === 1) {
              this.commonFunctions.showSuccessMessage("Spiel wurde angelegt");
            }
            this.loadData();
          }
        });
      },
      (error) => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Open dialog to set the game results
   */
  public openDialogAddGameResult() {
    const dialogRef = this.dialog.open(AddGameResultDialog, {
      width: "450px",
      data: <AddGameResultDialogData>{
        result: 0,
      },
    });

    dialogRef.afterClosed().subscribe((result: AddGameResultDialogData) => {
      if (result != undefined && result.result === 1) {
        this.commonFunctions.showSuccessMessage("Spielergebnis eingetragen");
      }
    });
  }

  /**
   * Close the current game
   */
  public closeGame() {
    const dialogRef = this.dialog.open(UniversalDialog, {
      width: "450px",
      data: <UniversalDialogDialogData>{
        result: 0,
        operationName: "Spielergebnis eingetragen",
        contentText: "Hast du schon das Spielergebnis eingetragen?",
        buttonNoName: "Nein",
        buttonYesName: "Ja",
      },
    });

    dialogRef.afterClosed().subscribe((result: BlockUserDialogData) => {
      if (result != undefined && result.result === 1) {
        const dialogRef = this.dialog.open(UniversalDialog, {
          width: "450px",
          data: <UniversalDialogDialogData>{
            result: 0,
            operationName: "Spiel abschließen",
            contentText: "Willst du das Spiel abschließen?",
            buttonNoName: "Nein",
            buttonYesName: "Ja",
          },
        });

        dialogRef.afterClosed().subscribe((result: BlockUserDialogData) => {
          if (result != undefined && result.result === 1) {
            this.spinnerService.show();

            this.serverCommunication.closeCurrentGame().subscribe(
              (data) => {
                // check if error
                if (data.result === ResponseTypeError) {
                  this.commonFunctions.showErrorMessage(data.message);
                } else {
                  this.loadData(); //update all data
                }
                this.spinnerService.hide();
              },
              (error) => {
                this.commonFunctions.showErrorMessage(error);
                this.spinnerService.hide();
              }
            );
          }
        });
      }
    });
  }

  /**
   * Starts the process of automatic team calculation
   */
  public automaticTeamGeneration() {
    this.spinnerService.show();

    this.serverCommunication.calculateTeams().subscribe(
      (data) => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else if (data.result === ResponseTypeWarn) {
          this.commonFunctions.showWarnMessage(data.message);
          this.updateGamePlayerList();
        } else {
          this.updateGamePlayerList();
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Gets the team strength
   *
   * @param team
   */
  public getTeamStrength(team: number): number {
    let cnt: number = 0;
    this.gamePlayerData.forEach((element) => {
      let teamName = +team == 1 ? TeamNames.Team1 : TeamNames.Team2;
      if (element.team == teamName) {
        cnt += +element.player_strength;
      }
    });
    return cnt;
  }

  /**
   * Shows a dialog to select a user to join a game
   */
  public openDialogShowTeams() {
    const dialogRef = this.dialog.open(ShowTeamsDialog, {
      width: "550px",
      data: <ShowTeamsDialogData>{ result: 0, gamePlayer: this.gamePlayerData },
    });

    dialogRef.afterClosed().subscribe((result: ShowTeamsDialogData) => {
      if (result != undefined) {
        if (result.result === 1) {
          this.commonFunctions.showSuccessMessage(
            "Neue Teamverteilung gespeichert"
          );
          this.updateGamePlayerList();
        }
      }
    });
  }

  //handles a click on a list element
  public onListClick(listElement: GamePlayer) {
    this.selection.toggle(listElement);
  }

  public isSelected(player: GamePlayer) {
    if (
      player === undefined ||
      this.selection === undefined ||
      this.selection.selected === undefined
    ) {
      return false;
    }
    if (this.selection.selected.indexOf(player) >= 0) {
      return true;
    }
    return false;
  }

  public getSelected(): GamePlayer {
    return this.selection.selected[0];
  }

  public isGamePresent(): boolean {
    return (
      this.gameData != null &&
      this.gameData != undefined &&
      this.gamePlayerData != null &&
      this.gamePlayerData != undefined
    );
  }

  //checks if the game is finished
  public isGameFinished(): boolean {
    if (
      this.gameData != null &&
      this.gameData != undefined &&
      this.gameData.finished == 1
    ) {
      return true;
    }
    return false;
  }

  /**
   * Checks if the current game is passed
   */
  public isGameOutdated(): boolean {
    if (this.gameData != null && this.gameData != undefined) {
      var currentTime = +Math.round(new Date().getTime() / 1000);
      var gameTime = +this.gameData.date_time;
      if (currentTime < gameTime + 18000) {
        //inkl. 5h delay
        return false;
      }
    }
    return true;
  }

  public setPaid(gamePlayerId: number) {
    this.serverCommunication.setAsPaid(gamePlayerId).subscribe(
      (data) => {
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.updateGamePlayerList();
        }
        this.spinnerService.hide();
      },
      (error: any) => {
        this.commonFunctions.showErrorMessage(
          "Setzen des Zahlungsstatus fehlgeschlagen"
        );
        this.spinnerService.hide();
      }
    );
  }
}
