<h1 mat-dialog-title>PayPal MoneyPool link setzen</h1>
<hr />
<div mat-dialog-content>
  <mat-form-field style="width: 90%; margin-left: 5%; margin-right: 5%;">
    <input
      matInput
      type="text"
      placeholder="MoneyPool link"
      [(ngModel)]="moneyPoolLink"
      minlength="3"
      maxlength="150"
      required
      cdkFocusInitial
    />
    <button
      mat-button
      *ngIf="moneyPoolLink"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="moneyPoolLink = ''"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>

<hr />
<div mat-dialog-actions>
  <button
    mat-raised-button
    type="reset"
    style="width: 47%;"
    (click)="onNoClick()"
  >
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="primary"
    style="width: 47%;"
    type="button"
    (click)="onYesClick()"
    [disabled]="moneyPoolLink.length < 3"
    cdkFocusInitial
  >
    Setzen
  </button>
</div>
