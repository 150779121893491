import { Injectable } from "@angular/core";
import { HttpEvent, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { AuthService } from "./auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).catch((err: HttpErrorResponse) => {
      if (this.router.url !== "/login" && err.status === 401) {
        console.log("Unauthorized access. Forward to login");
        this.authService.logout();
        this.router.navigate(["login"]);
      }
      return Observable.throw(err);
    });
  }
}
