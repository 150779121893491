import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { catchError, retry } from "rxjs/operators";
import {
  RetryMax,
  SiteUrl,
  TeamNames,
  GamePlayer,
  Response,
  UserData,
  ResponseTypeError,
} from "./definitions.service";
import { CommonFunctions } from "./common-functions.service";
import { Observable } from "rxjs";
import { reject } from "q";
import { Router } from "@angular/router";

@Injectable()
export class ServerCommunication {
  private _userData: UserData = null;

  constructor(
    private http: HttpClient,
    private commonFunctions: CommonFunctions,
    private router: Router
  ) {}

  private buildHttpOptions() {
    var ret = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    ret.set("authorization", "Bearer " + this.getJwt());
    var httpOptions = {
      headers: ret,
    };
    return httpOptions;
  }

  private getJwt() {
    return localStorage.getItem("jwt");
  }

  /**
   * Verify Login
   *
   * @param username
   * @param password
   * @param headers
   */
  public verifyLoginData(
    username: string,
    password: string
  ): Observable<Response> {
    //clear token
    this.commonFunctions.clearToken();
    this._userData = null;
    var body = new HttpParams()
      .set("username", username)
      .set("password", password);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/verifyLogin.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public prepareResetPassword(email: string): Observable<Response> {
    var body = new HttpParams().set("email", email);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/prepareResetPassword.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public setNewPassword(
    userId: number,
    password: string
  ): Observable<Response> {
    var body = new HttpParams()
      .set("password", password)
      .set("userId", userId.toString());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/setNewPassword.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public register(
    username: string,
    firstname: string,
    lastname: string,
    password: string,
    email: string,
    mobilephonenum: string = null
  ): Observable<Response> {
    var body = new HttpParams()
      .set("username", username)
      .set("firstname", firstname)
      .set("lastname", lastname)
      .set("password", password)
      .set("email", email);
    if (mobilephonenum != null) {
      body = body.set("mobilephonenum", mobilephonenum);
    }

    return this.http
      .post<Response>(
        SiteUrl + "/server/public/register.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public addNewUser(
    email: string,
    playerStrength: number
  ): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("email", email)
      .set("playerStrength", playerStrength.toString());

    return this.http
      .post<Response>(
        SiteUrl + "/server/public/addNewUser.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public getLastFinishedGame(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/getLastFinishedGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public getCurrentGame(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/getCurrentGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getCurrentGamePlayer(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/getCurrentGamePlayer.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public addNewGame(
    dateTime: number,
    gamePlace: string,
    gameNumber: string,
    maxPlayer: number,
    gameId: number = -1,
    moneyPoolLink: string = ""
  ): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("dateTime", dateTime.toString())
      .set("gamePlace", gamePlace)
      .set("gameNumber", gameNumber)
      .set("maxPlayer", maxPlayer.toString())
      .set("team1Name", TeamNames.Team1)
      .set("team2Name", TeamNames.Team2)
      .set("moneyPoolLink", moneyPoolLink);

    if (gameId != -1) {
      body = body.set("gameId", gameId.toString());
    }

    return this.http
      .post<Response>(
        SiteUrl + "/server/public/addNewGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        // retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public joinGame(userId: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("userId", userId.toString())
      .set("team1Name", TeamNames.Team1)
      .set("team2Name", TeamNames.Team2);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/joinGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public leaveGame(gamePlayerId: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("gamePlayerId", gamePlayerId.toString())
      .set("team1Name", TeamNames.Team1)
      .set("team2Name", TeamNames.Team2);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/leaveGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public joinGuestInGame(
    firstName: string,
    lastName: string,
    playerStrength: number = 5
  ): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("playerStrength", playerStrength.toString())
      .set("team1Name", TeamNames.Team1)
      .set("team2Name", TeamNames.Team2);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/joinGuestInGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public setMoneyPoolLink(link: string): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("link", link);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/setMoneyPoolLink.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public setAsPaid(gamePlayerId: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("gamePlayerId", gamePlayerId.toString());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/setAsPaid.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public cancelCurrentGame(gameId: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("gameId", gameId.toString());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/cancelCurrentGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getAllUsers(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/getAllUsers.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getUser(id: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("userId", id.toString());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/getUser.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public setUserData(
    userId: number,
    userName: string = null,
    firstName: string = null,
    lastName: string = null,
    playerStrength: number = null,
    mobilePhoneNumber: string = null,
    email: string = null,
    blocked: number = null,
    blockedUntil: number = null,
    userGroup: string = null,
    receiveMails: number = null,
    receivePush: number = null
  ): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("userId", userId.toString());
    if (userName != null) {
      body = body.set("userName", userName);
    }
    if (firstName != null) {
      body = body.set("firstName", firstName);
    }
    if (lastName != null) {
      body = body.set("lastName", lastName);
    }
    if (playerStrength != null) {
      body = body.set("playerStrength", playerStrength.toString());
    }
    if (mobilePhoneNumber != null) {
      body = body.set("mobilePhoneNumber", mobilePhoneNumber);
    }
    if (email != null) {
      body = body.set("email", email);
    }
    if (blocked != null) {
      body = body.set("blocked", blocked.toString());
    }
    if (blockedUntil != null) {
      body = body.set("blockedUntil", blockedUntil.toString());
    }
    if (userGroup != null) {
      body = body.set("userGroup", userGroup);
    }
    if (receiveMails != null) {
      body = body.set("receiveMails", receiveMails.toString());
    }
    if (receivePush != null) {
      body = body.set("receivePush", receivePush.toString());
    }
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/setUserData.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public closeCurrentGame(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/closeCurrentGame.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public addGameResult(
    winsTeam1: number,
    winsTeam2: number,
    standoff: number
  ): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("winsTeam1", winsTeam1.toString())
      .set("winsTeam2", winsTeam2.toString())
      .set("standoff", standoff.toString());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/addGameResult.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public calculateTeams(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("team1Name", TeamNames.Team1)
      .set("team2Name", TeamNames.Team2);
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/calculateTeams.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public manualSetTeams(gamePlayer: GamePlayer[]): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt())
      .set("gamePlayer", JSON.stringify(gamePlayer));
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/manualSetTeams.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        retry(RetryMax), // retry a failed request
        catchError(this.handleError) // then handle the error
      );
  }

  public getCountGamesPerPlayer(userId: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("userId", userId.toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/statistics/getCountGamesPerPlayer.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getCountGameDaysPerPlayer(userId: number): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("userId", userId.toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/statistics/getCountGameDaysPerPlayer.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getListBestPlayers(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/statistics/getListBestPlayers.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getArrayPlayerToGameDays(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/statistics/getArrayPlayerToGameDays.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  public getDataForTable(): Observable<Response> {
    var body = new HttpParams()
      .set("id", this.getUserId().toString())
      .set("token", this.getJwt());
    return this.http
      .post<Response>(
        SiteUrl + "/server/public/statistics/getDataForTable.php",
        body.toString(),
        this.buildHttpOptions()
      )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  /**
   * Gets the user id from the token
   */
  private getUserId(): number {
    return this.commonFunctions.getUserId();
  }

  /**
   * Gets the user data from the jwt
   */
  public getUserData(reFetch: boolean = false): Promise<UserData> {
    return new Promise<UserData>((resolve) => {
      if (this._userData == null || reFetch) {
        var userId = this.commonFunctions.getUserId();
        if (userId != -1) {
          this.getUser(userId).subscribe(
            (data) => {
              if (data == null) {
                console.log("Konnte Userdaten nicht laden");
                reject("Konnte Userdaten nicht laden");
                return;
              }
              // check if error
              if (data.result === ResponseTypeError) {
                reject(data.message);
              } else {
                this._userData = UserData.convert(data.message);
                resolve(this._userData);
              }
            },
            (error) => {
              reject(error);
            }
          );
        }
      } else {
        resolve(this._userData);
      }
    });
  }

  /**
   * Handles an error of a server request
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      if (error.status == 0) {
        return new ErrorObservable(
          "Server call fehlgeschlagen. Keine Verbindung ?"
        );
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
            `body was: ${error.message}`
        );
        console.error(error.error.text);

        //console.error(`Stack trace: ${error.error.stack}`);
        if (error.message != undefined)
          return new ErrorObservable(error.message);
      }
    }
    // return an ErrorObservable with a user-facing error message
    return new ErrorObservable("Error occured; please try again later.");
  }
}
