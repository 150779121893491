import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatSelect } from "@angular/material";
import { FormControl, FormGroup, FormGroupDirective } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { _localeFactory } from "@angular/core/src/application_module";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { UserData, ResponseTypeError } from "../../shared/definitions.service";

export interface BlockUserDialogData {
  result: number;
  userId: number;
}

@Component({
  selector: "block-user",
  templateUrl: "block-user.html",
  styleUrls: ["./block-user.css"]
})
export class BlockUserDialog {
  public title: string = "User blockieren";
  public minDateNow: Date = new Date();
  public blockDateTime: Date;
  public checkBoxBlockUser: boolean;
  public showUserSearch: boolean = false;
  public selectedUser: UserData;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;

  public userSelectionCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public blockUntilFormControl = new FormControl();
  public blockUserFormControl = new FormControl();

  //-------------------------------
  /** list of users */
  protected usersList: UserData[] = [];

  /** list of users filtered by search keyword */
  public filteredUsers: ReplaySubject<UserData[]> = new ReplaySubject<UserData[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @ViewChild("singleSelect") singleSelect: MatSelect;

  //---------------------------------

  constructor(
    public dialogRef: MatDialogRef<BlockUserDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: BlockUserDialogData,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("Starting 'block user dialog'");

    //set up form group
    this.formGroup = new FormGroup({
      userSelectionCtrl: this.userSelectionCtrl,
      userFilterCtrl: this.userFilterCtrl,
      blockUntilFormControl: this.blockUntilFormControl,
      blockUserFormControl: this.blockUserFormControl
    });

    if (dialogData.userId != null) {
      this.loadUser(dialogData.userId);

      this.blockDateTime = new Date();
      this.blockDateTime.setMonth(this.blockDateTime.getMonth() + 1);
    } else {
      this.showUserSearch = true;
      this.loadAllUsers();
    }
  }

  ngOnInit() {
    if (this.showUserSearch) {
      // set initial selection
      //this.userSelectionCtrl.setValue(this.usersList[1]);

      // load the initial bank list
      this.filteredUsers.next(this.usersList.slice());

      // listen for search field value changes
      this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterUser();
      });
    }
  }

  ngAfterViewInit() {
    if (this.showUserSearch) {
      this.setInitialValue();
    }
  }

  onNoClick(): void {
    this.dialogData.result = 0;
    this.dialogRef.close(this.dialogData);
  }

  onYesClick(formDirective: FormGroupDirective): void {
    this.formGroupDirective = formDirective;
    this.dialogData.result = 1;
    this.dialogRef.close(this.dialogData);
  }

  //Raised when selection of user is changed
  onChangeUser(user: UserData) {
    this.selectedUser = user;
    this.blockUserFormControl.setValue(user.isBlocked());
    if (!user.isBlocked()) {
      this.blockUntilFormControl.disable();
    } else {
      this.blockUntilFormControl.enable();
    }
    if (user.blockedUntil != 0 && user.blockedUntil != null && user.blockedUntil != NaN) {
      this.blockDateTime = new Date(user.blockedUntil * 1000);
    } else {
      this.blockDateTime = new Date();
      this.blockDateTime.setMonth(this.blockDateTime.getMonth() + 1);
    }
  }

  /**
   * Raised when checkbox to block user is clicked
   * @param checked
   */
  public checkedChangeBlockUser(checked: boolean) {
    if (!checked) {
      this.blockUntilFormControl.disable();
    } else {
      this.blockUntilFormControl.enable();
    }
  }

  public setData() {
    this.spinnerService.show();

    let block = this.blockUserFormControl.value ? 1 : 0;
    let blockUntil = this.blockDateTime.getTime() / 1000;
    if (block == 0) {
      blockUntil = 0;
    }

    this.serverCommunication.setUserData(this.selectedUser.getId(), null, null, null, null, null, null, block, blockUntil).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.dialogData.result = 1;
          this.dialogRef.close(this.dialogData);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  private loadUser(id: number) {
    this.spinnerService.show();

    this.serverCommunication.getUser(id).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          let userData: UserData = UserData.convert(data.message);

          //set local field
          this.selectedUser = userData;

          //Checkbox
          this.blockUserFormControl.setValue(userData.isBlocked());

          //block until
          let blockedUntil = userData.blockedUntil;
          if (blockedUntil != undefined && blockedUntil != null) {
            this.blockDateTime = new Date(blockedUntil * 1000);
          }
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
        this.dialogRef.close(this.dialogData);
      }
    );
  }

  private loadAllUsers() {
    this.spinnerService.show();

    this.serverCommunication.getAllUsers().subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.usersList = UserData.convertArray(data.message);
          this.filteredUsers.next(this.usersList);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
        this.dialogRef.close(this.dialogData);
      }
    );
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredUsers
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  /**
   * Filters the user selection
   */
  protected filterUser() {
    if (!this.usersList) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.usersList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredUsers.next(
      this.usersList.filter(
        user =>
          user.firstName.toLowerCase().indexOf(search) > -1 ||
          user.lastName.toLowerCase().indexOf(search) > -1 ||
          user.userName.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
