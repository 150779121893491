import { OnInit } from "@angular/core";
import { CommonFunctions } from "../shared/common-functions.service";
import { ServerCommunication } from "../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ResponseTypeWarn } from "../shared/definitions.service";
import { MatSort, MatTableDataSource } from "@angular/material";
var StatisticsTableComponent = /** @class */ (function () {
    function StatisticsTableComponent(serverCommunication, commonFunctions, spinnerService) {
        var _this = this;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.displayedColumns = [];
        this.columnNames = [
            {
                id: "fn",
                value: "Vorname"
            },
            {
                id: "last_name",
                value: "Nachname"
            },
            {
                id: "gameDays",
                value: "Spieltage"
            },
            {
                id: "wins",
                value: "Siege"
            },
            {
                id: "loss",
                value: "Niederlagen"
            },
            {
                id: "standoff",
                value: "Unentschieden"
            }
        ];
        console.log("loading statistics table component");
        this._userData = null;
        //get user data
        this.serverCommunication.getUserData().then(function (userData) {
            _this._userData = userData;
        });
    }
    StatisticsTableComponent.prototype.ngOnInit = function () {
        this.displayedColumns = this.columnNames.map(function (x) { return x.id; });
        this.getDataForTable();
    };
    /**
     * Get list with data for a table view of statistics.
     * Liste mit: Vorname Name (Username), Spieltage, Siege, Niederlagen, Unentschieden
     */
    StatisticsTableComponent.prototype.getDataForTable = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getDataForTable().subscribe(function (data) {
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
            }
            else {
                var tableArr = data.message;
                _this.dataSource = new MatTableDataSource(tableArr);
                _this.dataSource.sort = _this.sort;
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    StatisticsTableComponent.prototype.highlightRow = function (row) {
        if (row == null || row == undefined) {
            return false;
        }
        var firstName = row.fn;
        var lastName = row.last_name;
        if (this._userData.firstName == firstName && this._userData.lastName == lastName) {
            return true;
        }
        return false;
    };
    return StatisticsTableComponent;
}());
export { StatisticsTableComponent };
