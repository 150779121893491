import { MatDialogRef, } from "@angular/material";
import { FormControl, FormGroup, } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ResponseTypeWarn, } from "../../shared/definitions.service";
var AddNewGameDialog = /** @class */ (function () {
    function AddNewGameDialog(dialogRef, data, _serverCommunication, _commonFunctions, _spinnerService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._serverCommunication = _serverCommunication;
        this._commonFunctions = _commonFunctions;
        this._spinnerService = _spinnerService;
        this.gameNumber = 0;
        this.gamePlace = "Hansa";
        this.maxPlayer = 12;
        this.moneyPoolLink = "";
        this.minDateNow = new Date();
        this._lastGameId = -1;
        this.gameNumberFormControl = new FormControl();
        this.startDateFormControl = new FormControl();
        this.gamePlaceFormControl = new FormControl();
        this.maxPlayerFormControl = new FormControl();
        this.moneyPoolLinkFormControl = new FormControl();
        console.log("Start 'add new game' dialog");
        //datetime auf naechsten Freitag 20 Uhr setzen
        this.startDateTime = new Date();
        this.startDateTime = this.getNextDayOfWeek(this.startDateTime, 5);
        this.startDateTime.setHours(20);
        this.startDateTime.setMinutes(0);
        this.startDateTime.setSeconds(0);
        //set up form group
        this.formGroup = new FormGroup({
            gameNumberFormControl: this.gameNumberFormControl,
            startDateFormControl: this.startDateFormControl,
            gamePlaceFormControl: this.gamePlaceFormControl,
            maxPlayerFormControl: this.maxPlayerFormControl,
            moneyPoolLinkFormControl: this.moneyPoolLinkFormControl,
        });
        //take assigned data from old game
        this._lastGameId = +data.gameId;
        if (+data.gameNumber != -1) {
            this.gameNumberFormControl.setValue(+data.gameNumber);
            this.gameNumber = +data.gameNumber;
        }
        if (+data.maxPlayer != -1) {
            this.maxPlayerFormControl.setValue(+data.maxPlayer);
            this.maxPlayer = +data.maxPlayer;
        }
    }
    AddNewGameDialog.prototype.ngAfterViewInit = function () { };
    AddNewGameDialog.prototype.onNoClick = function () {
        this.data.result = 0;
        this.dialogRef.close(this.data);
    };
    AddNewGameDialog.prototype.onYesClick = function (formDirective) {
        this.formGroupDirective = formDirective;
        this.data.result = 1;
        this.dialogRef.close(this.data);
    };
    /**
     * Raised when the input picker is clicked
     */
    AddNewGameDialog.prototype.onDateTimePickerOpen = function () {
        var width = window.screen.width;
        var height = window.screen.height;
        setTimeout(function () {
            var element = document.getElementById("cdk-overlay-2");
            var picker = document.getElementById("owl-dt-picker-0");
            if (width < 500) {
                picker.style.width = "18em";
                element.style.left = "20px";
                element.style.bottom = "20px";
            }
            else {
                picker.style.width = "30em";
                element.style.left = width * 0.2 + "px";
                element.style.bottom = height * 0.1 + "px";
            }
        }, 1500);
    };
    /**
     * Adds a new game
     */
    AddNewGameDialog.prototype.addNewGame = function () {
        var _this = this;
        this._spinnerService.show();
        var gameNumber = this.gameNumberFormControl.value;
        var gamePlace = this.gamePlaceFormControl.value;
        var startDate = this.startDateTime.getTime() / 1000;
        var maxPlayer = this.maxPlayerFormControl.value;
        var moneyPoolLink = this.moneyPoolLinkFormControl.value;
        this._serverCommunication
            .addNewGame(startDate, gamePlace, gameNumber, maxPlayer, -1, moneyPoolLink)
            .subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this._commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this._commonFunctions.showWarnMessage(data.message);
                _this.data.result = 2;
                _this.dialogRef.close(_this.data);
            }
            else {
                _this.data.result = 1;
                _this.dialogRef.close(_this.data);
            }
            _this._spinnerService.hide();
        }, function (error) {
            _this._commonFunctions.showErrorMessage(error);
            _this._spinnerService.hide();
        });
    };
    /**
     * Gets the next wanted day of week from a given start date
     *
     * @param date
     * @param dayOfWeek
     */
    AddNewGameDialog.prototype.getNextDayOfWeek = function (date, dayOfWeek) {
        var resultDate = new Date(date.getTime());
        resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));
        return resultDate;
    };
    return AddNewGameDialog;
}());
export { AddNewGameDialog };
