import { MatDialogRef, MatSelect } from "@angular/material";
import { FormControl, FormGroup } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { UserData, ResponseTypeError, ResponseTypeWarn } from "../../shared/definitions.service";
var JoinUserToGameDialog = /** @class */ (function () {
    //---------------------------------
    function JoinUserToGameDialog(dialogRef, dialogData, serverCommunication, commonFunctions, spinnerService) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.title = "Spieler hinzufügen";
        this.userSelectionCtrl = new FormControl();
        this.userFilterCtrl = new FormControl();
        //-------------------------------
        /** list of users */
        this.usersList = [];
        /** list of users filtered by search keyword */
        this.filteredUsers = new ReplaySubject(1);
        /** Subject that emits when the component has been destroyed. */
        this._onDestroy = new Subject();
        console.log("Starting 'join user to game dialog'");
        //set up form group
        this.formGroup = new FormGroup({
            userSelectionCtrl: this.userSelectionCtrl,
            userFilterCtrl: this.userFilterCtrl
        });
        this.loadAllUsers();
    }
    JoinUserToGameDialog.prototype.ngOnInit = function () {
        // set initial selection
        //this.userSelectionCtrl.setValue(this.usersList[1]);
        var _this = this;
        // load the initial bank list
        this.filteredUsers.next(this.usersList.slice());
        // listen for search field value changes
        this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(function () {
            _this.filterUser();
        });
    };
    JoinUserToGameDialog.prototype.ngAfterViewInit = function () { };
    JoinUserToGameDialog.prototype.onNoClick = function () {
        this.dialogData.result = 0;
        this.dialogRef.close(this.dialogData);
    };
    //Raised when selection of user is changed
    JoinUserToGameDialog.prototype.onChangeUser = function (user) {
        this.selectedUser = user;
    };
    JoinUserToGameDialog.prototype.joinUser = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.joinGame(this.selectedUser.getId()).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else if (data.result === ResponseTypeWarn) {
                _this.commonFunctions.showWarnMessage(data.message);
                _this.dialogData.result = 2;
                _this.dialogRef.close(_this.dialogData);
            }
            else {
                _this.dialogData.result = 1;
                _this.dialogRef.close(_this.dialogData);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    JoinUserToGameDialog.prototype.loadAllUsers = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getAllUsers().subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.usersList = UserData.convertArray(data.message);
                _this.filteredUsers.next(_this.usersList);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
            _this.dialogRef.close(_this.dialogData);
        });
    };
    /**
     * Sets the initial value after the filteredBanks are loaded initially
     */
    JoinUserToGameDialog.prototype.setInitialValue = function () {
        var _this = this;
        this.filteredUsers
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            _this.singleSelect.compareWith = function (a, b) { return a && b && a.id === b.id; };
        });
    };
    /**
     * Filters the user selection
     */
    JoinUserToGameDialog.prototype.filterUser = function () {
        if (!this.usersList) {
            return;
        }
        // get the search keyword
        var search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredUsers.next(this.usersList.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the users
        this.filteredUsers.next(this.usersList.filter(function (user) {
            return user.firstName.toLowerCase().indexOf(search) > -1 ||
                user.lastName.toLowerCase().indexOf(search) > -1 ||
                user.userName.toLowerCase().indexOf(search) > -1;
        }));
    };
    return JoinUserToGameDialog;
}());
export { JoinUserToGameDialog };
