import { Injectable } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeOk } from "./definitions.service";
import { ServerCommunication } from "./server-communication.service";
import { Response } from "./definitions.service";
import { CommonFunctions } from "./common-functions.service";

@Injectable()
export class AuthService {
  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions
  ) {}

  public logout() {
    //jwt aus local cache clearen
    localStorage.clear();
    this.commonFunctions.clearToken();
  }

  /**
   * Checks if the current token is still valid
   */
  public isAuthenticated(): boolean {
    var token = localStorage.getItem("jwt");

    if (token == null || token == undefined || token == "") {
      return false;
    }
    return true;
  }

  /**
   * Executes a login operation
   *
   * @param username
   * @param password
   */
  public login(username: string, password: string): Promise<Boolean> {
    this.commonFunctions.clearToken();
    this.spinnerService.show();

    var prom = new Promise((resolve: (res: Boolean) => void, reject: (res: string) => void) => {
      console.log("Verifying login...");
      this.serverCommunication.verifyLoginData(username, password).subscribe(
        (data: Response) => {
          var result = data.result;
          var message = data.message;
          this.spinnerService.hide();

          if (result === ResponseTypeOk) {
            console.log("Login successfull");

            //save jwt to local storage
            localStorage.setItem("jwt", message);

            resolve(true);
          } else {
            console.log("Login failed");
            localStorage.setItem("jwt", "");
            resolve(false);
          }
        },
        error => {
          this.spinnerService.hide();
          reject(error);
        }
      );
    });
    return prom;
  }
}
