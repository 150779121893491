import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { _localeFactory } from "@angular/core/src/application_module";
import { ResponseTypeError, TeamNames, Team, GamePlayer } from "../../shared/definitions.service";
import { SelectionModel } from "@angular/cdk/collections";

export interface ShowTeamsDialogData {
  result: number;
  gamePlayer: GamePlayer[];
}

@Component({
  selector: "show-teams",
  templateUrl: "show-teams.html",
  styleUrls: ["./show-teams.css"]
})
export class ShowTeamsDialog {
  public title: string = "Teamverteilung";
  public gamePlayer: GamePlayer[];
  public selection = new SelectionModel<GamePlayer>(false, []);

  constructor(
    public dialogRef: MatDialogRef<ShowTeamsDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ShowTeamsDialogData,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("Starting 'show teams' dialog");
    this.gamePlayer = dialogData.gamePlayer;
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  onNoClick(): void {
    this.dialogData.result = 0;
    this.dialogRef.close(this.dialogData);
  }

  public saveData() {
    this.spinnerService.show();

    this.serverCommunication.manualSetTeams(this.gamePlayer).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.dialogData.result = 1;
          this.dialogRef.close(this.dialogData);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
      }
    );
  }

  /**
   * Swaps a player to the other team
   *
   */
  public swap() {
    let gamePlayer = this.getSelected();
    if (gamePlayer == null || gamePlayer == undefined) {
      return;
    }
    if (gamePlayer.team == TeamNames.Team1) {
      gamePlayer.team = TeamNames.Team2;
    } else if (gamePlayer.team == TeamNames.Team2) {
      gamePlayer.team = TeamNames.Team1;
    }
  }

  public getTeamName(num: number) {
    if (num == 1) {
      return TeamNames.Team1;
    }
    return TeamNames.Team2;
  }

  public getTeamStrength(team: number): number {
    let cnt: number = 0;
    this.gamePlayer.forEach(element => {
      let teamName = +team == 1 ? TeamNames.Team1 : TeamNames.Team2;
      if (element.team == teamName) {
        cnt += +element.player_strength;
      }
    });
    return cnt;
  }

  //handles a click on a list element
  public onListClick(listElement: GamePlayer) {
    this.selection.toggle(listElement);
  }

  public isSelected(player: GamePlayer) {
    if (player === undefined || this.selection === undefined || this.selection.selected === undefined) {
      return false;
    }
    if (this.selection.selected.indexOf(player) >= 0) {
      return true;
    }
    return false;
  }

  public getSelected(): GamePlayer {
    return this.selection.selected[0];
  }

  public getColorForTeamStrength(): string {
    var team1 = this.getTeamStrength(1);
    var team2 = this.getTeamStrength(2);
    var ratio = team1 / team2;

    if (ratio > 1.3 || ratio < 0.7) {
      return "red";
    } else {
      return "black";
    }
  }

  public isAdmin(): boolean {
    return this.commonFunctions.isAdmin();
  }
}
