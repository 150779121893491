<h1 mat-dialog-title>{{ title }}</h1>
<hr />

<form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="setUserData()">
  <div mat-dialog-content>
    <!--Suchfeld-->
    <mat-form-field class="set-user-data-element" *ngIf="showUserSearch && isAdmin()">
      <mat-select [formControl]="userSelectionCtrl" placeholder="User auswählen" (change)="onChangeUser(userSelectionCtrl.value)" #singleSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let user of (filteredUsers | async)" [value]="user">
          {{ user.userName }} ({{ user.firstName }} {{ user.lastName }})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--username-->
    <mat-form-field class="set-user-data-element">
      <input matInput type="text" placeholder="Username" [formControl]="userNameFormControl" [(ngModel)]="userName" minlength="3" maxlength="20" />
      <button mat-button *ngIf="userName" matSuffix mat-icon-button aria-label="Clear" (click)="userName = ''"><mat-icon>close</mat-icon></button>
    </mat-form-field>

    <!--firstName-->
    <mat-form-field class="set-user-data-element">
      <input matInput type="text" placeholder="Vorname" [formControl]="firstNameFormControl" [(ngModel)]="firstName" minlength="3" maxlength="20" />
      <button mat-button *ngIf="firstName" matSuffix mat-icon-button aria-label="Clear" (click)="firstName = ''"><mat-icon>close</mat-icon></button>
    </mat-form-field>

    <!--lastName-->
    <mat-form-field class="set-user-data-element">
      <input matInput type="text" placeholder="Nachname" [formControl]="lastNameFormControl" [(ngModel)]="lastName" minlength="3" maxlength="20" />
      <button mat-button *ngIf="lastName" matSuffix mat-icon-button aria-label="Clear" (click)="lastName = ''"><mat-icon>close</mat-icon></button>
    </mat-form-field>

    <!--Email-->
    <mat-form-field class="set-user-data-element">
      <input matInput type="email" placeholder="E-Mail" [(ngModel)]="email" [formControl]="emailFormControl" minlength="3" maxlength="50" required />
      <button mat-button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="email = ''"><mat-icon>close</mat-icon></button>
    </mat-form-field>

    <!--playerStrength-->
    <mat-form-field class="set-user-data-element" *ngIf="isAdmin()">
      <input matInput type="number" placeholder="Spielerstärke (1-10)" [formControl]="playerStrengthFormControl" [(ngModel)]="playerStrength" />
    </mat-form-field>

    <!-- MobilePhoneNumber-->
    <mat-form-field class="set-user-data-element">
      <span matPrefix>+49 &nbsp;</span>
      <input
        type="tel"
        matInput
        (keypress)="numberOnly($event)"
        placeholder="Handynummer"
        [formControl]="mobilePhoneNumberFormControl"
        [(ngModel)]="mobilePhoneNumber"
        minlength="10"
        maxlength="12"
      />
      <mat-icon matSuffix>phone</mat-icon>
      <mat-error *ngIf="mobilePhoneNumberFormControl.invalid">Nummer hat nicht die richtige Länge</mat-error>
    </mat-form-field>

    <!--user group-->
    <mat-form-field class="set-user-data-element" *ngIf="isAdmin()">
      <mat-select [(value)]="userGroup" [formControl]="userGroupFormControl">
        <mat-option value="user">User</mat-option>
        <mat-option value="admin">Admin</mat-option>
      </mat-select>
    </mat-form-field>

    <!--checkbox receive mails-->
    <mat-checkbox class="set-user-data-element" [(ngModel)]="checkboxReceiveMails" [formControl]="checkboxReceiveMailsFormControl"
      >E-Mails empfangen</mat-checkbox
    >

    <!--checkbox receive push-->
    <mat-checkbox class="set-user-data-element" [(ngModel)]="checkboxReceivePush" [formControl]="checkboxReceivePushFormControl"
      >Push-Nachrichten empfangen</mat-checkbox
    >
  </div>
  <hr />

  <div mat-dialog-actions>
    <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">Abbrechen</button>
    <button mat-raised-button color="primary" style="width: 47%;" type="submit" [disabled]="!userSelected">
      Setzen
    </button>
  </div>
</form>
