import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ServerCommunication } from "../../shared/server-communication.service";
import {
  ResponseTypeError,
  DefinitionsService,
  PayPalProductionKey,
} from "../../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonFunctions } from "../../shared/common-functions.service";

export interface PaymentDialogData {
  result: number;
  gamePlayerId: number;
  moneyPoolLink: string;
}

declare let paypal: any;

@Component({
  selector: "pay-dialog",
  templateUrl: "pay-dialog.html",
  styleUrls: ["./pay-dialog.css"],
})
export class PayDialog {
  public paypalLoad: boolean = true;
  public finalAmount: number = 6;
  private paypalScriptName: string = "paypalscript";
  private gamePlayerId: number = -1;
  public moneyPoolLink: string = "";
  public payPalKey: string = PayPalProductionKey;

  //https://developer.paypal.com/developer/accounts?event=createSuccess
  //https://developer.paypal.com/demo/checkout/#/pattern/client
  //http://tphangout.com/angular-5-paypal-express-checkout/

  constructor(
    public dialogRef: MatDialogRef<PayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentDialogData,
    private serverCommunication: ServerCommunication,
    private spinnerService: Ng4LoadingSpinnerService,
    private commonFunctions: CommonFunctions
  ) {
    console.log("Start 'pay' dialog");

    if (
      data.gamePlayerId === null ||
      data.gamePlayerId === -1 ||
      data.gamePlayerId === undefined
    ) {
      this.commonFunctions.showErrorMessage("Keine Spieler-Id übergeben");
      this.data.result = 1;
      this.dialogRef.close(this.data);
    } else {
      this.gamePlayerId = data.gamePlayerId;
      this.moneyPoolLink = data.moneyPoolLink;
    }
  }

  ngOnInit() {
    this.addPaypalScript().then(() => {
      paypal.Button.render(this.paypalConfig, "#paypal-checkout-btn");
      this.paypalLoad = false;
    });
  }

  //Paypal configuration
  paypalConfig = {
    env: "sandbox",
    client: {
      sandbox:
        "AbNx9MXwJyxtZCUYd6RRuSNT_rDMhalyRGWHfOpoyIREGpBZTjpqRtuHdFNHxjscHmQxBp56JM0yK5mt",
      production: PayPalProductionKey,
    },
    style: {
      size: "responsive",
      color: "blue",
      shape: "rect",
      label: "buynow",
      layout: "vertical",
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            { amount: { total: this.finalAmount, currency: "EUR" } },
          ],
        },
      });
    },
    onAuthorize: (data, actions) => {
      this.spinnerService.show();
      return actions.payment.execute().then((payment) => {
        //Do something when payment is successful.
        console.log("Payment done !");

        if (payment.state == "approved") {
          //send to server
          this.serverCommunication.setAsPaid(this.gamePlayerId).subscribe(
            (data) => {
              if (data.result === ResponseTypeError) {
                this.commonFunctions.showErrorMessage(data.message);
              } else {
                this.data.result = 1;
                this.dialogRef.close(this.data);
              }
              this.spinnerService.hide();
            },
            (error: any) => {
              this.commonFunctions.showErrorMessage(
                "Zahlungsübermittlung an Server fehlgeschlagen"
              );
              this.spinnerService.hide();
            }
          );
        } else {
          this.commonFunctions.showErrorMessage(
            "Zahlung wurde nicht anzeptiert"
          );
        }
      });
    },
  };

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onMoneyPoolClick(): void {
    //window.location.href = "https://paypal.me/pools/c/8pr87hMYLg";
    window.location.href = this.moneyPoolLink;
  }

  /**
   * Adds the PayPal Script tag to the DOM
   */
  private addPaypalScript() {
    return new Promise((resolve, reject) => {
      var me = document.getElementById(this.paypalScriptName);

      if (me == null) {
        let scripttagElement = document.createElement("script");
        scripttagElement.src = "https://www.paypalobjects.com/api/checkout.js";
        scripttagElement.id = this.paypalScriptName;
        scripttagElement.onload = resolve;
        document.body.appendChild(scripttagElement);
      } else {
        resolve();
      }
    });
  }
}
