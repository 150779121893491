import { MatDialogRef } from "@angular/material";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, TeamNames, GamePlayer } from "../../shared/definitions.service";
import { SelectionModel } from "@angular/cdk/collections";
var ShowTeamsDialog = /** @class */ (function () {
    function ShowTeamsDialog(dialogRef, dialogData, serverCommunication, commonFunctions, spinnerService) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.title = "Teamverteilung";
        this.selection = new SelectionModel(false, []);
        console.log("Starting 'show teams' dialog");
        this.gamePlayer = dialogData.gamePlayer;
    }
    ShowTeamsDialog.prototype.ngOnInit = function () { };
    ShowTeamsDialog.prototype.ngAfterViewInit = function () { };
    ShowTeamsDialog.prototype.onNoClick = function () {
        this.dialogData.result = 0;
        this.dialogRef.close(this.dialogData);
    };
    ShowTeamsDialog.prototype.saveData = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.manualSetTeams(this.gamePlayer).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.dialogData.result = 1;
                _this.dialogRef.close(_this.dialogData);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Swaps a player to the other team
     *
     */
    ShowTeamsDialog.prototype.swap = function () {
        var gamePlayer = this.getSelected();
        if (gamePlayer == null || gamePlayer == undefined) {
            return;
        }
        if (gamePlayer.team == TeamNames.Team1) {
            gamePlayer.team = TeamNames.Team2;
        }
        else if (gamePlayer.team == TeamNames.Team2) {
            gamePlayer.team = TeamNames.Team1;
        }
    };
    ShowTeamsDialog.prototype.getTeamName = function (num) {
        if (num == 1) {
            return TeamNames.Team1;
        }
        return TeamNames.Team2;
    };
    ShowTeamsDialog.prototype.getTeamStrength = function (team) {
        var cnt = 0;
        this.gamePlayer.forEach(function (element) {
            var teamName = +team == 1 ? TeamNames.Team1 : TeamNames.Team2;
            if (element.team == teamName) {
                cnt += +element.player_strength;
            }
        });
        return cnt;
    };
    //handles a click on a list element
    ShowTeamsDialog.prototype.onListClick = function (listElement) {
        this.selection.toggle(listElement);
    };
    ShowTeamsDialog.prototype.isSelected = function (player) {
        if (player === undefined || this.selection === undefined || this.selection.selected === undefined) {
            return false;
        }
        if (this.selection.selected.indexOf(player) >= 0) {
            return true;
        }
        return false;
    };
    ShowTeamsDialog.prototype.getSelected = function () {
        return this.selection.selected[0];
    };
    ShowTeamsDialog.prototype.getColorForTeamStrength = function () {
        var team1 = this.getTeamStrength(1);
        var team2 = this.getTeamStrength(2);
        var ratio = team1 / team2;
        if (ratio > 1.3 || ratio < 0.7) {
            return "red";
        }
        else {
            return "black";
        }
    };
    ShowTeamsDialog.prototype.isAdmin = function () {
        return this.commonFunctions.isAdmin();
    };
    return ShowTeamsDialog;
}());
export { ShowTeamsDialog };
