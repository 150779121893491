import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

export enum NotificationType {
  Error,
  Success,
  Warn
}

export interface NotificationDialogData {
  text: string;
  duration: number;
}

@Component({
  selector: "notification",
  templateUrl: "notification.html"
})
export class NotificationDialog {
  public text: string = "";

  constructor(public dialogRef: MatDialogRef<NotificationDialog>, @Inject(MAT_DIALOG_DATA) public data: NotificationDialogData) {
    var duration = data.duration;
    this.text = data.text;

    if (duration == undefined || duration == null) {
      duration = 4000; //default
    }

    setTimeout(() => {
      this.dialogRef.close();
    }, duration);
  }
}
