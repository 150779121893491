<div
  style="
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -130px;
    margin-top: -150px;
    text-align: center;
  "
>
  <div *ngIf="noConnection">
    <img src="../../assets/no_connection.png" alt="No Connection" />
    <h3>Keine Verbindung</h3>
    <button mat-icon-button (click)="loadData()" style="margin-top: 10px;">
      <mat-icon aria-label="Update">refresh</mat-icon>
    </button>
  </div>
  <div
    *ngIf="
      !loadingData && gamePlayerData.length == 0 && !noConnection && !noOpenGame
    "
  >
    <h3>Noch kein Spieler beigetreten</h3>
  </div>
  <div
    *ngIf="!loadingData && !noConnection && noOpenGame"
    style="text-align: center;"
  >
    <button
      mat-raised-button
      color="primary"
      style="width: 100%; margin-top: 30px;"
      type="button"
      (click)="openCreateNewGameDialog()"
    >
      Neues Spiel anlegen
    </button>
  </div>
  <div *ngIf="loadingData" style="margin-left: 30px;">
    <h3>Lade Daten...</h3>
  </div>
</div>

<div
  *ngIf="
    isGameFinished() &&
    !loadingData &&
    !noConnection &&
    gamePlayerData.length != 0
  "
  style="
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -90px;
    margin-top: -100px;
    text-align: center;
    z-index: 100;
  "
>
  <h3>Spiel beendet</h3>
</div>

<div *ngIf="!loadingData && gamePlayerData.length > 0 && !noConnection">
  <mat-expansion-panel
    class="expansion-panel"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title style="text-align: center; flex-grow: 2;">
      </mat-panel-title>
      <mat-panel-description class="headline">
        <h6 class="headline">
          <strong>{{ gameData.game_number }}.</strong> Spiel am
          <strong>{{ gameData.date_time * 1000 | date: "d. MMMM" }}</strong>
        </h6>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="headline-sub">
      <p style="color: black; margin: 10px;">
        Platz: {{ gameData.game_place }}
      </p>
      <p style="color: black; margin: 10px;">
        Uhrzeit: {{ gameData.date_time * 1000 | date: "HH:mm" }} Uhr
      </p>
    </div>
  </mat-expansion-panel>

  <div class="flex-container" class="table-data">
    <table
      style="
        width: -webkit-fill-available;
        background-color: white;
        table-layout: fixed;
        margin-bottom: 80px;
      "
      class="mat-elevation-z8"
      [ngClass]="isGameFinished() ? 'game-outdated' : ''"
    >
      <thead>
        <tr>
          <th class="th-header-place">Platz</th>
          <th class="th-header-name">Name</th>
          <th class="th-header-operations" *ngIf="isAdmin()">Operationen</th>
        </tr>
      </thead>

      <tr
        *ngFor="
          let f of gamePlayerData as resulting;
          index as i;
          let even = even;
          let odd = odd
        "
        (click)="onListClick(f)"
        [class.active]="isSelected(f)"
        [ngClass]="playerIsInGame(f) ? '' : 'not-in-game'"
      >
        <!-- <td *ngIf="isAdmin()" style="border: 1px solid #dddddd;padding: 8px;text-align: center;padding-top: 15px;">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="onListClick(f)" [checked]="selection.isSelected(f)"> </mat-checkbox>
        </td> -->
        <td class="th-header-place">
          <p [ngClass]="playerIsInGame(f) ? '' : 'not-in-game-place'">
            {{ f.list_place }}
          </p>
        </td>
        <td class="th-header-name">
          <table style="width: -webkit-fill-available; table-layout: fixed;">
            <tr>
              <td class="td-inner-name">
                <p
                  [ngClass]="
                    playerIsThisUser(f.user_id)
                      ? 'this-player-text'
                      : 'not-this-player-text'
                  "
                >
                  {{ f.first_name }} {{ f.last_name }}
                </p>
              </td>
              <td class="td-inner-signs">
                <p
                  *ngIf="f.paid == 1"
                  [ngClass]="
                    playerIsThisUser(f.user_id)
                      ? 'this-player-text'
                      : 'not-this-player-text'
                  "
                >
                  <!--Custom svg icons werden in der app component registriert-->
                  <!-- <button mat-icon-button>
                    <mat-icon
                      class="mat-icon"
                      svgIcon="paypal_icon"
                      role="img"
                      aria-hidden="true"
                      style="margin-bottom: 10px;"
                    ></mat-icon>
                  </button> -->
                  <button mat-icon-button>
                    <mat-icon aria-label="Bezahlt">euro_symbol</mat-icon>
                  </button>
                </p>
              </td>
            </tr>
          </table>
        </td>
        <td *ngIf="isAdmin()" class="th-header-operations">
          <button mat-icon-button (click)="setPaid(f.id)">
            <mat-icon aria-label="Bezahlt setzen">euro_symbol</mat-icon>
          </button>
          <button mat-icon-button (click)="askToRemovePlayerFromList(f.id)">
            <mat-icon aria-label="Spieler enfernen">delete</mat-icon>
          </button>
          <button mat-icon-button (click)="openDialogChangeUserData(f)">
            <mat-icon aria-label="Spieler bearbeiten">build</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>

  <!--Speed Dial-->
  <eco-fab-speed-dial
    *ngIf="isGamePresent()"
    [(open)]="fabOpen"
    [direction]="fabDirection"
    [animationMode]="fling"
    [fixed]="false"
    class="fixedbutton"
  >
    <eco-fab-speed-dial-trigger>
      <button mat-fab color="primary"><mat-icon>menu</mat-icon></button>
    </eco-fab-speed-dial-trigger>

    <eco-fab-speed-dial-actions>
      <button
        mat-mini-fab
        color="warn"
        *ngIf="isAdmin() && isGamePresent() && !isGameFinished()"
        (click)="closeGame()"
      >
        <mat-icon aria-label="Spiel beenden">outlined_flag</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        *ngIf="isAdmin() && isGamePresent() && !isGameFinished()"
        (click)="openDialogAddGameResult()"
      >
        <mat-icon aria-label="Spielergebnis eintragen">assignment</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        *ngIf="isGamePresent()"
        (click)="openDialogShowTeams()"
      >
        <mat-icon aria-label="Teams anzeigen">group</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        *ngIf="isAdmin() && isGamePresent() && !isGameFinished()"
        (click)="automaticTeamGeneration()"
      >
        <mat-icon aria-label="Automatische Teamverteilung">star</mat-icon>
      </button>
    </eco-fab-speed-dial-actions>
  </eco-fab-speed-dial>
</div>
