import { MatDialogRef } from "@angular/material";
import { ServerCommunication } from "../../shared/server-communication.service";
import { ResponseTypeError, PayPalProductionKey, } from "../../shared/definitions.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonFunctions } from "../../shared/common-functions.service";
var PayDialog = /** @class */ (function () {
    //https://developer.paypal.com/developer/accounts?event=createSuccess
    //https://developer.paypal.com/demo/checkout/#/pattern/client
    //http://tphangout.com/angular-5-paypal-express-checkout/
    function PayDialog(dialogRef, data, serverCommunication, spinnerService, commonFunctions) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.data = data;
        this.serverCommunication = serverCommunication;
        this.spinnerService = spinnerService;
        this.commonFunctions = commonFunctions;
        this.paypalLoad = true;
        this.finalAmount = 6;
        this.paypalScriptName = "paypalscript";
        this.gamePlayerId = -1;
        this.moneyPoolLink = "";
        this.payPalKey = PayPalProductionKey;
        //Paypal configuration
        this.paypalConfig = {
            env: "sandbox",
            client: {
                sandbox: "AbNx9MXwJyxtZCUYd6RRuSNT_rDMhalyRGWHfOpoyIREGpBZTjpqRtuHdFNHxjscHmQxBp56JM0yK5mt",
                production: PayPalProductionKey,
            },
            style: {
                size: "responsive",
                color: "blue",
                shape: "rect",
                label: "buynow",
                layout: "vertical",
            },
            commit: true,
            payment: function (data, actions) {
                return actions.payment.create({
                    payment: {
                        transactions: [
                            { amount: { total: _this.finalAmount, currency: "EUR" } },
                        ],
                    },
                });
            },
            onAuthorize: function (data, actions) {
                _this.spinnerService.show();
                return actions.payment.execute().then(function (payment) {
                    //Do something when payment is successful.
                    console.log("Payment done !");
                    if (payment.state == "approved") {
                        //send to server
                        _this.serverCommunication.setAsPaid(_this.gamePlayerId).subscribe(function (data) {
                            if (data.result === ResponseTypeError) {
                                _this.commonFunctions.showErrorMessage(data.message);
                            }
                            else {
                                _this.data.result = 1;
                                _this.dialogRef.close(_this.data);
                            }
                            _this.spinnerService.hide();
                        }, function (error) {
                            _this.commonFunctions.showErrorMessage("Zahlungsübermittlung an Server fehlgeschlagen");
                            _this.spinnerService.hide();
                        });
                    }
                    else {
                        _this.commonFunctions.showErrorMessage("Zahlung wurde nicht anzeptiert");
                    }
                });
            },
        };
        console.log("Start 'pay' dialog");
        if (data.gamePlayerId === null ||
            data.gamePlayerId === -1 ||
            data.gamePlayerId === undefined) {
            this.commonFunctions.showErrorMessage("Keine Spieler-Id übergeben");
            this.data.result = 1;
            this.dialogRef.close(this.data);
        }
        else {
            this.gamePlayerId = data.gamePlayerId;
            this.moneyPoolLink = data.moneyPoolLink;
        }
    }
    PayDialog.prototype.ngOnInit = function () {
        var _this = this;
        this.addPaypalScript().then(function () {
            paypal.Button.render(_this.paypalConfig, "#paypal-checkout-btn");
            _this.paypalLoad = false;
        });
    };
    PayDialog.prototype.onNoClick = function () {
        this.data.result = 0;
        this.dialogRef.close(this.data);
    };
    PayDialog.prototype.onMoneyPoolClick = function () {
        //window.location.href = "https://paypal.me/pools/c/8pr87hMYLg";
        window.location.href = this.moneyPoolLink;
    };
    /**
     * Adds the PayPal Script tag to the DOM
     */
    PayDialog.prototype.addPaypalScript = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var me = document.getElementById(_this.paypalScriptName);
            if (me == null) {
                var scripttagElement = document.createElement("script");
                scripttagElement.src = "https://www.paypalobjects.com/api/checkout.js";
                scripttagElement.id = _this.paypalScriptName;
                scripttagElement.onload = resolve;
                document.body.appendChild(scripttagElement);
            }
            else {
                resolve();
            }
        });
    };
    return PayDialog;
}());
export { PayDialog };
