<div *ngIf="!hideNotification" style="text-align: center;margin-top: 200px;">
  <h3>Keine Daten angegeben</h3>
</div>

<div *ngIf="hideNotification" class="flex-container form-content">
  <form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="setNewPassword(formDirective)">
    <!-- Password 1 -->
    <mat-form-field class="input-field-large">
      <input
        matInput
        type="password"
        [formControl]="password1FormControl"
        placeholder="Neues Passwort eingeben"
        [(ngModel)]="password1"
        minlength="3"
        maxlength="50"
        required
        cdkFocusInitial
      />
      <mat-error *ngIf="password1FormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
    </mat-form-field>

    <!-- Password2 -->
    <mat-form-field class="input-field-large">
      <input
        matInput
        type="password"
        [formControl]="password2FormControl"
        placeholder="Passwort wiederholen"
        [(ngModel)]="password2"
        minlength="3"
        maxlength="50"
        required
        cdkFocusInitial
      />
      <mat-error *ngIf="password2FormControl.invalid">Bitte mehr als 3 Zeichen angeben</mat-error>
    </mat-form-field>
    <mat-error *ngIf="formGroup.valid && password1FormControl.value != password2FormControl.value">Passwörter müssen übereinstimmen</mat-error>

    <button
      mat-raised-button
      color="primary"
      style="width: 70%;margin-top: 20px;height: 40px;"
      type="submit"
      [disabled]="!formGroup.valid || password1FormControl.value != password2FormControl.value"
      cdkFocusInitial
    >
      Passwort neu setzen
    </button>
  </form>
</div>
