<h1 mat-dialog-title>{{ title }}</h1>
<hr />

<div mat-dialog-content></div>

<table style="width: -webkit-fill-available;background-color: white;">
  <thead>
    <tr style="text-align: center;border: 1px solid #dddddd">
      <th>{{ getTeamName(1) }}</th>
      <th>{{ getTeamName(2) }}</th>
    </tr>
  </thead>

  <tr>
    <td style="width: 50%;vertical-align: top;">
      <table style="width: -webkit-fill-available;background-color: white;">
        <tr *ngFor="let f of gamePlayer as resulting; index as i" style="text-align: center;" (click)="onListClick(f)" [class.active]="isSelected(f)">
          <td *ngIf="isAdmin() && f.team == getTeamName(1)" style="border: 1px solid #dddddd;padding-top: 8px;">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="onListClick(f)" [checked]="selection.isSelected(f)"> </mat-checkbox>
          </td>
          <td style="border: 1px solid #dddddd;height: 40px;" *ngIf="f.team == getTeamName(1)">
            {{ f.first_name }}
          </td>
        </tr>
      </table>
    </td>
    <td style="width: 50%;vertical-align: top;">
      <table style="width: -webkit-fill-available;background-color: white;">
        <tr *ngFor="let f of gamePlayer as resulting; index as i" style="text-align: center;" (click)="onListClick(f)" [class.active]="isSelected(f)">
          <td *ngIf="isAdmin() && f.team == getTeamName(2)" style="border: 1px solid #dddddd;padding-top: 8px;">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="onListClick(f)" [checked]="selection.isSelected(f)"> </mat-checkbox>
          </td>
          <td style="border: 1px solid #dddddd;height: 40px;" *ngIf="f.team == getTeamName(2)">
            {{ f.first_name }}
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<div style="text-align: center" *ngIf="isAdmin()">
  <button mat-raised-button color="primary" style="width: 47%;margin-top: 10px;" [disabled]="getSelected() == undefined" (click)="swap()">
    <mat-icon aria-label="Swap">swap_horiz</mat-icon>
  </button>
</div>

<hr />
<!--Team Strength-->
<div style="text-align: center;">
  <h5 style="margin-bottom: 10px;">Team-Stärken</h5>
  <table style="width: -webkit-fill-available;background-color: white;">
    <thead>
      <tr>
        <th style="width: 45%;" [ngStyle]="{ color: getColorForTeamStrength() }">{{ getTeamStrength(1) }}</th>
        <th style="width: 10%;"></th>
        <th style="width: 45%;" [ngStyle]="{ color: getColorForTeamStrength() }">{{ getTeamStrength(2) }}</th>
      </tr>
    </thead>
  </table>
</div>

<hr />

<div mat-dialog-actions style="text-align: center;">
  <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()" *ngIf="isAdmin()">Abbrechen</button>
  <button mat-raised-button color="primary" style="width: 47%;" (click)="saveData()" *ngIf="isAdmin()">
    Speichern
  </button>
  <button mat-raised-button color="primary" style="width: 100%" (click)="onNoClick()" *ngIf="!isAdmin()">Schließen</button>
</div>
