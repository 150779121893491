<h1 mat-dialog-title>{{ title }}</h1>
<hr />

<form [formGroup]="formGroup" #formDirective="ngForm" (ngSubmit)="setData()">
  <div mat-dialog-content>
    <!--Suchfeld-->
    <mat-form-field class="set-user-data-element" *ngIf="showUserSearch">
      <mat-select [formControl]="userSelectionCtrl" placeholder="User auswählen" (change)="onChangeUser(userSelectionCtrl.value)" #singleSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let user of (filteredUsers | async)" [value]="user">
          {{ user.userName }} ({{ user.firstName }} {{ user.lastName }})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      class="set-user-data-element"
      [(ngModel)]="checkBoxBlockUser"
      [formControl]="blockUserFormControl"
      (change)="checkedChangeBlockUser(checkBoxBlockUser)"
      >User blockieren</mat-checkbox
    >

    <!--Block until-->
    <mat-form-field class="set-user-data-element">
      <input
        matInput
        [min]="minDateNow"
        type="datetime"
        placeholder="Dauer der Blockade"
        [formControl]="blockUntilFormControl"
        [(ngModel)]="blockDateTime"
        [owlDateTimeTrigger]="dtStart"
        [owlDateTime]="dtStart"
      />
      <owl-date-time #dtStart></owl-date-time>
    </mat-form-field>
  </div>
  <hr />

  <div mat-dialog-actions>
    <button mat-raised-button type="reset" style="width: 47%" (click)="onNoClick()">Abbrechen</button>
    <button mat-raised-button color="primary" style="width: 47%;" type="submit" [disabled]="selectedUser == undefined">
      Setzen
    </button>
  </div>
</form>
