import { MatDialogRef, MatSelect } from "@angular/material";
import { FormControl, FormGroup } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ChangeUserDataIntention, UserData } from "../../shared/definitions.service";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
var SetUserDataDialog = /** @class */ (function () {
    //---------------------------------
    function SetUserDataDialog(dialogRef, dialogData, serverCommunication, commonFunctions, spinnerService) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.serverCommunication = serverCommunication;
        this.commonFunctions = commonFunctions;
        this.spinnerService = spinnerService;
        this.title = "Userdaten anpassen";
        this.userGroup = "user";
        this.checkboxReceiveMails = false;
        this.checkboxReceivePush = false;
        this.showUserSearch = false;
        this.userSelected = false;
        this.userGroupFormControl = new FormControl();
        this.userNameFormControl = new FormControl();
        this.firstNameFormControl = new FormControl();
        this.lastNameFormControl = new FormControl();
        this.emailFormControl = new FormControl();
        this.playerStrengthFormControl = new FormControl();
        this.mobilePhoneNumberFormControl = new FormControl();
        this.checkboxReceiveMailsFormControl = new FormControl();
        this.checkboxReceivePushFormControl = new FormControl();
        this.userSelectionCtrl = new FormControl();
        this.userFilterCtrl = new FormControl();
        //-------------------------------
        /** list of users */
        this.usersList = [];
        /** list of users filtered by search keyword */
        this.filteredUsers = new ReplaySubject(1);
        /** Subject that emits when the component has been destroyed. */
        this._onDestroy = new Subject();
        console.log("Starting 'set user data dialog'");
        //set up form group
        this.formGroup = new FormGroup({
            userGroupFormControl: this.userGroupFormControl,
            userNameFormControl: this.userNameFormControl,
            firstNameFormControl: this.firstNameFormControl,
            lastNameFormControl: this.lastNameFormControl,
            emailFormControl: this.emailFormControl,
            playerStrengthFormControl: this.playerStrengthFormControl,
            mobilePhoneNumberFormControl: this.mobilePhoneNumberFormControl,
            checkboxReceiveMailsFormControl: this.checkboxReceiveMailsFormControl,
            checkboxReceivePushFormControl: this.checkboxReceivePushFormControl,
            userSelectionCtrl: this.userSelectionCtrl,
            userFilterCtrl: this.userFilterCtrl
        });
        //je nach intention, sachen ein/ausblenden
        if (this.dialogData.intention == ChangeUserDataIntention.ChangeAll) {
            this.title = "Userdaten anpassen";
        }
        else if (this.dialogData.intention == ChangeUserDataIntention.UserSettings) {
            this.title = "Einstellungen";
        }
        //show user selection
        if (dialogData.gamePlayerData === null && dialogData.userData === null) {
            this.showUserSearch = true;
            this.loadAllUsers();
        }
        else if (dialogData.gamePlayerData != null) {
            //load user
            this.loadUser(dialogData.gamePlayerData.user_id);
        }
        else if (dialogData.userData != null) {
            this.selectedUser = dialogData.userData;
            //assign old data to fields
            this.setDataToControls(dialogData.userData);
        }
        else {
            commonFunctions.showErrorMessage("Keine Daten übergeben");
        }
    }
    SetUserDataDialog.prototype.ngOnInit = function () {
        var _this = this;
        if (this.showUserSearch) {
            // load the initial users list
            this.filteredUsers.next(this.usersList.slice());
            // listen for search field value changes
            this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(function () {
                _this.filterUser();
            });
        }
    };
    SetUserDataDialog.prototype.ngAfterViewInit = function () {
        if (this.showUserSearch) {
            this.setInitialValue();
        }
    };
    SetUserDataDialog.prototype.onNoClick = function () {
        this.dialogData.result = 0;
        this.dialogRef.close(this.dialogData);
    };
    SetUserDataDialog.prototype.onYesClick = function (formDirective) {
        this.formGroupDirective = formDirective;
        this.dialogData.result = 1;
        this.dialogRef.close(this.dialogData);
    };
    SetUserDataDialog.prototype.isAdmin = function () {
        return this.commonFunctions.isAdmin();
    };
    SetUserDataDialog.prototype.setUserData = function () {
        var _this = this;
        this.spinnerService.show();
        var userGroup = this.dialogData.intention == ChangeUserDataIntention.ChangeAll ? this.userGroupFormControl.value : null;
        var userName = this.userNameFormControl.value;
        var firstName = this.firstNameFormControl.value;
        var lastName = this.lastNameFormControl.value;
        var email = this.emailFormControl.value;
        var playerStrength = this.dialogData.intention == ChangeUserDataIntention.ChangeAll ? this.playerStrengthFormControl.value : null;
        var mobilePhone = this.mobilePhoneNumberFormControl.value;
        var receiveMails = this.checkboxReceiveMailsFormControl.value ? 1 : 0;
        var receivePush = this.checkboxReceivePushFormControl.value ? 1 : 0;
        this.serverCommunication
            .setUserData(this.selectedUser.getId(), userName, firstName, lastName, playerStrength, mobilePhone, email, null, null, userGroup, receiveMails, receivePush)
            .subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.dialogData.result = 1;
                _this.dialogRef.close(_this.dialogData);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
        });
    };
    /**
     * Ensures that only numbers are entered
     *
     * @param event
     */
    SetUserDataDialog.prototype.numberOnly = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        //check if first char is '0' and dont accept it
        if ((this.mobilePhoneNumberFormControl.value == undefined || this.mobilePhoneNumberFormControl.value == "") && charCode == 48) {
            return false;
        }
        return true;
    };
    //Raised when selection of user is changed
    SetUserDataDialog.prototype.onChangeUser = function (user) {
        this.selectedUser = user;
        //daten in die felder setzen
        this.setDataToControls(user);
    };
    SetUserDataDialog.prototype.loadAllUsers = function () {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getAllUsers().subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                _this.usersList = UserData.convertArray(data.message);
                _this.filteredUsers.next(_this.usersList);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
            _this.dialogRef.close(_this.dialogData);
        });
    };
    SetUserDataDialog.prototype.loadUser = function (id) {
        var _this = this;
        this.spinnerService.show();
        this.serverCommunication.getUser(id).subscribe(function (data) {
            // check if error
            if (data.result === ResponseTypeError) {
                _this.commonFunctions.showErrorMessage(data.message);
            }
            else {
                var userData = UserData.convert(data.message);
                _this.selectedUser = userData;
                //assign old data to fields
                _this.setDataToControls(userData);
            }
            _this.spinnerService.hide();
        }, function (error) {
            _this.commonFunctions.showErrorMessage(error);
            _this.spinnerService.hide();
            _this.dialogRef.close(_this.dialogData);
        });
    };
    /**
     * Sets the initial value after the filteredBanks are loaded initially
     */
    SetUserDataDialog.prototype.setInitialValue = function () {
        var _this = this;
        this.filteredUsers
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(function () {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            _this.singleSelect.compareWith = function (a, b) { return a && b && a.id === b.id; };
        });
    };
    /**
     * Filters the user selection
     */
    SetUserDataDialog.prototype.filterUser = function () {
        if (!this.usersList) {
            return;
        }
        // get the search keyword
        var search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredUsers.next(this.usersList.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the users
        this.filteredUsers.next(this.usersList.filter(function (user) {
            return user.firstName.toLowerCase().indexOf(search) > -1 ||
                user.lastName.toLowerCase().indexOf(search) > -1 ||
                user.userName.toLowerCase().indexOf(search) > -1;
        }));
    };
    SetUserDataDialog.prototype.setDataToControls = function (userData) {
        this.userGroupFormControl.setValue(userData.userGroup);
        this.userNameFormControl.setValue(userData.userName);
        this.firstNameFormControl.setValue(userData.firstName);
        this.lastNameFormControl.setValue(userData.lastName);
        this.emailFormControl.setValue(userData.email);
        this.playerStrengthFormControl.setValue(userData.playerStrength);
        this.mobilePhoneNumberFormControl.setValue(userData.mobilePhoneNumber);
        this.checkboxReceiveMailsFormControl.setValue(+userData.receiveMails);
        this.checkboxReceivePushFormControl.setValue(+userData.receivePush);
        this.userSelected = true;
    };
    return SetUserDataDialog;
}());
export { SetUserDataDialog };
