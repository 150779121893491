import { Component, Inject, ElementRef, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatSelect } from "@angular/material";
import { FormControl, FormGroup, FormGroupDirective } from "../../../../node_modules/@angular/forms";
import { CommonFunctions } from "../../shared/common-functions.service";
import { ServerCommunication } from "../../shared/server-communication.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ResponseTypeError, ChangeUserDataIntention, UserData, GamePlayer } from "../../shared/definitions.service";
import { _localeFactory } from "@angular/core/src/application_module";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

export interface ChangeUserDataDialogData {
  result: number;
  intention: ChangeUserDataIntention;
  gamePlayerData: GamePlayer;
  userData: UserData;
}

@Component({
  selector: "set-user-data",
  templateUrl: "set-user-data.html",
  styleUrls: ["./set-user-data.css"]
})
export class SetUserDataDialog {
  public title: string = "Userdaten anpassen";
  public userGroup: string = "user";
  public userName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public playerStrength: number;
  public mobilePhoneNumber: number;
  public checkboxReceiveMails: boolean = false;
  public checkboxReceivePush: boolean = false;
  public showUserSearch: boolean = false;
  public selectedUser: UserData;
  public userSelected: boolean = false;

  public formGroup: FormGroup;
  private formGroupDirective: FormGroupDirective;

  public userGroupFormControl = new FormControl();
  public userNameFormControl = new FormControl();
  public firstNameFormControl = new FormControl();
  public lastNameFormControl = new FormControl();
  public emailFormControl = new FormControl();
  public playerStrengthFormControl = new FormControl();
  public mobilePhoneNumberFormControl = new FormControl();
  public checkboxReceiveMailsFormControl = new FormControl();
  public checkboxReceivePushFormControl = new FormControl();
  public userSelectionCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();

  //-------------------------------
  /** list of users */
  protected usersList: UserData[] = [];

  /** list of users filtered by search keyword */
  public filteredUsers: ReplaySubject<UserData[]> = new ReplaySubject<UserData[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @ViewChild("singleSelect") singleSelect: MatSelect;

  //---------------------------------

  constructor(
    public dialogRef: MatDialogRef<SetUserDataDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ChangeUserDataDialogData,
    private serverCommunication: ServerCommunication,
    private commonFunctions: CommonFunctions,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
    console.log("Starting 'set user data dialog'");

    //set up form group
    this.formGroup = new FormGroup({
      userGroupFormControl: this.userGroupFormControl,
      userNameFormControl: this.userNameFormControl,
      firstNameFormControl: this.firstNameFormControl,
      lastNameFormControl: this.lastNameFormControl,
      emailFormControl: this.emailFormControl,
      playerStrengthFormControl: this.playerStrengthFormControl,
      mobilePhoneNumberFormControl: this.mobilePhoneNumberFormControl,
      checkboxReceiveMailsFormControl: this.checkboxReceiveMailsFormControl,
      checkboxReceivePushFormControl: this.checkboxReceivePushFormControl,
      userSelectionCtrl: this.userSelectionCtrl,
      userFilterCtrl: this.userFilterCtrl
    });

    //je nach intention, sachen ein/ausblenden
    if (this.dialogData.intention == ChangeUserDataIntention.ChangeAll) {
      this.title = "Userdaten anpassen";
    } else if (this.dialogData.intention == ChangeUserDataIntention.UserSettings) {
      this.title = "Einstellungen";
    }

    //show user selection
    if (dialogData.gamePlayerData === null && dialogData.userData === null) {
      this.showUserSearch = true;
      this.loadAllUsers();
    } else if (dialogData.gamePlayerData != null) {
      //load user
      this.loadUser(dialogData.gamePlayerData.user_id);
    } else if (dialogData.userData != null) {
      this.selectedUser = dialogData.userData;
      //assign old data to fields
      this.setDataToControls(dialogData.userData);
    } else {
      commonFunctions.showErrorMessage("Keine Daten übergeben");
    }
  }

  ngOnInit() {
    if (this.showUserSearch) {
      // load the initial users list
      this.filteredUsers.next(this.usersList.slice());

      // listen for search field value changes
      this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterUser();
      });
    }
  }

  ngAfterViewInit() {
    if (this.showUserSearch) {
      this.setInitialValue();
    }
  }

  onNoClick(): void {
    this.dialogData.result = 0;
    this.dialogRef.close(this.dialogData);
  }

  onYesClick(formDirective: FormGroupDirective): void {
    this.formGroupDirective = formDirective;
    this.dialogData.result = 1;
    this.dialogRef.close(this.dialogData);
  }

  public isAdmin(): boolean {
    return this.commonFunctions.isAdmin();
  }

  public setUserData() {
    this.spinnerService.show();

    let userGroup: string = this.dialogData.intention == ChangeUserDataIntention.ChangeAll ? this.userGroupFormControl.value : null;
    let userName: string = this.userNameFormControl.value;
    let firstName: string = this.firstNameFormControl.value;
    let lastName: string = this.lastNameFormControl.value;
    let email: string = this.emailFormControl.value;
    let playerStrength: number = this.dialogData.intention == ChangeUserDataIntention.ChangeAll ? this.playerStrengthFormControl.value : null;
    let mobilePhone: string = this.mobilePhoneNumberFormControl.value;
    let receiveMails: number = this.checkboxReceiveMailsFormControl.value ? 1 : 0;
    let receivePush: number = this.checkboxReceivePushFormControl.value ? 1 : 0;

    this.serverCommunication
      .setUserData(
        this.selectedUser.getId(),
        userName,
        firstName,
        lastName,
        playerStrength,
        mobilePhone,
        email,
        null,
        null,
        userGroup,
        receiveMails,
        receivePush
      )
      .subscribe(
        data => {
          // check if error
          if (data.result === ResponseTypeError) {
            this.commonFunctions.showErrorMessage(data.message);
          } else {
            this.dialogData.result = 1;
            this.dialogRef.close(this.dialogData);
          }
          this.spinnerService.hide();
        },
        error => {
          this.commonFunctions.showErrorMessage(error);
          this.spinnerService.hide();
        }
      );
  }

  /**
   * Ensures that only numbers are entered
   *
   * @param event
   */
  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //check if first char is '0' and dont accept it
    if ((this.mobilePhoneNumberFormControl.value == undefined || this.mobilePhoneNumberFormControl.value == "") && charCode == 48) {
      return false;
    }
    return true;
  }

  //Raised when selection of user is changed
  onChangeUser(user: UserData) {
    this.selectedUser = user;
    //daten in die felder setzen
    this.setDataToControls(user);
  }

  private loadAllUsers() {
    this.spinnerService.show();

    this.serverCommunication.getAllUsers().subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          this.usersList = UserData.convertArray(data.message);
          this.filteredUsers.next(this.usersList);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
        this.dialogRef.close(this.dialogData);
      }
    );
  }

  private loadUser(id: number) {
    this.spinnerService.show();

    this.serverCommunication.getUser(id).subscribe(
      data => {
        // check if error
        if (data.result === ResponseTypeError) {
          this.commonFunctions.showErrorMessage(data.message);
        } else {
          let userData: UserData = UserData.convert(data.message);
          this.selectedUser = userData;
          //assign old data to fields
          this.setDataToControls(userData);
        }
        this.spinnerService.hide();
      },
      error => {
        this.commonFunctions.showErrorMessage(error);
        this.spinnerService.hide();
        this.dialogRef.close(this.dialogData);
      }
    );
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredUsers
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  /**
   * Filters the user selection
   */
  protected filterUser() {
    if (!this.usersList) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.usersList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredUsers.next(
      this.usersList.filter(
        user =>
          user.firstName.toLowerCase().indexOf(search) > -1 ||
          user.lastName.toLowerCase().indexOf(search) > -1 ||
          user.userName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private setDataToControls(userData: UserData) {
    this.userGroupFormControl.setValue(userData.userGroup);
    this.userNameFormControl.setValue(userData.userName);
    this.firstNameFormControl.setValue(userData.firstName);
    this.lastNameFormControl.setValue(userData.lastName);
    this.emailFormControl.setValue(userData.email);
    this.playerStrengthFormControl.setValue(userData.playerStrength);
    this.mobilePhoneNumberFormControl.setValue(userData.mobilePhoneNumber);
    this.checkboxReceiveMailsFormControl.setValue(+userData.receiveMails);
    this.checkboxReceivePushFormControl.setValue(+userData.receivePush);
    this.userSelected = true;
  }
}
